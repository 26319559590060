// styles.ts
import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    margin: '0 auto',
    maxWidth: '1000px',
    marginTop: '30px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  cardContainer: {
    width: '100%',
    margin: theme.spacing(2, 0),
    borderRadius: theme.shape.borderRadius,
    transition: 'transform 0.3s, box-shadow 0.3s',
    cursor: 'pointer',
    '&:hover': {
      transform: 'scale(1.02)',
      boxShadow: theme.shadows[8],
    },
  },
  cardHeader: {
    backgroundColor: 'transparent',
    borderBottom: '1px solid #ddd',
    color: 'inherit',
  },
  cardContent: {
    padding: theme.spacing(2),
    '&:last-child': {
      paddingBottom: theme.spacing(2),
    },
  },
}));

export { useStyles };
