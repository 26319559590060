import React, { useState, useEffect } from 'react'
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Paper } from '@mui/material'
import { CustomTable, FormHeader } from 'components'
import ListIcon from '@mui/icons-material/List'
import { useStyles } from './styles'
import api from 'services/api'
import { FormatTableFieldType, ITableHeadCellDTO } from 'data/dtos/components/i-table-head-cell-dto'
import { useAlreadyMounted } from 'utils/use-already-mounted';
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'
import { useHistory } from 'react-router-dom'
import { OrganizationListDTO } from './i-organization-list'
import DefaultModal, { DialogType } from 'components/modal'

const headCells: ITableHeadCellDTO[] = [
  {
    id: 'cnpj',
    label: 'CNPJ',
    width: 2
  },
  {
    id: 'legalName',
    label: 'Razão Social',
    width: 3
  },
  {
    id: 'tradeName',
    label: 'Nome Fantasia',
    width: 3
  },
  {
    id: 'disabled',
    label: "Desativado",
    width: 4,
    format: FormatTableFieldType.BOOLEAN
  }
]

const OrganizationList: React.FC = () => {
  const [loading, setLoading] = useState(0)
  const [search, setSearch] = useState('')
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(100)
  const [orderByDirection, setOrderByDirection] = useState(true)
  const [rowsCount, setRowsCount] = useState(0)
  const [organizationsList, setOrganizationsList] = useState<OrganizationListDTO[]>([])
  const [recordToDelete, setRecordToDelete] = useState<string | null>('')
  const [columnOrder, setColumnOrder] = useState<('ASC' | 'DESC')[]>([])
  const [deleteModalIsOpen, setDeleteModalIsOpen] = useState<boolean>(false)
  const history = useHistory()

  const classes = useStyles()
  const alreadyMounted = useAlreadyMounted();

  const loadOrganizations = async () => {
    setLoading(1)

    await api
      .post('/organizations/list', {search, page, rowsPerPage, columnOrder, onlyActive: false})
      .then(async listResponse => {
        const { data } = listResponse.data
        setOrganizationsList(data)

        await api
          .post('/organizations/count', {search})
          .then(countResponse => {
            const { count } = countResponse.data.data
            setRowsCount(count)
          })
          .then(() => setLoading(0))
          .catch(error => {
            return error
          })
      })
      .catch(error => {
        return error
      })
  }


  const handleDelete = async (organizationID: string) => {
    await api
      .delete(`/organizations/${organizationID}`)
      .then(async () => {
        await loadOrganizations()
      })
      .catch(error => {
        return error
      })
      setDeleteModalIsOpen(false)
  }


  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number, ) => {
    setPage(newPage);
  }


  const handleChangeRowsPerPage = async (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }


  useEffect(() => {
    const timeout = setTimeout(() => {
      if (!alreadyMounted) {
        loadOrganizations()
      }
    }, 1000)

    return () => clearTimeout(timeout)
  }, [search])


  useEffect(() => {
    loadOrganizations()
  }, [orderByDirection, rowsPerPage, page])


  return (
    <Paper elevation={0} className={classes.paper}>

      <FormHeader
        title="Parceiros"
        icon={ListIcon}
        newRoute="/organizations/new"
        showBackButton={true}
      />

      <CustomTable
        headCells={headCells}
        rows={organizationsList}
        totalRows={rowsCount}
        handleSearch={setSearch}
        isLoading={loading}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        rowsPerPage={rowsPerPage}
        handleChangePage={handleChangePage}
        page={page}
        columnOrder={columnOrder}
        setColumnOrder={setColumnOrder}
        orderByDirection={orderByDirection}
        setOrderByDirection={setOrderByDirection}
        buttons={
          [
            {
              name: 'Editar',
              icon: EditIcon,
              onClick(rowID) {
                history.push(`/organizations/edit/${rowID}`)
              }
            },
            {
              name: 'Remover',
              icon: DeleteIcon,
              onClick(rowID) {
                setRecordToDelete(rowID)
                setDeleteModalIsOpen(true)
              }
            }
          ]
        }
      />

      <DefaultModal
        isOpen={deleteModalIsOpen}
        title='Exclusão de Registro'
        bodyContent={<> Deseja realmente excluir/desativar o parceiro? </>}
        onConfirmModal={() => handleDelete(recordToDelete)}
        onCloseModal={() => setDeleteModalIsOpen(false)}
        type={DialogType.ACTION}
      />

    </Paper>
  )
}

export default OrganizationList
