export enum PaymentType {
  PAYMENTSLIP = 'PAYMENTSLIP',
  DIRECTDEBT = 'DIRECTDEBT',
}

export const showPaymentType = (paymentType: PaymentType): string => {
  switch (paymentType) {
    case PaymentType.PAYMENTSLIP:
      return 'Boleto'
    case PaymentType.DIRECTDEBT:
      return 'Débito em conta'
    default:
      return 'Tipo de pagamento inválido'
  }
}

