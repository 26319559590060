import React, { useState } from 'react'
import { Paper, Typography } from '@mui/material'
import { useStyles } from "./styles"
import { dummyData } from './data'
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  LabelList,
  LineChart,
  Legend,
  Line,
} from 'recharts';

import { CustomTable } from 'components';
import { FormatTableFieldType, ITableHeadCellDTO } from 'data/dtos/components/i-table-head-cell-dto';
import { useProfile } from 'hooks/use-profile';
import { ProfileType } from 'hooks/enums/ProfileType';

const headCells: ITableHeadCellDTO[] = [
  {
    id: 'assessorName',
    label: 'Assesor',
    width: 2
  },
  {
    id: 'clientName',
    label: 'Cliente',
    width: 2
  },
  {
    id: 'bank',
    label: 'Banco Parceiro',
    width: 2
  },
  {
    id: 'approvedValue',
    label: 'Valor Aprovado',
    width: 1,
    format: FormatTableFieldType.MONEY
  },
  {
    id: 'contractPaymentDate',
    label: 'Data Pagamento Contrato',
    width: 1
  },
  {
    id: 'commissionValue',
    label: 'Valor Comissão',
    width: 1,
    format: FormatTableFieldType.MONEY
  },
  {
    id: 'commissionPaymentDate',
    label: 'Data Pagamento Comissão',
    width: 1
  },
]

const officerHeadCells: ITableHeadCellDTO[] = [
  {
    id: 'organizationName',
    label: 'Parceiro',
    width: 2
  },
  ...headCells,
]

const Commissions: React.FC = () => {
  const classes = useStyles()
  const [loading, setLoading] = useState(0)
  const [search, setSearch] = useState('')
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(100)
  const [orderByDirection, setOrderByDirection] = useState(true)
  const [rowsCount, setRowsCount] = useState(0)
  const [columnOrder, setColumnOrder] = useState<('ASC' | 'DESC')[]>([])
  const { selectedProfile } = useProfile();
  const tableHeadCells = selectedProfile === ProfileType.OFFICER ? officerHeadCells : headCells
  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number, ) => {
    setPage(newPage);
  }

  const handleChangeRowsPerPage = async (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const data = [
    { name: '06/2022', value: 2500.00, formattedValue: '2.500' },
    { name: '07/2022', value: 3541.52, formattedValue: '3.541' },
    { name: '08/2022', value: 1527.68, formattedValue: '1.527' },
    { name: '09/2022', value: 3500.00, formattedValue: '3.500' },
    { name: '10/2022', value: 4100.00, formattedValue: '4.100' },
    { name: '11/2022', value: 5500.00, formattedValue: '5.500' },
    { name: '12/2022', value: 4200.00, formattedValue: '4.200' },
    { name: '01/2023', value: 2000.00, formattedValue: '2.000' },
    { name: '02/2023', value: 2500.00, formattedValue: '2.500' },
    { name: '03/2023', value: 3500.00, formattedValue: '3.500' },
    { name: '04/2023', value: 4000.00, formattedValue: '4.000' },
    { name: '05/2023', value: 2500.00, formattedValue: '2.500' },
  ];

  const dataaa = [
    { name: 'Jan', uv: 4000, pv: 2400, amt: 2400 },
    { name: 'Feb', uv: 3000, pv: 1398, amt: 2210 },
    { name: 'Mar', uv: 2000, pv: 9800, amt: 2290 },
    { name: 'Apr', uv: 2780, pv: 3908, amt: 2000 },
    { name: 'May', uv: 1890, pv: 4800, amt: 2181 },
    { name: 'Jun', uv: 2390, pv: 3800, amt: 2500 },
    { name: 'Jul', uv: 3490, pv: 4300, amt: 2100 },
  ];

  return (
    <Paper elevation={0} className={classes.paper}>

      <Typography variant='h5' className={classes.formTitle}>
        Histórico de Comissões Pagas - Últimos 12 Meses
      </Typography>

      <div style={{ fontSize: '13px' }}>
        <ResponsiveContainer width="100%" height={250}>
          <BarChart data={data}>
            <XAxis dataKey="name" />
            <Bar dataKey="value" fill="#2614FE" barSize={35} isAnimationActive={false}>
              <LabelList dataKey="formattedValue" position="top"/>
            </Bar>
          </BarChart>
        </ResponsiveContainer>
      </div>

      <LineChart  height={300} data={dataaa}>
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis dataKey="name" />
      <YAxis />
      <Tooltip />
      <Legend />
      <Line type="monotone" dataKey="pv" stroke="#8884d8" />
      <Line type="monotone" dataKey="uv" stroke="#82ca9d" />
    </LineChart>

      <CustomTable
        headCells={tableHeadCells}
        rows={dummyData}
        totalRows={rowsCount}
        handleSearch={setSearch}
        isLoading={loading}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        rowsPerPage={rowsPerPage}
        handleChangePage={handleChangePage}
        page={page}
        columnOrder={columnOrder}
        setColumnOrder={setColumnOrder}
        orderByDirection={orderByDirection}
        setOrderByDirection={setOrderByDirection}
        buttons={[]}
      />

    </Paper>
  )
}

export default Commissions
