import React, { useState, useCallback, useEffect } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { Box, TextField, Button, Grid, Link, Paper, LinearProgress, Alert, Typography } from '@mui/material'
import { useAuth } from 'hooks/auth'
import logoImage from 'assets/logo.svg'
import backgroundLogo from 'assets/finteto-logo-background.png'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import LoginFooter from 'components/login-footer'
import { IInviteDTO } from 'data/dtos/operation/i-invite-dto'
import api from 'services/api'
import { ServerErrorResponse } from 'shared/types'
import { AxiosError } from 'axios'
import { useStyles } from './styles'

interface ISignUpFormData {
  name: string
  email: string
  password: string
}

interface IRouteParams {
  id: string
}

const SignUp: React.FC = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [mainError, setMainError] = useState('')
  const { signIn } = useAuth()

  const params = useParams<IRouteParams>()

  const hasInvite = !!params.id
  const history = useHistory()
	const classes = useStyles()
  const canCreateUser = mainError !== ''

	const validationSchema = yup.object().shape({
    name: yup.string().required('É necessário informar um nome'),
    email: yup.string().email('Formato de email inválido').required('É necessário informar um email'),
    password: yup.string().required('É necessário informar uma senha').min(8, 'Sua senha deve conter no mínimo 8 caracteres')
  })

  const { register, handleSubmit, reset, formState: { errors } } = useForm<ISignUpFormData>({
    resolver: yupResolver(validationSchema)
  })

  useEffect(() => {
    async function loadData() {
      const { id } = params
      await api
        .get(`/invites/${id}`)
        .then(response => {
          const { data } = response

          const inviteResult = {
            name: data.name,
            email: data.email,
          }

          return inviteResult
        })
        .then((inviteResult: IInviteDTO) =>{
          reset(inviteResult)
        })
        .catch(err => {
          const error = err as AxiosError<ServerErrorResponse>
          setMainError(error.response.data.error.message)
        })
    }

    if(hasInvite){
      loadData()
    }
  }, [params, hasInvite, reset])

  const onSubmit = useCallback(
    async (data: ISignUpFormData) => {
      try {
        const { id } = params

        setIsLoading(true)

        await api.post('/users', {...data, inviteID: id})

        await signIn({
          email: data.email,
          password: data.password,
        })

        setIsLoading(false)
        history.push('/home')
      } catch (err) {
        setIsLoading(false)
        const error = err as AxiosError<ServerErrorResponse>
        setMainError(error.response.data.error.message)
      }
    },
    [history],
  )

  const handleChange = (formField: any) => {
    setMainError('')
  }

	return (
		<Box className={classes.container}>
			<Box className={classes.mainWrapper}>
        <Box className={classes.loginWrapper}>
          <Box className={classes.logoWrapper}>
            <img src={logoImage} className={classes.logoImage} alt="Logo" />
          </Box>

          <Paper elevation={3} className={classes.paperWrapper}>
            <Box
              component="form"
              onSubmit={handleSubmit(onSubmit)}
              noValidate
              data-testid="form"
              className={classes.formBox}
            >
              <h3>Criar Conta</h3>

              <Grid container item xs={12} spacing={0}>
                <Grid item xs={12}>
                  <Typography variant="caption" display="block" gutterBottom>
                    Nome *
                  </Typography>
                  <TextField
                    required
                    id="name"
                    type="text"
                    autoFocus
                    variant="outlined"
                    error={!!errors.name}
                    helperText={errors?.name?.message}
                    {...register("name",
                      { onChange: (e) => handleChange(e) }
                    )}
                  />
                </Grid>
              </Grid>

              <Grid container item xs={12} spacing={0}>
                <Grid item xs={12}>
                  <Typography variant="caption" display="block" gutterBottom>
                    Email *
                  </Typography>
                  <TextField
                    required
                    id="email"
                    type="email"
                    disabled={hasInvite}
                    variant="outlined"
                    error={!!errors.email}
                    helperText={errors?.email?.message}
                    {...register("email",
                      { onChange: (e) => handleChange(e) }
                    )}
                  />
                </Grid>
              </Grid>

              <Grid container item xs={12} spacing={0}>
                <Grid item xs={12}>
                  <Typography variant="caption" display="block" gutterBottom>
                    Senha *
                  </Typography>
                  <TextField
                    required
                    id="password"
                    type="password"
                    autoComplete="new-password"
                    variant="outlined"
                    error={!!errors.password}
                    helperText={errors?.password?.message}
                    {...register("password",
                      { onChange: (e) => handleChange(e) }
                    )}
                  />
                </Grid>
              </Grid>

              <Button
                type="submit"
                fullWidth
                variant="contained"
                size="large"
                sx={{ mt: 2, mb: 1 }}
                className={classes.mainButton}
                disabled={canCreateUser}
              >
                Criar
              </Button>

              <Grid container marginTop={10}>
                <Grid item marginTop={10}>
                  <Typography variant="body2" display="block" gutterBottom marginTop={1}>
                    Ao criar o seu cadastro, você concorda com a nossa <Link href="https://www.finteto.com/privacidade" variant="body2" target='_blank'>política de privacidade</Link> e <Link href="https://www.finteto.com/termos-de-uso" variant="body2" target='_blank'>termos de uso</Link>
                  </Typography>
                </Grid>
              </Grid>

              <Grid container>
                <Grid item xs>
                  <Link href="/" variant="body2" data-testid="signup-link">
                    Voltar ao Login
                  </Link>
                </Grid>
                <Grid item xs className={classes.signupLink}>
                  <span/>
                </Grid>
              </Grid>

              {isLoading && (
                <LinearProgress className={classes.linearProgress} />
              )}

              {mainError !== '' && (
                <Alert severity="error" className={classes.alert}>{mainError}</Alert>
              )}
            </Box>
          </Paper>
        </Box>

        <LoginFooter />
			</Box>

      <Box className={classes.backgroundWrapper}>
        <Box className={classes.backgroundTransparencyBlue}>
          <Box className={classes.backgroundTransparencyBlack}>
            <img src={backgroundLogo}  className={classes.backgroundLogo} />
          </Box>
        </Box>
      </Box>
  	</Box>
	)
}

export default SignUp
