import { Avatar, Button, FormControl, Grid, Paper, TextField, Typography } from "@mui/material";
import { ProposalContext } from "contexts/proposal-context"
import { IProposalDTO } from "data/dtos/i-proposal-dto";
import { useContext } from "react"
import { FormProvider, useForm } from "react-hook-form";
import { ProposalPropertyEvaluationFormStep } from "./form-step";
import FileUpload from "components/file-upload";
import { zodResolver } from "@hookform/resolvers/zod";
import { array, object, TypeOf, z } from 'zod';
import { ProposalStatus } from "hooks/enums/ProposalStatus";
import { useStyles } from "../../styles";
import ContentCopyRoundedIcon from '@mui/icons-material/ContentCopyRounded';
import { APPRAISAL_REPORT_TOOLTIP_HELPER_TEXT } from "utils/constants/tooltipHelperMessages";

type Props = {
  isDisabled: boolean
  setProposalFormStep: React.Dispatch<React.SetStateAction<ProposalPropertyEvaluationFormStep>>
}

const imageUploadSchema = object({
  image: z.instanceof(File),
  images: array(z.instanceof(File)),
});

type IUploadImage = TypeOf<typeof imageUploadSchema>;

export const PropertyEvaluationDocumentationForm = ({ isDisabled, setProposalFormStep }: Props) => {
  const { proposal, setProposal } = useContext(ProposalContext);
  const classes = useStyles()

  const methods = useForm<IUploadImage>({
    resolver: zodResolver(imageUploadSchema),
  });


  const handleFileUpload = (fieldName: string, file: File): void => {
    setProposal({...proposal, [fieldName]: file})
  }

  const handleBack = () => {
    setProposalFormStep(ProposalPropertyEvaluationFormStep.EVALUATION_DATA)
  }

  const saveData = (spousePersonalData: IProposalDTO) => {
    setProposal({ ...proposal, ...spousePersonalData, status: ProposalStatus.HIRING_AWAITING_CLIENT_SIGNED })
    setProposalFormStep(ProposalPropertyEvaluationFormStep.COMPLETED)
  }

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<IProposalDTO>({
    defaultValues: {
      realEstateAppraisalValue: proposal.realEstateAppraisalValue || '',
    },
    reValidateMode: 'onChange'
  })

  return (
    <form onSubmit={handleSubmit(saveData)}>
      <FormProvider {...methods}>
        <Grid container spacing={0} className={classes.formContainer}>

          <Paper elevation={1} variant="outlined" className={classes.paperWrapper}>
            <Grid container alignItems='center' direction='row' style={{ maxWidth: '800px'}}>
              <Grid item xs={1} sm={1} md={1} lg={1} xl={1} style={{ paddingRight: '0px'}} >
                <Avatar sx={{ backgroundColor: '#F5F8FF' }}>
                  <ContentCopyRoundedIcon />
                </Avatar>
              </Grid>
              <Grid item xs={11} sm={11} md={11} lg={11} xl={11}>
                <div>
                  <Typography variant="caption" marginBottom={0} paddingBottom={0}>
                    <strong>Faça aqui o upload dos seus documentos</strong>
                  </Typography>
                  <div>
                    <Typography variant="caption" marginBottom={0} paddingBottom={0}>
                      Arquivos suportados nos seguintes formatos: PDF, PNG, JPEG e JPG, com tamanho máximo de 10MBs.
                    </Typography>
                  </div>
                </div>
              </Grid>
            </Grid>

            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <FileUpload label="Relatório de Avaliação do Imóvel (Opcional)" helperText={APPRAISAL_REPORT_TOOLTIP_HELPER_TEXT} defaultValue={proposal.realEstateFileAppraisalReport} imageUrl={proposal.realEstateFileAppraisalReportURL} name='realEstateFileAppraisalReport' isDisabled={isDisabled} onFileUpload={handleFileUpload} shouldShowHeader={true} isRequired={true}/>
            </Grid>
          </Paper>

          <Grid container spacing={1}>
            <Button
              variant="contained"
              type="submit"
              sx={{ mt: 1, mb: 1 }}
            >
              Continuar
            </Button>

            <Button onClick={handleBack} sx={{ mt: 1, mr: 1, ml: 1 }}>
              Voltar
            </Button>
          </Grid>
        </Grid>
      </FormProvider>
    </form>
  )
}
