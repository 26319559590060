import React from 'react'
import { Box } from '@mui/material'
import { useStyles } from './styles'
import ProposalForm from '../../form'
import PublicAppHeader from '../app-header'
import PublicFooter from '../footer'

const PublicProposalForm: React.FC = () => {
  const classes = useStyles()

  return (
    <>
    <Box className={classes.root}>
      <PublicAppHeader />
      <Box className={classes.proposalContainer}>
        <ProposalForm title='Cadastre aqui a sua proposta' />
      </Box>
      <PublicFooter className={classes.footer} />
    </Box>

    </>
  )
}

export default PublicProposalForm
