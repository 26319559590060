import { makeStyles } from '@mui/styles'
import { Theme } from '@mui/material'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    width: '100%',
  },
  container: {
    maxHeight: 'calc(100vh - 320px)'
  },
  paper: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    padding: '20px',
    minHeight: 'calc(100vh - 80px)',
    width: '100%',
    background: 'rgba(92, 107, 192, 0.2) !important',
    overflow: 'auto'
  },
  homeText: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    width: '100%',
    fontFamily: 'Roboto',
    fontSize: '24px !important',
    fontWeight: 'bold',
    color: theme.palette.primary.contrastText
  },
  logo: {
    height: '70px',
  },
  bankLogo: {
    height: '35px',
    borderRadius: '10px'
  },
  gridHeader: {
    backgroundColor: '#fafafa',
    padding: '5px 9px 5px 10px',
    fontWeight: 'bold',
  },
  gridBody: {
    height: '45px',
    backgroundColor: '#fff',
    padding: '2px 9px 2px 10px',
    borderBottom: '1px solid #fafafa',
  },
  observation: {
    fontWeight: 'bold',
    margin: '50px',
  },
  card: {
    borderRadius: '20px !important',
    cursor: 'pointer',
    border: '1px solid #CCC',
    boxShadow: 'none !important',
    transition: 'transform 0.3s ease, box-shadow 0.3s ease !important',
    height: '100%', // Ensure the card takes up the full height of its parent
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    '&:hover': {
      transform: 'scale(1.05)',
      boxShadow: theme.shadows[8],
    },
  },
  cardIcon: {
    fontSize: '32px !important',
    color: '#605F6A !important',
  },
  cardContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    height: '100%', // Ensure the content takes up the full height of the card
  },
  fintetoLogo: {
    height: '80px',
    width: '250px',
    marginBottom: '10px'
  },
  logoContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: '200px'
  }
}));

export { useStyles }
