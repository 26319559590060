export enum OrganizationType {
  OFFICE = 'OFFICE', // Escritório
  LAND_DEVELOPER = "LAND DEVELOPER", // Loteadora
  REAL_ESTATE_AGENCY = "REAL_ESTATE_AGENCY", // Imobiliária
  DEVELOPER = "DEVELOPER", // Incorporadora
  PENSION_FUND = "PENSION_FUND", // Fundo de Pensão
  OTHERS = "OTHERS" // Outros
}

export const showOrganizationType = (organizationType: OrganizationType): string => {
  switch(organizationType){
    case OrganizationType.OFFICE:
      return 'Escritório'
    case OrganizationType.LAND_DEVELOPER:
      return 'Loteadora'
    case OrganizationType.REAL_ESTATE_AGENCY:
      return 'Imobiliária'
    case OrganizationType.DEVELOPER:
      return 'Incorporadora'
    case OrganizationType.PENSION_FUND:
      return 'Fundo de Pensão'
    case OrganizationType.OTHERS:
      return 'Outros'
  }
}
