import { createContext, useState, ReactNode } from 'react'

type ProfileContextType = {
	selectedProfile: string
	setSelectedProfile: (selectedProfile: string) => void
}

interface Props {
	children?: ReactNode
}

export const ProfileContext = createContext({} as ProfileContextType)

export function ProfileProvider({ children }: Props) {
	const [selectedProfile, setSelectedProfile] = useState<string>('')

	return (
		<ProfileContext.Provider
			value={{
				selectedProfile,
				setSelectedProfile
			}}
		>
			{children}
		</ProfileContext.Provider>
	)
}
