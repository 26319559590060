import { Avatar, Box, Button, Checkbox, FormControl, FormControlLabel, FormGroup, Grid, MenuItem, Paper, RadioGroup, TextField, Typography } from "@mui/material";
import { ProposalContext } from "contexts/proposal-context"
import { IProposalDTO } from "data/dtos/i-proposal-dto";
import { MaritalStatus } from "hooks/enums/MaritalStatus";
import { zodResolver } from '@hookform/resolvers/zod';
import { useContext, useState } from "react"
import { Controller, FormProvider, useForm } from "react-hook-form";
import { bankNamePlaceHolder, completeNamePlaceHolder, cpfMask, cpfMaskPlaceholder, emailPlaceHolder, identityIssuer, identityMask, nationalityPlaceHolder, phoneMask, phoneMaskPlaceholder } from "utils/utils";
import { useStyles } from "../../styles";
import FileUpload from "components/file-upload";
import { array, object, TypeOf, z } from 'zod';
import { ProposalDocumentationFormStep } from "./form-step";
import ContentCopyRoundedIcon from '@mui/icons-material/ContentCopyRounded';
import { ADDRESS_TOOLTIP_HELPER_TEXT, AUTHORIZATION_FGTS__TOOLTIP_HELPER_TEXT, CIVIL_STATUS_TOOLTIP_HELPER_TEXT, CNH_TOOLTIP_HELPER_TEXT, CPF_TOOLTIP_HELPER_TEXT, EXTRACT_FGTS_TOOLTIP_HELPER_TEXT, INCOME_TAX_RECEIPT_TOOLTIP_HELPER_TEXT, INCOME_TAX_TOOLTIP_HELPER_TEXT, LATEST_PAY_STUB_TOOLTIP_HELPER_TEXT, PENULTIMATE_PAY_STUB_TOOLTIP_HELPER_TEXT, RG_BACK_TOOLTIP_HELPER_TEXT, RG_FRONT_TOOLTIP_HELPER_TEXT } from "utils/constants/tooltipHelperMessages";

type Props = {
  isDisabled: boolean
  setProposalFormStep: React.Dispatch<React.SetStateAction<ProposalDocumentationFormStep>>
}

const imageUploadSchema = object({
  image: z.instanceof(File),
  images: array(z.instanceof(File)),
});

type IUploadImage = TypeOf<typeof imageUploadSchema>;

export const BuyerForm = ({ isDisabled, setProposalFormStep }: Props) => {
  const { proposal, setProposal } = useContext(ProposalContext);
  const classes = useStyles()

  const methods = useForm<IUploadImage>({
    resolver: zodResolver(imageUploadSchema),
  });

  const handleFileUpload = (fieldName: string, file: File): void => {
    setProposal({...proposal, [fieldName]: file})
  }

  const saveData = (personPersonalData: IProposalDTO) => {
    setProposal({...proposal, ...personPersonalData})
    setProposalFormStep(ProposalDocumentationFormStep.SELLER_DATA)
  }

  const {
    register,
    handleSubmit,
    watch,
    control,
    setValue,
    formState: { errors },
  } = useForm<IProposalDTO>({
    defaultValues: {
      publicBanksShouldSend: proposal.publicBanksShouldSend || false,
    },
    reValidateMode: 'onChange'
  })

  return (
    <form onSubmit={handleSubmit(saveData)}>
      <FormProvider {...methods}>
        <Grid container spacing={1} className={classes.formContainer}>

          <Paper elevation={1} variant="outlined" className={classes.paperWrapper} sx={{ overflowY: 'auto'}}>
            <Grid container alignItems='center' direction='row' style={{ maxWidth: '800px'}}>
              <Grid item xs={1} sm={1} md={1} lg={1} xl={1} style={{ paddingRight: '0px'}} >
                <Avatar sx={{ backgroundColor: '#F5F8FF' }}>
                  <ContentCopyRoundedIcon />
                </Avatar>
              </Grid>
              <Grid item xs={11} sm={11} md={11} lg={11} xl={11}>
                <div>
                  <Typography variant="caption" marginBottom={0} paddingBottom={0}>
                    <strong>Faça aqui o upload dos seus documentos</strong>
                  </Typography>
                  <div>
                    <Typography variant="caption" marginBottom={0} paddingBottom={0}>
                      Arquivos suportados nos seguintes formatos: PDF, PNG, JPEG e JPG, com tamanho máximo de 10MBs.
                    </Typography>
                  </div>
                </div>
              </Grid>
            </Grid>

            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <FileUpload label="CNH (frente e verso) *" helperText={CNH_TOOLTIP_HELPER_TEXT} defaultValue={proposal.personFileCNH} imageUrl={proposal.personFileCNHURL} name='personFileCNH' isDisabled={isDisabled} onFileUpload={handleFileUpload} shouldShowHeader={true} isRequired={true}/>
            </Grid>

            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <FileUpload label="RG (frente) *" helperText={RG_FRONT_TOOLTIP_HELPER_TEXT} defaultValue={proposal.personFileRGFront} imageUrl={proposal.personFileRGFrontURL} name='personFileRGFront' isDisabled={isDisabled} onFileUpload={handleFileUpload} isRequired={true}/>
            </Grid>

            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <FileUpload label="RG (verso) *" helperText={RG_BACK_TOOLTIP_HELPER_TEXT} defaultValue={proposal.personFileRGBack} imageUrl={proposal.personFileRGBackURL} name='personFileRGBack' isDisabled={isDisabled} onFileUpload={handleFileUpload} isRequired={true}/>
            </Grid>

            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <FileUpload label="CPF *" helperText={CPF_TOOLTIP_HELPER_TEXT} defaultValue={proposal.personFileCPF} imageUrl={proposal.personFileCPFURL} name='personFileCPF' isDisabled={isDisabled} onFileUpload={handleFileUpload} isRequired={true}/>
            </Grid>

            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <FileUpload label="Certidão de Estado Civil *" helperText={CIVIL_STATUS_TOOLTIP_HELPER_TEXT} defaultValue={proposal.personFileCertificateCivilStatus} imageUrl={proposal.personFileCertificateCivilStatusURL} name='personFileCertificateCivilStatus' isDisabled={isDisabled} onFileUpload={handleFileUpload} isRequired={true}/>
            </Grid>

            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <FileUpload label="Comprovante de Endereço: Mês atual ou anterior *" helperText={ADDRESS_TOOLTIP_HELPER_TEXT} defaultValue={proposal.personFileAddressVerification} imageUrl={proposal.personFileAddressVerificationURL} name='personFileAddressVerification' isDisabled={isDisabled} onFileUpload={handleFileUpload} isRequired={true}/>
            </Grid>

            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <FileUpload label="Último Holerite *" helperText={LATEST_PAY_STUB_TOOLTIP_HELPER_TEXT} defaultValue={proposal.personFileLatestPayStub} imageUrl={proposal.personFileLatestPayStubURL} name='personFileLatestPayStub' isDisabled={isDisabled} onFileUpload={handleFileUpload} isRequired={true}/>
            </Grid>

            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <FileUpload label="Penúltimo Holerite *" helperText={PENULTIMATE_PAY_STUB_TOOLTIP_HELPER_TEXT} defaultValue={proposal.personFilePenultimatePayStub} imageUrl={proposal.personFilePenultimatePayStubURL} name='personFilePenultimatePayStub' isDisabled={isDisabled} onFileUpload={handleFileUpload} isRequired={true}/>
            </Grid>

            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <FileUpload label="Declaração do Imposto de Renda *" helperText={INCOME_TAX_TOOLTIP_HELPER_TEXT} defaultValue={proposal.personFileIncomeTax} imageUrl={proposal.personFileIncomeTaxURL} name='personFileIncomeTax' isDisabled={isDisabled} onFileUpload={handleFileUpload} isRequired={true}/>
            </Grid>

            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <FileUpload label="Recibo de Entrega do Imposto de Renda *" helperText={INCOME_TAX_RECEIPT_TOOLTIP_HELPER_TEXT} defaultValue={proposal.personFileIncomeTaxReceipt} imageUrl={proposal.personFileIncomeTaxReceiptURL} name='personFileIncomeTaxReceipt' isDisabled={isDisabled} onFileUpload={handleFileUpload} isRequired={true}/>
            </Grid>

            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <FileUpload label="Extrato FGTS" helperText={EXTRACT_FGTS_TOOLTIP_HELPER_TEXT} defaultValue={proposal.personFileExtractFGTS} imageUrl={proposal.personFileExtractFGTSURL} name='personFileExtractFGTS' isDisabled={isDisabled} onFileUpload={handleFileUpload} isRequired={false}/>
            </Grid>

            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <FileUpload label="Autorização de uso do FGTS" helperText={AUTHORIZATION_FGTS__TOOLTIP_HELPER_TEXT} defaultValue={proposal.personFileAuthorizationFGTS} imageUrl={proposal.personFileAuthorizationFGTSURL} name='personFileAuthorizationFGTS' isDisabled={isDisabled} onFileUpload={handleFileUpload} isRequired={false}/>
            </Grid>
          </Paper>

        </Grid>

        <Grid container spacing={1}>
          <Button variant="contained" type="submit" sx={{ mt: 1, mb: 1 }}>
            Continuar
          </Button>
        </Grid>
      </FormProvider>
    </form>
  )
}
