import React from 'react';
import ReactApexChart from 'react-apexcharts';
import { makeStyles } from '@mui/styles'
import { ApexOptions } from 'apexcharts';

const useStyles = makeStyles({
  funnelChartWrapper: {
    '& .apexcharts-toolbar': {
      display: 'none !important',
    },
  },
});

export interface FunnelChartCategories {
  name: string,
  value: number
}

export type FunnelChartProps = {
  categories: FunnelChartCategories[]
}

const FunnelChart = ({ categories }: FunnelChartProps) => {
  const classes = useStyles();

  const options: ApexOptions = {
    chart: {
      type: 'bar',
      height: 350,
    },
    plotOptions: {
      bar: {
        borderRadius: 0,
        horizontal: true,
        barHeight: '80%',
        isFunnel: true,
      },
    },
    dataLabels: {
      enabled: true,
      formatter: function (val, opt) {
        return opt.w.globals.labels[opt.dataPointIndex]
      },
      dropShadow: {
        enabled: true,
      },
    },

    xaxis: {
      categories: categories.map(category => `${category.name}: ${category.value}`)
    },
    legend: {
      show: false,
    },
    tooltip: {
      enabled: false
    },
  }

  const series = [
    {
      name: "Funnel Series",
      data: [100, 80, 60, 40],
    },
  ]

  return (
    <div className={classes.funnelChartWrapper}>
      <div id="chart">
        <ReactApexChart options={options} series={series} type="bar" height={250} width={400} />
      </div>
      <div id="html-dist"></div>
    </div>
  );
};

export default FunnelChart;
