import { makeStyles } from '@mui/styles'
import { Theme } from '@mui/material'
import { MarginRounded } from '@mui/icons-material'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    width: '100%',
  },

  container: {
    maxHeight: 'calc(100vh - 320px)',
  },

  paper: {
    padding: '20px',
    minHeight: 'calc(100vh - 110px)',
    maxHeight: 'calc(100vh - 110px)',
    width: '100%'
  },

  formTitle: {
    display: 'flex',
    width: '100%',
    fontSize: 24,
    fontWeight: 'bold',
    alignItems: 'center',
    justifyContent: 'space-between',
    color: theme.palette.primary.main
  },

  formContainer: {
    maxHeight: 'calc(100vh - 200px)',
    width: '100%'
  },

  formResultsContainer: {
    maxHeight: 'calc(100vh - 490px)',
    width: '100%',
  },

  head: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
  },

  iconHead: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    width: 1,
    minWidth: 1,
    maxWidth: 1,
  },

  tableIcon: {
  	margin: '-5px',
  	padding: '-5px',
  	lineHeight: 0,
  	fontSize: 20,
  	cursor: 'pointer',
  	color: theme.palette.primary.main,
  },

  actionButton: {
    marginLeft: '10px !important',
  },
  submitButton: {
    marginTop: 20,
    justifyContent: 'flex-end',
  },

  checkBox: {
    marginTop: 5,
    marginBottom: 5
  },

  gridBoxFirst: {
    marginLeft: -6
  },

  gridBox: {
  },

  gridHeader: {
    backgroundColor: '#fafafa',
    padding: '5px 9px 5px 10px'
  },

  gridBody: {
    backgroundColor: '#fff',
    padding: '2px 9px 2px 10px',
    borderBottom: '1px solid #fafafa',
  },

  resultBox: {
    display: 'flex',
    justifyContent: 'flex-end',
    minHeight: 'calc(100vh - 510px)',
    maxHeight: 'calc(100vh - 510px)',
    flexGrow: 1,
    overflowX: 'hidden',
    overflowY: 'scroll',
    padding: '0px 1px 0px 0px',
  },

  resultColumn: {
    display: 'flex',
    justifyContent: 'flex-end',
    flexGrow: 1,
    maxWidth: '100% !important',
    width: '100% !important'
  },

  gridPaper: {
    padding: '10px 20px 20px 20px',
    marginTop: '20px'
  }
}))

export { useStyles }
