import React, { useState, useEffect, useRef, useCallback } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { Box, Paper, Grid, TextField, InputLabel, MenuItem, Typography } from '@mui/material'
import TextareaAutosize from '@mui/base/TextareaAutosize';
import { Checkbox } from '@mui/material'
import { useForm, Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { FormHeader, FormAlert } from 'components'
import ListIcon from '@mui/icons-material/List'
import * as yup from 'yup'
import { useStyles } from './styles'
import api from 'services/api'
import { INotificationDTO } from 'data/dtos/operation/i-notification-dto'

interface IRouteParams {
  id: string
}

const NotificationForm: React.FC = () => {
  const [mainError, setMainError] = useState('')
  const [organizationsA, setOrganizationsA] = useState([])
  const [personsB, setPersonsB] = useState([])

  const params = useParams<IRouteParams>()
  const firstInputElement = useRef(null)
  const classes = useStyles()
  const history = useHistory()

  const validationSchema = yup.object().shape({
    datetimeSending: yup.string()
      .required('Campo obrigatório'),
    organizationId: yup.string()
      .required('Campo obrigatório'),
    senderId: yup.string()
      .required('Campo obrigatório'),
    subject: yup.string()
      .required('Campo obrigatório'),
  })

  const {
    register,
    handleSubmit,
    reset,
    getValues,
    setValue,
    watch,
    formState: { errors },
    control
  } = useForm<INotificationDTO>({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      organizationId: null,
      senderId: null,
      subject: '',
    }
  })

  // initial load

  useEffect(() => {
    async function loadData() {

      // select Organização

      await api
        .post('/organizations/select')
        .then(response => {
          const { data } = response.data

          return data
        })
        .then((organizationsResult) => {
          setOrganizationsA(organizationsResult)
        })
        .catch(error => {
          return error
        })

      // select De

      await api
        .post('/persons/select')
        .then(response => {
          const { data } = response.data

          return data
        })
        .then((personsResult) => {
          setPersonsB(personsResult)
        })
        .catch(error => {
          return error
        })
    }

    loadData()
  }, [])


  // main data load

  useEffect(() => {
    async function loadData() {
      const { id } = params

      // form data

      await api
        .get(`/notifications/${id}`)
        .then(response => {
          const { data } = response.data

          const notificationResult = {
            datetimeSending: data.datetimeSending,
            organizationId: data.organizationId.id,
            senderId: data.senderId.id,
            subject: data.subject,
            message: data.message,
          }

          return notificationResult
        })
        .then((notificationResult: INotificationDTO) => {
          reset(notificationResult)
        })
        .catch(error => {
          return error
        })
    }

    if (params.id) {
      loadData()
    }
  }, [params, params.id])


  // data save

  const onSubmit = useCallback(async (data: INotificationDTO) => {
    const payLoad: INotificationDTO = {
      datetimeSending: data.datetimeSending,
      organizationId: data.organizationId,
      senderId: data.senderId,
      subject: data.subject,
      message: data.message,
    }

    if (params.id) {
      const { id } = params

      payLoad.id = id

      await api
        .put(`/notifications`, payLoad)
        .then(history.push('/notifications'))
        .catch(error => {
          setMainError(error.response.data.data.name)
          return error.response.data.data
        })
    } else {
      await api
        .post('/notifications', payLoad)
        .then(history.push('/notifications/new'))
        .then(() => reset())
        .then(() => setTimeout(() => { firstInputElement.current.focus() }, 0))
        .catch(error => {
          setMainError(error.response.data.data.name)
          return error.response.data.data
        })
    }
  }, [])


  const handleChange = (formField: any) => {
    setMainError('')
  }


  return (
    <Paper elevation={0} className={classes.paper}>
      <Box
        component="form"
        onSubmit={handleSubmit(onSubmit)}
        noValidate
        data-testid="form"
      >
        <FormHeader
          title="Notificações"
          icon={ListIcon}
          backRoute="/notifications"
          showBackButton={true}
          showSaveButton={true}
        />

        <FormAlert setMainError={setMainError} mainError={mainError} />

        <Paper elevation={1} className={classes.gridPaper}>
          <Grid container spacing={1} className={classes.formContainer}>

            <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Typography variant="caption" display="block" gutterBottom>
                  Data de envio
                </Typography>
                <TextField
                  id="datetimeSending"
                  error={!!errors.datetimeSending}
                  helperText={errors?.datetimeSending?.message}
                  variant="outlined"
                  margin="dense"
                  size="small"
                  fullWidth={true}
                  type="date"
                  autoFocus
                  inputRef={firstInputElement}
                  InputLabelProps={{
                    shrink: true
                  }}
                  {...register("datetimeSending",
                    { onChange: (e) => handleChange(e) }
                  )}
                />
              </Grid>
            </Grid>

            <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Typography variant="caption" display="block" gutterBottom>
                  Organização
                </Typography>
                <TextField
                  id="organizationId"
                  error={!!errors.organizationId}
                  helperText={errors?.organizationId?.message}
                  variant="outlined"
                  margin="dense"
                  size="small"
                  fullWidth={true}
                  value={`${watch().organizationId}`}
                  select
                  {...register("organizationId", { onChange: (e) => {
                    setValue("organizationId", e.target.value)
                    handleChange(e)
                  }})}
                >
                {organizationsA.map((organization) => (
                  <MenuItem
                    key={organization.id}
                    value={organization.id}
                  >
                    {organization.tradeName}
                  </MenuItem>
                ))}
                </TextField>
              </Grid>
            </Grid>

            <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Typography variant="caption" display="block" gutterBottom>
                  De
                </Typography>
                <TextField
                  id="senderId"
                  error={!!errors.senderId}
                  helperText={errors?.senderId?.message}
                  variant="outlined"
                  margin="dense"
                  size="small"
                  fullWidth={true}
                  value={`${watch().senderId}`}
                  select
                  {...register("senderId", { onChange: (e) => {
                    setValue("senderId", e.target.value)
                    handleChange(e)
                  }})}
                >
                {personsB.map((person) => (
                  <MenuItem
                    key={person.id}
                    value={person.id}
                  >
                    {person.name}
                  </MenuItem>
                ))}
                </TextField>
              </Grid>
            </Grid>

            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Typography variant="caption" display="block" gutterBottom>
                  Assunto
                </Typography>
                <TextField
                  id="subject"
                  error={!!errors.subject}
                  helperText={errors?.subject?.message}
                  variant="outlined"
                  margin="dense"
                  size="small"
                  fullWidth={true}
                  InputLabelProps={{
                    shrink: true
                  }}
                  inputProps={{
                    maxLength: 255
                  }}
                  {...register("subject",
                    { onChange: (e) => handleChange(e) }
                  )}
                />
              </Grid>
            </Grid>

            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Typography variant="caption" display="block" gutterBottom>
                  Mensagem
                </Typography>
                <Controller
                  render={({ field }) => (
                    <TextareaAutosize
                      minRows={10}
                      maxRows={10}
                      value={getValues("message")}
                      {...register("message", { onChange: (e) => {
                        setValue("message", e.target.value)
                        handleChange(e)
                      }})}
                    />
                  )}
                  name="message"
                  control={control}
                />
              </Grid>
            </Grid>

          </Grid>
        </Paper>
      </Box>
    </Paper>
  )
}

export default NotificationForm
