import { Button, Checkbox, FormControl, FormControlLabel, FormGroup, Grid, MenuItem, TextField, Tooltip, Typography } from "@mui/material";
import { ProposalContext } from "contexts/proposal-context"
import { IProposalDTO } from "data/dtos/i-proposal-dto";
import { useContext } from "react"
import { Controller, useForm } from "react-hook-form";
import { currencyMask, realEstatePricePlaceHolder } from "utils/utils";
import { useStyles } from "../../styles";
import { PaymentType, showPaymentType } from "hooks/enums/PaymentType";
import { ProposalDataFormStep } from "./form-step";
import InfoIcon from '@mui/icons-material/Info';
import { INCOME_TYPE_TOOLTIP_HELPER_TEXT } from "utils/constants/tooltipHelperMessages";
import { MaritalStatus } from "hooks/enums/MaritalStatus";

type Props = {
  isDisabled: boolean
  setProposalFormStep: React.Dispatch<React.SetStateAction<ProposalDataFormStep>>
}

export const FinancingForm = ({ isDisabled, setProposalFormStep }: Props) => {
  const { proposal, setProposal } = useContext(ProposalContext);
  const classes = useStyles()

  const saveData = (personProfessionalData: IProposalDTO) => {
    setProposal({...proposal, ...personProfessionalData})
    setProposalFormStep(ProposalDataFormStep.PUBLIC_BANK_DATA)
  }

  const handleBack = () => {
    setProposalFormStep(ProposalDataFormStep.REAL_STATE_DATA)
  }

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
    control
  } = useForm<IProposalDTO>({
    defaultValues: {
      financingValue: proposal.financingValue || '',
      financingPayType: proposal.financingPayType || '',
      financingSpouse: proposal.financingSpouse || false,
    },
    reValidateMode: 'onChange'
  })

  const shouldShowSpouseOption = proposal.personMaritalStatus === MaritalStatus.MARRIED
  const fieldLayout = shouldShowSpouseOption ? { xs: 12, sm: 6, md: 3, lg: 3, xl: 3 } : { xs: 12, sm: 6, md: 6, lg: 6, xl: 6 }

  return (
    <form onSubmit={handleSubmit(saveData)}>
      <FormControl >

        <Grid container spacing={1} className={classes.formContainer}>
          <Grid item {...fieldLayout}>
            <Typography variant="caption" display="block" gutterBottom >
              Valor do Financiamento
            </Typography>
            <TextField
              id="financingValue"
              name="financingValue"
              error={!!errors.financingValue}
              helperText={errors?.financingValue?.message}
              placeholder={realEstatePricePlaceHolder}
              variant="outlined"
              margin="dense"
              size="small"
              fullWidth={true}
              InputLabelProps={{
                shrink: true
              }}
              inputProps={{
                maxLength: 60
              }}
              {...register("financingValue", {
                required: "Campo Obrigatório",
                onChange: (e) => {
                  const financingValue = currencyMask(e.target.value)
                  setValue("financingValue", financingValue);
                  setProposal({ ...proposal, financingValue })
                }
              })}
              disabled={isDisabled}
            />
          </Grid>

          <Grid item {...fieldLayout}>
            <Typography variant="caption" display="block" gutterBottom>
              Forma de Pagamento
            </Typography>
            <TextField
              id="financingPayType"
              name="financingPayType"
              error={!!errors.financingPayType}
              helperText={errors?.financingPayType?.message}
              variant="outlined"
              margin="dense"
              size="small"
              fullWidth={true}
              value={watch('financingPayType')}
              select
              {...register("financingPayType", {
                required: "Campo Obrigatório",
                onChange: (e) => {
                  setProposal({ ...proposal, financingPayType: e.target.value })
                }
              })}
              disabled={isDisabled}
            >
            {Object.entries(PaymentType).map(([key, value]) => (
              <MenuItem
                key={key}
                value={value}
              >
                {showPaymentType(value)}
              </MenuItem>
            ))}
            </TextField>
          </Grid>

          {shouldShowSpouseOption &&
            <Grid item xs={12} sm={12} md={6} lg={5} xl={5}>
              <Typography variant="caption" display="block" gutterBottom >
                <div>&nbsp;</div>
              </Typography>

              <FormGroup className={classes.checkBox}>
                <Controller
                  name="financingSpouse"
                  control={control}
                  render={({ field }) => (
                    <FormControlLabel
                      control={
                        <Checkbox
                          onChange={(e) => {
                            field.onChange(e.target.checked)
                            setProposal({ ...proposal, financingSpouse: !!e.target.value })
                          }}
                          checked={field.value}
                        />
                      }
                      label={
                        <div className={classes.checkboxLabel}>
                          <Typography variant="caption" display="block" gutterBottom>
                            Irá compor renda com o cônjuge ou familiar?
                          </Typography>
                          <Tooltip title={INCOME_TYPE_TOOLTIP_HELPER_TEXT} placement="right" arrow={true} >
                            <InfoIcon className={classes.formTitleHelpIcon} />
                          </Tooltip>
                        </div>
                      }
                      disabled={isDisabled}
                    />
                  )}
                />
              </FormGroup>
            </Grid>
          }

          <Grid container spacing={1}>
            <Button variant="contained" type="submit" sx={{ mt: 1, mb: 1 }}>
              Continuar
            </Button>
            <Button onClick={handleBack} sx={{ mt: 1, mr: 1, ml: 1 }}>
              Voltar
            </Button>
          </Grid>

        </Grid>
      </FormControl>
    </form>
  )
}
