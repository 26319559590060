import React from 'react'
import { useHistory } from 'react-router-dom'
import { SvgIconComponent } from '@mui/icons-material'
import HelpOutlineIcon from '@mui/icons-material/HelpOutline'
import { Typography, Box, Button, Tooltip } from '@mui/material'
import { useStyles } from './styles'

type Props = {
  icon?: SvgIconComponent,
  title: string,
  newRoute?: string,
  backRoute?: string,
  showBackButton?: boolean,
  showSaveButton?: boolean,
  helpText?: string
  buttons?: {
    text: string
    color: 'inherit' | 'primary' | 'secondary' | 'success' | 'error' | 'info' | 'warning'
    onClick: () => void
    isDisabled?: boolean
  }[]
}

const renderNewButton = (history: any , route?: string) => {
  if (typeof route !== 'undefined') {
    return <Button variant="contained" color="primary" onClick={() => history.push(route)}>Novo</Button>
  }
}

export interface FormHeaderButton {
  text: string, color: 'inherit' | 'primary' | 'secondary' | 'success' | 'error' | 'info' | 'warning', onClick: () => void, isDisabled?: boolean
}

const renderButton = ({ text, color, onClick, isDisabled = false }: FormHeaderButton) => {
  return <Button variant='contained' color={color} onClick={() => onClick()} style={{marginLeft: 10}} disabled={isDisabled}> {text} </Button>
}

const renderBackButton = (history: any, route?: string, showButton?: boolean) => {
  if (typeof route !== 'undefined' && showButton) {
    return <Button variant="contained" color="primary" onClick={() => history.push(route)} style={{marginLeft: 10}} >Retornar</Button>
  }
}

const renderSaveButton = (showButton?: boolean) => {
  if (typeof showButton !== 'undefined' && showButton) {
    return <Button type="submit" variant="contained" color="primary">Salvar</Button>
  }
}

const renderHelpIcon = (classes: any, helpText?: string) => {
  if (typeof helpText !== 'undefined') {
    if (helpText) {
      return (
        <Tooltip title={helpText} placement="right" arrow={true} >
          <HelpOutlineIcon className={classes.formTitleHelpIcon} />
        </Tooltip>
      )
    }
  }
}

const FormHeader: React.FC<Props> = ({
  icon,
  title,
  newRoute,
  backRoute,
  showSaveButton,
  showBackButton,
  helpText,
  buttons
}: Props) => {
  const classes = useStyles()
  const history = useHistory()

  return (
    <Typography variant="h5" className={classes.formTitle}>
      <Box className={classes.formTitleLeft} key={title}>
        {title}
        { renderHelpIcon(classes, helpText) }
      </Box>
      <Box className={classes.formButtonsRight}>
        {buttons?.map(button => {
          return renderButton({ text: button.text, color: button.color, onClick: button.onClick, isDisabled: button.isDisabled })
        })}
        { renderNewButton(history, newRoute) }
        { renderSaveButton(showSaveButton) }
        { renderBackButton(history, backRoute, showBackButton) }
      </Box>
    </Typography>
  )
}

export { FormHeader }
