import React, { useState, useEffect } from 'react'
import { Paper, InputBase, TablePagination, LinearProgress, Icon, Select, MenuItem } from '@mui/material'
import { Table, TableContainer, TableHead, TableBody, TableRow, TableCell, TableSortLabel } from '@mui/material'
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from '@mui/material'
import { FormatTableFieldType, ITableHeadCellDTO } from 'data/dtos/components/i-table-head-cell-dto'
import { format as formatDate } from 'date-fns'
import { useStyles } from './styles'
import { currencyMask } from 'utils/utils'
import { InviteStatus } from 'hooks/enums/InviteStatus'
import { ProposalPerformanceStatus, showProposalPerformanceStatus } from 'hooks/enums/ProposalStatus'

type Props = {
  headCells: ITableHeadCellDTO[],
  rows: any[],
  totalRows: number,
  handleSearch: React.Dispatch<React.SetStateAction<string>>,
  isLoading: number,
  handleChangeRowsPerPage: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => Promise<void>,
  rowsPerPage: number,
  handleChangePage: (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number, ) => void,
  page: number,
  orderByDirection: boolean,
  setOrderByDirection: React.Dispatch<React.SetStateAction<boolean>>
  columnOrder: ('ASC' | 'DESC')[],
  setColumnOrder: React.Dispatch<React.SetStateAction<('ASC' | 'DESC')[]>>
  buttons: {
    name: string
    icon: React.ElementType,
    onClick: (rowID: string) => void;
  }[]
}

const formatString = (fieldContent: string, format: FormatTableFieldType) => {
  let output = fieldContent
  switch (format) {
    case FormatTableFieldType.CNPJ:
      output = output.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5")
      break
    case FormatTableFieldType.DATE:
      output = formatDate(new Date(output), 'dd/MM/yyyy HH:mm')
      break
    case FormatTableFieldType.INVITE_STATUS:
      if(output === InviteStatus.ACTIVE)
        output = 'Pendente'
      else if(output === InviteStatus.CANCELLED)
        output = 'Cancelado'
      else if(output === InviteStatus.CONSUMED)
        output = 'Aceito'
      break
    case FormatTableFieldType.PROPOSAL_PERFORMANCE_STATUS:
      output = showProposalPerformanceStatus(fieldContent as ProposalPerformanceStatus)
      break
    case FormatTableFieldType.MONEY:
      output = currencyMask(output)
      break
    case FormatTableFieldType.BOOLEAN:
      output = output.toString() === "true" ? "Sim" : "Não"
      break;
  }

  return output
}

const CustomTable: React.FC<Props> = ({
  headCells,
  rows,
  totalRows,
  handleSearch,
  isLoading,
  handleChangeRowsPerPage,
  rowsPerPage,
  handleChangePage,
  page,
  orderByDirection,
  setOrderByDirection,
  columnOrder,
  setColumnOrder,
  buttons
}: Props) => {
  const [open, setOpen] = useState(false)



  const classes = useStyles()
  const defaultWidth = (headCells.length > 1 ? 100 / headCells.length : 100)

  const handleClose = () => {
    setOpen(false)
  }

  const handleCloseDelete = () => {
    setOpen(false)
  }

  const handleSortChange = (index: number) => {
    columnOrder[index] = columnOrder[index] === 'ASC' ? 'DESC' : 'ASC'

    setColumnOrder(columnOrder)
    setOrderByDirection(!orderByDirection)
  }

  useEffect(() => {
    const sortArray: Array<('ASC' | 'DESC')> = headCells.map(() => 'ASC')

    setColumnOrder(sortArray)
  }, [headCells, setColumnOrder])

  return (
    <>
      <div className={classes.search}>
        <InputBase
          placeholder={'Informe o termo de busca aqui...'}
          className={classes.inputInput}
          onChange={ (e) => { handleSearch(e.target.value) } }
        />
      </div>

      <div className={ isLoading === 1 ? classes.linearProgressOn : classes.linearProgressOff }>
        <LinearProgress />
      </div>

      <Paper className={classes.tablePaper}>
        <TableContainer className={classes.tableContainer}>
          <Table stickyHeader>
            <TableHead key={0}>
              <TableRow key={0}>
                {headCells.map((headCell, index) => (
                  <TableCell align="left" key={index} style={{ width: `${typeof(headCell.width) !== 'undefined' ? (headCell.width * 100 / 11) : defaultWidth}%`, fontWeight: 'bold', paddingLeft: '100px' }}>
                    {headCell.allowSort ? (
                      <TableSortLabel
                      active={false}
                      direction={columnOrder[index] === 'ASC' ? 'asc' : 'desc'}
                      onClick={() => handleSortChange(index)}
                    >
                      {headCell.label}
                    </TableSortLabel>
                    ) : (headCell.label)}
                  </TableCell>
                ))}
                {buttons.map((button) => (
                  <TableCell className={classes.rowTableIcon}>{button.name}</TableCell>
                ))}
              </TableRow>
            </TableHead>

            <TableBody>
              {rows.map((row) => (
              <TableRow hover key={row.id}>
                {headCells.map((headCell) => (
                  headCell.format === FormatTableFieldType.COMBO_BOX ? (
                    <TableCell align="left" key={headCell.id} style={{ minWidth: 100 }}>
                      <Select
                        fullWidth
                        defaultValue={row[headCell.id]}
                        onChange={(event) => headCell.onChange({
                          id: row.id,
                          officerUserID: event.target.value
                        })}
                      >
                        {headCell.possibleValues.map(value => (
                          <MenuItem
                            key={value.id}
                            value={value.id}
                          >
                          {value.value}
                        </MenuItem>
                        ))}
                      </Select>
                    </TableCell>
                  ) : (
                    <TableCell align="left" key={headCell.id} style={{ minWidth: 100 }}>
                      { formatString(row[headCell.id], headCell.format ?? FormatTableFieldType.NO_FORMAT) }
                    </TableCell>
                  )
                ))}

                {buttons.map((button) => (
                  <TableCell align='center' className={classes.rowTableIcon}><Button className={classes.tableIconButton} onClick={() => button.onClick(row.id)}><Icon component={button.icon}/></Button></TableCell>
                ))}
              </TableRow>
              ))}
            </TableBody>

          </Table>
        </TableContainer>

        <TablePagination
          rowsPerPageOptions={[10, 25, 50, 100]}
          component="div"
          count={totalRows}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          labelRowsPerPage="Linhas por página"
          labelDisplayedRows={({ from, to, count }) => `Página ${page + 1}, ${from}-${to} de ${count}`}
          sx={{
            '.MuiSvgIcon-root': {
              position: 'relative'
            }
          }}
        />
      </Paper>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Exclusão de Registro</DialogTitle>

        <DialogContent style={{ width: '500px' }}>
          <DialogContentText id="alert-dialog-description">
            Deseja realmente excluir o registro?
          </DialogContentText>
        </DialogContent>

        <DialogActions>
          <Button onClick={handleClose} variant="contained">Não</Button>
          <Button onClick={handleCloseDelete} variant="contained">Sim</Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export { CustomTable }
