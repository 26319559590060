export enum RealEstateType {
  RESIDENTIAL = 'RESIDENTIAL',
  COMMERCIAL = 'COMMERCIAL',
}

export const showRealStateType = (realStateType: RealEstateType): string => {
  switch (realStateType) {
    case RealEstateType.RESIDENTIAL:
      return 'Residencial'
    case RealEstateType.COMMERCIAL:
      return 'Comercial'
    default:
      return 'Tipo de residência inválido'
  }
}
