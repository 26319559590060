import { Button, FormControl, Grid, TextField, Typography } from "@mui/material";
import { ProposalContext } from "contexts/proposal-context"
import { IProposalDTO } from "data/dtos/i-proposal-dto";
import { useContext } from "react"
import { useForm } from "react-hook-form";
import { useStyles } from "../../styles";
import { ProposalPropertyEvaluationFormStep } from "./form-step";
import { currencyMask, realEstatePricePlaceHolder } from "utils/utils";

type Props = {
  isDisabled: boolean
  setProposalFormStep: React.Dispatch<React.SetStateAction<ProposalPropertyEvaluationFormStep>>
}

export const PropertyEvaluationDataForm = ({ isDisabled, setProposalFormStep }: Props) => {
  const { proposal, setProposal } = useContext(ProposalContext);
  const classes = useStyles()

  const saveData = (spousePersonalData: IProposalDTO) => {
    setProposal({...proposal, ...spousePersonalData})
    setProposalFormStep(ProposalPropertyEvaluationFormStep.DOCUMENTATION)
  }

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<IProposalDTO>({
    defaultValues: {
      realEstateAppraisalValue: proposal.realEstateAppraisalValue || '',
    },
    reValidateMode: 'onChange'
  })

  return (
    <form onSubmit={handleSubmit(saveData)}>
      <FormControl >
        <Grid container spacing={1} className={classes.formContainer}>

          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Typography variant="caption" display="block" gutterBottom>
                Valor da avaliação *
              </Typography>
              <TextField
                id="realEstateAppraisalValue"
                name="realEstateAppraisalValue"
                error={!!errors.realEstateAppraisalValue}
                helperText={errors?.realEstateAppraisalValue?.message}
                placeholder={realEstatePricePlaceHolder}
                variant="outlined"
                margin="dense"
                size="small"
                fullWidth={true}
                InputLabelProps={{
                  shrink: true
                }}
                inputProps={{
                  maxLength: 60
                }}
                {...register("realEstateAppraisalValue", {
                  required: "Campo Obrigatório",
                  onChange: (e) => {
                    setValue("realEstateAppraisalValue", currencyMask(e.target.value));
                  },
                })}
                disabled={isDisabled}
              />
            </Grid>
          </Grid>

          <Grid container spacing={1}>
            <Button
              variant="contained"
              type="submit"
              sx={{ mt: 1, mb: 1 }}
            >
              Continuar
            </Button>
          </Grid>
        </Grid>
      </FormControl>
    </form>
  )
}
