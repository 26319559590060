import React, { useState, useEffect, useCallback } from 'react'
import { useIdleTimer } from 'react-idle-timer'
import { useAuth } from 'hooks/auth'
import { SvgIconProps, Box } from '@mui/material'
import api from 'services/api'
import { useApplication } from 'hooks/use-application'

import { useStyles } from "./styles"
import { SideMenu, AppHeader } from './components'
import { useProfile } from 'hooks/use-profile'

interface SubMenuOption {
  id: string
  icon: React.ReactElement<SvgIconProps>
  text: string
  route: string
}

export interface MenuOption {
  id: string
  icon: React.ReactElement<SvgIconProps>
  text: string
  route: string
  subMenuOptions: SubMenuOption[]
}

const DefaultLayout: React.FC = ({ children }) => {
  const [userMenu, setUserMenu] = useState<MenuOption[]>([])
  const { menuOpen } = useApplication();
  const { selectedProfile } = useProfile();

  const { signOut } = useAuth();

  const { getRemainingTime, getLastActiveTime } = useIdleTimer({
    timeout: 1000 * 60 * 15,
    onIdle: () => {
      signOut()
    }
  })

  useEffect(() => {
    async function loadData() {
        await api
        .post(`/users-security/get-menu/${selectedProfile}`)
        .then(response => {
          return response.data
        })
        .then((stateResult) => {
          setUserMenu(stateResult)
        })
        .catch(error => {
          return error
        })
    }

    loadData()
  }, [selectedProfile])


  const classes = useStyles()

  return (
    <Box className={classes.container}>
      <AppHeader />
      <SideMenu options={userMenu} />
      <Box className={ `${classes.mainContentWrapper} ${menuOpen ? classes.mainContentWrapperOpen : classes.mainContentWrapperClose}` }>
        { children }
      </Box>
    </Box>
  )
}

export default DefaultLayout
