export const CNH_TOOLTIP_HELPER_TEXT = "Carteira Nacional de Habilitação válida. Documento completo."
export const RG_FRONT_TOOLTIP_HELPER_TEXT = "RG ou RNE (caso estrangeiro) válido, parte da frente."
export const RG_BACK_TOOLTIP_HELPER_TEXT = "RG ou RNE (caso estrangeiro) válido, verso."
export const CPF_TOOLTIP_HELPER_TEXT = "CPF válido."
export const CIVIL_STATUS_TOOLTIP_HELPER_TEXT = "Certidão de Casamento (caso casado), ou Certidão de Nascimento (caso solteiro, divorciado ou viúvo)."
export const ADDRESS_TOOLTIP_HELPER_TEXT = "Comprovante de endereço recente (últimos 2 meses) em nome do requerente."
export const LATEST_PAY_STUB_TOOLTIP_HELPER_TEXT = "Holerite do último mês (mês anterior ao da proposta de financeiamento) em nome do requerente, ou extrato bancário do último mês, caso requerente não possua holerite."
export const PENULTIMATE_PAY_STUB_TOOLTIP_HELPER_TEXT = "Holerite do penúltimo mês (2 meses anteriores ao da proposta de financeiamento) em nome do requerente, ou extrato bancário do penúltimo mês, caso requerente não possua holerite."
export const INCOME_TAX_TOOLTIP_HELPER_TEXT = "Última declaração completa de Imposto de Renda entregue pelo requerente à Receita Federal."
export const INCOME_TAX_RECEIPT_TOOLTIP_HELPER_TEXT = "Recibo de entrega da última declaração completa de Imposto de Renda do requerente."
export const EXTRACT_FGTS_TOOLTIP_HELPER_TEXT = "Extrato atualizado do FGTS do requerente, caso deseje utilizar o FGTS para financiar o imóvel. O documento em questão deve ser solicitado diretamente com a Caixa Econômica Federal ou pelo aplicativo de celular FGTS."
export const AUTHORIZATION_FGTS__TOOLTIP_HELPER_TEXT = "Formulário preenchido e assinado pelo requerente, autorizando a utilização do FGTS. O formulário deve seguir o padrão do banco ao qual o requerente está solicitando o financiamento."

export const SOCIAL_CONTRACT_TOOLTIP_HELPER_TEXT = "Contrato Social da empresa ou MEI (Certificado da Condição de Microempreendedor Individual), caso empresa seja enquadrada nessa categoria."
export const SIMPLIFIED_CERTIFICATE_TOOLTIP_HELPER_TEXT = "Certidão simplificada da junta comercial do Estado em que a empresa está registrada, com informações atualizadas."
export const DOCUMENT_LEGAL_REPRESENTATIVES_TOOLTIP_HELPER_TEXT = "RG, CPF ou CNH válidos de todos os representantes legais da empresa."
export const POWER_OF_ATTORNEY_TOOLTIP_HELPER_TEXT = "Documento atualizado que define o procurador que assina pela empresa."
export const ATTORNEY_DOCUMENT_TOOLTIP_HELPER_TEXT = "RG, CPF ou CNH válidos do procurador da empresa."

export const UPDATE_REGISTRATION_TOOLTIP_HELPER_TEXT = "Certidão de Inteiro Teor do imóvel. Pode ser solicitada presencialmente no Cartório de Registro Civil ou online no site do Cartório Online Brasil ou Registradores."
export const IPTU_TOOLTIP_HELPER_TEXT = "Capa da guia do IPTU do imóvel referente ao ano vigente."
export const OCCUPANCY_PERMIT_TOOLTIP_HELPER_TEXT = "Apenas para imóveis novos. Solicite o documento junto à construtora do imóvel."
export const CREA_CAU__TOOLTIP_HELPER_TEXT = "Apenas para imóveis novos. Solicite o documento junto à construtora do imóvel."
export const ART_RTT_TOOLTIP_HELPER_TEXT = "Apenas para imóveis novos. Solicite o documento junto à construtora do imóvel."

export const APPRAISAL_REPORT_TOOLTIP_HELPER_TEXT = "Relatório de avaliação do imóvel fornecido pelo banco."

export const INCOME_TYPE_TOOLTIP_HELPER_TEXT = "A composição de renda é permitida pela maior parte dos bancos, com cônjuges casados ou companheiros com a união estável registrada em cartório. Caso deseje compor renda com um parente, namorado ou amigo, entre em contato com o officer Finteto."
