export enum DocumentType {
  RG = 'RG',
  CNH = 'CNH',
  PASSPORT = 'PASSPORT',
  NATIONAL_ALIEN_REGISTRATION = 'NATIONAL_ALIEN_REGISTRATION',
}

export const showDocumentType = (documentType: DocumentType): string => {
  switch (documentType) {
    case DocumentType.RG:
      return 'Identidade (RG)'
    case DocumentType.CNH:
        return 'Carteira Nacional de Habilitação (CNH)'
    case DocumentType.PASSPORT:
      return 'Passaporte'
    case DocumentType.NATIONAL_ALIEN_REGISTRATION:
      return 'Registro Nacional de Estrangeiro'
    default:
      return 'Tipo de documento inválido'
  }
}
