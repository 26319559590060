import React from 'react'
import { Container, Box, Typography, Grid, Link, Divider } from '@mui/material'
import { useStyles } from './styles'

type Props = React.HTMLAttributes<HTMLElement>

const PublicFooter: React.FC<Props> = (props: Props) => {
	const classes = useStyles()

	return (
    <Box component="footer" className={classes.footer}>
      <Container maxWidth="xl">
        <Typography variant="body2" fontSize={12}>
          A Finteto é uma plataforma eletrônica que atua como correspondente bancário para facilitar o processo de contratação de financiamento e empréstimos. Como correspondente bancário, seguimos as diretrizes da Resolução no 4.935 do Banco Central do Brasil. Atuamos como correspondente bancário das seguintes instituições financeiras: Banco Bradesco S.A. (CNPJ 60.746.948/0001-12), Itaú Unibanco S.A. (CNPJ 60.701.190/0001-04) e Banco de Brasília S.A. (CNPJ 00.000.208/0001-00). Finteto Soluções Financeiras Ltda. (CNPJ 42.822.190/0001-72), localizada na Rua Líbero Badaró, no 377, conj. 803, Centro, na Cidade de São Paulo, Estado de São Paulo, CEP 01009-906.
        </Typography>
        <Divider variant="fullWidth" sx={{ borderBottomWidth: 1, my: 1 }} />
        <Typography component={'span'} variant="body2" mb={2} fontSize={12}>
          © Copyright {(new Date().getFullYear())} Finteto Soluções Financeiras Ltda.
        </Typography>
      </Container>
    </Box>
	)
}

export default PublicFooter
