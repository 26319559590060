import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import logo from 'assets/logo.svg'
import { useStyles } from './styles';

const PublicAppHeader: React.FC = () => {
  const classes = useStyles()

  return (
    <AppBar position="relative" className={classes.appBar}>
      <Container maxWidth="xl">
        <Toolbar >
          <img src={logo} alt="Finteto Logo" className={classes.logo} />

          <Box sx={{ ml: 'auto' }}>
            <Button sx={{ mr: 1 }} href="https://www.finteto.com" target="_blank" >Finteto</Button>
            <Button sx={{ mr: 1 }} href="https://www.finteto.com/produtos" target="_blank" >Produtos</Button>
            <Button sx={{ mr: 1 }} href="https://www.finteto.com/area-do-parceiro" target="_blank">Área do Parceiro</Button>
            <Button sx={{ }} href="https://www.finteto.com/area-do-cliente" target="_blank">Área do Cliente</Button>
            <Button sx={{ mr: 1 }} href="https://www.finteto.com/contato" target="_blank">Contato</Button>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}

export default PublicAppHeader
