import { Box, Button, FormControl, Grid, MenuItem, TextField, Typography } from "@mui/material";
import { ProposalContext } from "contexts/proposal-context"
import { IProposalDTO } from "data/dtos/i-proposal-dto";
import { MaritalStatus } from "hooks/enums/MaritalStatus";
import { useContext, useState } from "react"
import { useForm } from "react-hook-form";
import { completeNamePlaceHolder, cpfMask, cpfMaskPlaceholder, currencyMask, emailPlaceHolder, incomeMaskPlaceHolder, jobPositionPlaceHolder, legalNamePlaceHolder, nationalityPlaceHolder, phoneMask, phoneMaskPlaceholder, professionPlaceHolder } from "utils/utils";
import { useStyles } from "../../styles";
import { createMask } from "imask";
import { IncomeType, showIncomeType } from "hooks/enums/IncomeType";
import { ProposalDataFormStep } from "./form-step";

type Props = {
  isDisabled: boolean
  setProposalFormStep: React.Dispatch<React.SetStateAction<ProposalDataFormStep>>
}

export const SpouseProfessionalData = ({ isDisabled, setProposalFormStep }: Props) => {
  const { proposal, setProposal } = useContext(ProposalContext);
  const classes = useStyles()

  const saveData = (spousePersonalData: IProposalDTO) => {
    setProposal({...proposal, ...spousePersonalData})
    setProposalFormStep(ProposalDataFormStep.REAL_STATE_DATA)
  }

  const handleBack = () => {
    setProposalFormStep(ProposalDataFormStep.SPOUSE_PERSONAL_DATA)
  }

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm<IProposalDTO>({
    defaultValues: {
      spouseProfession: proposal.spouseProfession || '',
      spouseJobPosition: proposal.spouseJobPosition || '',
      spouseCompanyName: proposal.spouseCompanyName || '',

      spouseHireDate: proposal.spouseHireDate || '',
      spouseGrossIncome: proposal.spouseGrossIncome || '',
      spouseNetIncome: proposal.spouseNetIncome || '',
      spouseIncomeType: proposal.spouseIncomeType || '',
    },
    reValidateMode: 'onChange'
  })

  return (
    <form onSubmit={handleSubmit(saveData)}>
      <FormControl >
        <Grid container spacing={1} className={classes.formContainer}>

          <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
            <Typography variant="caption" display="block" gutterBottom >
              Profissão *
            </Typography>
            <TextField
              id="spouseProfession"
              name="spouseProfession"
              error={!!errors.spouseProfession}
              helperText={errors?.spouseProfession?.message}
              placeholder={professionPlaceHolder}
              variant="outlined"
              margin="dense"
              size="small"
              fullWidth={true}
              InputLabelProps={{
                shrink: true
              }}
              inputProps={{
                maxLength: 60
              }}
              {...register("spouseProfession", {
                required: "Campo Obrigatório",
                onChange: (e) => {
                  setProposal({ ...proposal, spouseProfession: e.target.value })
                }
              })}
              disabled={isDisabled}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
            <Typography variant="caption" display="block" gutterBottom >
              Cargo *
            </Typography>
            <TextField
              id="spouseJobPosition"
              name="spouseJobPosition"
              error={!!errors.spouseJobPosition}
              helperText={errors?.spouseJobPosition?.message}
              placeholder={jobPositionPlaceHolder}
              variant="outlined"
              margin="dense"
              size="small"
              fullWidth={true}
              InputLabelProps={{
                shrink: true
              }}
              inputProps={{
                maxLength: 60
              }}
              {...register("spouseJobPosition", {
                required: "Campo Obrigatório",
                onChange: (e) => {
                  setProposal({ ...proposal, spouseJobPosition: e.target.value })
                }
              })}
              disabled={isDisabled}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
            <Typography variant="caption" display="block" gutterBottom >
              Nome da Empresa *
            </Typography>
            <TextField
              id="spouseCompanyName"
              name="spouseCompanyName"
              error={!!errors.spouseCompanyName}
              helperText={errors?.spouseCompanyName?.message}
              placeholder={legalNamePlaceHolder}
              variant="outlined"
              margin="dense"
              size="small"
              fullWidth={true}
              InputLabelProps={{
                shrink: true
              }}
              inputProps={{
                maxLength: 60
              }}
              {...register("spouseCompanyName", {
                required: "Campo Obrigatório",
                onChange: (e) => {
                  setProposal({ ...proposal, spouseCompanyName: e.target.value })
                }
              })}
              disabled={isDisabled}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
            <Typography variant="caption" display="block" gutterBottom >
              Data Admissão *
            </Typography>
            <TextField
              id="spouseHireDate"
              name="spouseHireDate"
              error={!!errors.spouseHireDate}
              helperText={errors?.spouseHireDate?.message}
              variant="outlined"
              margin="dense"
              size="small"
              fullWidth={true}
              type="date"
              InputLabelProps={{
                shrink: true
              }}
              {...register("spouseHireDate", {
                required: "Campo Obrigatório",
                onChange: (e) => {
                  setProposal({ ...proposal, spouseHireDate: e.target.value })
                }
              })}
              disabled={isDisabled}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
            <Typography variant="caption" display="block" gutterBottom >
              Renda Bruta Mensal*
            </Typography>
            <TextField
              id="spouseGrossIncome"
              name="spouseGrossIncome"
              error={!!errors.spouseGrossIncome}
              helperText={errors?.spouseGrossIncome?.message}
              placeholder={incomeMaskPlaceHolder}
              variant="outlined"
              margin="dense"
              size="small"
              fullWidth={true}
              InputLabelProps={{
                shrink: true
              }}
              inputProps={{
                maxLength: 60
              }}
              {...register("spouseGrossIncome", {
                required: "Campo Obrigatório",
                onChange: (e) => {
                  const spouseGrossIncome = currencyMask(e.target.value)
                  setValue("spouseGrossIncome", spouseGrossIncome)
                  setProposal({ ...proposal, spouseGrossIncome })
                }
              })}
              disabled={isDisabled}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
            <Typography variant="caption" display="block" gutterBottom >
              Renda Líquida Mensal *
            </Typography>
            <TextField
              id="spouseNetIncome"
              name="spouseNetIncome"
              error={!!errors.spouseNetIncome}
              helperText={errors?.spouseNetIncome?.message}
              placeholder={incomeMaskPlaceHolder}
              variant="outlined"
              margin="dense"
              size="small"
              fullWidth={true}
              InputLabelProps={{
                shrink: true
              }}
              inputProps={{
                maxLength: 60
              }}
              {...register("spouseNetIncome", {
                required: "Campo Obrigatório",
                onChange: (e) => {
                  const spouseNetIncome = currencyMask(e.target.value)
                  setValue("spouseNetIncome", spouseNetIncome);
                  setProposal({ ...proposal, spouseNetIncome })
                }
              })}
              disabled={isDisabled}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
            <Typography variant="caption" display="block" gutterBottom >
              Tipo de Renda *
            </Typography>
            <TextField
              id="spouseIncomeType"
              name="spouseIncomeType"
              error={!!errors.spouseIncomeType}
              helperText={errors?.spouseIncomeType?.message}
              variant="outlined"
              margin="dense"
              size="small"
              fullWidth={true}
              value={watch('spouseIncomeType')}
              select
              {...register("spouseIncomeType", {
                required: "Campo Obrigatório",
                onChange: (e) => {
                  setProposal({ ...proposal, spouseIncomeType: e.target.value })
                }
              })}
              disabled={isDisabled}
            >
            {Object.entries(IncomeType).map(([key, value]) => (
              <MenuItem
                key={key}
                value={value}
              >
                {showIncomeType(value)}
              </MenuItem>
            ))}
            </TextField>
          </Grid>

          <Grid container spacing={1}>
            <Button variant="contained" type="submit" sx={{ mt: 1, mb: 1 }}>
              Continuar
            </Button>
            <Button onClick={handleBack} sx={{ mt: 1, mr: 1, ml: 1 }}>
              Voltar
            </Button>
          </Grid>
        </Grid>
      </FormControl>
    </form>
  )
}
