import { Box, Step, StepContent, StepLabel, Stepper, TextField, Typography } from "@mui/material"
import { useState } from "react"
import { ProposalPropertyEvaluationFormStep } from "./form-step"
import { SubmitProposal } from "../proposal-data/submit-proposal"
import { PropertyEvaluationDataForm } from "./property-evaluation-data-form"
import { PropertyEvaluationDocumentationForm } from "./property-evaluation-documentation-form"
import { ProposalPhase } from "hooks/enums/ProposalStatus"

type Props = {
  canEdit: boolean
}

const propertyEvaluationFormSteps = [
  { label: 'Digite o valor da avaliação' },
  { label: 'Documentação' }
];

export const PropertyEvaluationForm = ({ canEdit }: Props) => {
  const [proposalFormStep, setProposalFormStep] = useState<ProposalPropertyEvaluationFormStep>(ProposalPropertyEvaluationFormStep.EVALUATION_DATA);

  function getFormStep(formStep: ProposalPropertyEvaluationFormStep) {
    switch (formStep) {
      case ProposalPropertyEvaluationFormStep.EVALUATION_DATA:
        return <PropertyEvaluationDataForm isDisabled={!canEdit} setProposalFormStep={setProposalFormStep} />
      case ProposalPropertyEvaluationFormStep.DOCUMENTATION:
        return <PropertyEvaluationDocumentationForm isDisabled={!canEdit} setProposalFormStep={setProposalFormStep} />
    }
  }

  return (
    <>
      <Box sx={{ maxWidth: 1200, mx: 'auto', mt: 5, mb: 5 }}>
        <Stepper activeStep={proposalFormStep} orientation="vertical" >
          {propertyEvaluationFormSteps.map((step, index) => (
            <Step key={step.label}>
              <StepLabel>
                {step.label}
              </StepLabel>
              <StepContent>
                {getFormStep(proposalFormStep)}
              </StepContent>
            </Step>
          ))}
        </Stepper>
        {proposalFormStep === ProposalPropertyEvaluationFormStep.COMPLETED && (
          <SubmitProposal redirectToPhase={ProposalPhase.CONTRACTING} />
        )}
      </Box>
    </>
  )
}

