import React, { useEffect, useState } from 'react'
import NoProposals from 'components/no-proposals'
import ProposalSummaries, { ProposalSummary } from 'components/proposal-summary';
import api from 'services/api';
import { CircularProgress } from '@mui/material';
import { useStyles } from './styles';
import { FormAlert } from 'components';

const FollowUp: React.FC = () => {
  const classes = useStyles()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [proposals, setProposals] = useState<ProposalSummary[]>([])
  const [mainError, setMainError] = useState<string>('');


  const loadProposalSummaries = async() => {
    setIsLoading(true)
    try {
      const proposalSummaries = await api.get('/proposals/summary')
      setProposals(proposalSummaries.data)
    } catch (err) {
      setMainError('Não foi possível carregar as propostas, tente novamente mais tarde.');
    }
    setIsLoading(false)
  }

  useEffect(() => {
    loadProposalSummaries()
  }, [])

  if(isLoading) {
    return <CircularProgress color="primary" className={classes.spinner} />
  }

  return (
    <>
      <FormAlert setMainError={setMainError} mainError={mainError} />
      {proposals && proposals.length ? (
        <ProposalSummaries proposals={proposals}/>
      ) : (
        <NoProposals/>
      )}
    </>
  )
}

export default FollowUp
