import React, { useState, useEffect } from 'react'
import { AppBar, Toolbar, IconButton, Badge, MenuItem, Menu, Box, SelectChangeEvent, Tabs, Tab, Typography, Chip, Stack, ListItemIcon, ListItemText } from '@mui/material'
import { Avatar, Divider, Select } from '@mui/material'
import MenuIcon from '@mui/icons-material/Menu';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import MailIcon from '@mui/icons-material/Mail'
import AccountCircle from '@mui/icons-material/AccountCircle'
import EmailIcon from '@mui/icons-material/Email';
import SettingsIcon from '@mui/icons-material/Settings';
import LogoutIcon from '@mui/icons-material/Logout';
import MoreIcon from '@mui/icons-material/MoreVert'
import { useAuth } from 'hooks/auth'
import logo from 'assets/logo.svg'
import { useApplication } from 'hooks/use-application'
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
// import { SearchInput } from 'components/layouts/default/components'
import { styled } from '@mui/material/styles'

import { useStyles } from "./styles"
import api from 'services/api';
import { useProfile } from 'hooks/use-profile';
import { ProfileType } from 'hooks/enums/ProfileType';
import { useHistory } from 'react-router-dom';
import { capitalizeFirstName } from 'utils/utils';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const StyledBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    backgroundColor: '#44b700',
    color: '#44b700',
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    '&::after': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      borderRadius: '50%',
      animation: 'ripple 1.2s infinite ease-in-out',
      border: '1px solid currentColor',
      content: '""',
    },
  },
  '@keyframes ripple': {
    '0%': {
      transform: 'scale(.8)',
      opacity: 1,
    },
    '100%': {
      transform: 'scale(2.4)',
      opacity: 0,
    },
  },
}));

const AppHeader: React.FC = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState<null | HTMLElement>(null)
  const [notificationAnchorEl, setNotificationAnchorEl] = useState<null | HTMLElement>(null)
  const [profiles, setProfiles] = useState([])
  const [notifications, setNotifications] = useState([])
  const [hasNewNotification, setHasNewNotification] = useState(false)
  const [notificationTabValue, setNotificationTabValue] = useState(0)
  const isMenuOpen = Boolean(anchorEl)
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl)
  const isNotificationMenuOpen = Boolean(notificationAnchorEl)
  const menuId = 'account-menu'
  const mobileMenuId = 'account-menu-mobile'
  const notificationMenuId = 'notification-menu'
  const classes = useStyles()
  const { signOut, user } = useAuth();
  const { menuOpen, setMenuOpen } = useApplication();
  const { selectedProfile, setSelectedProfile } = useProfile();
  const history = useHistory()

  const handleChangeProfile = (event: SelectChangeEvent) => {
    setSelectedProfile(event.target.value)
  };

  const handleDrawerOpen = () => {
    setMenuOpen(!menuOpen);
  };

  const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null)
  }

  const handleMenuClose = () => {
    setAnchorEl(null)
    handleMobileMenuClose()
  }

  const handleClickOnProfile = (userID: string) => {
    history.push(`/users/edit/${userID}`)
  }

  const handleNotificationMenuClose = () => {
    setNotificationAnchorEl(null)
  }

  const handleMobileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setMobileMoreAnchorEl(event.currentTarget)
  }

  const handleNotificationMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setNotificationAnchorEl(event.currentTarget)
  }

  const handleChangeNotificationPage = (event: React.SyntheticEvent, newValue: number) => {
    setNotificationTabValue(newValue)
  }

  useEffect(() => {
    async function loadProfiles() {

      // load profiles
      if(user.profiles.includes(ProfileType.ADMIN)) {
        await api
        .post('/profiles/select')
        .then(response => {
          const { data } = response.data

          return data
        })
        .then((profilesResult) => {
          setProfiles(profilesResult)
          setSelectedProfile(profilesResult[0].id)
        })
        .catch(error => {
          return error
        })
      } else {
        setSelectedProfile(user.profiles[0])
      }

      // load notifications

      const notificationBody = {
        search: "",
        rowsPerPage: 5,
        page: 0,
        columnOrder: ["ASC", "ASC"]
      }
      await api
        .post('/notification-destinations/list', notificationBody)
        .then(response => {
          const { data } = response.data

          return data
        })
        .then((notificationsResult) => {
          setNotifications(notificationsResult)
          if (notificationsResult.length > 0) {
            setHasNewNotification(true)
          }
        })
        .catch(error => {
          return error
        })
    }

    loadProfiles()
  }, [])

  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMenuOpen}
      onClose={handleMenuClose}
      PaperProps={{
        style: {
          marginTop: '3px',
        }
      }}
    >
      <MenuItem onClick={() => handleClickOnProfile(user.id)}>
        <ListItemIcon>
          <AccountCircleIcon fontSize='small' />
        </ListItemIcon>
        Meu Perfil
      </MenuItem>
      <Divider />
      <MenuItem onClick={signOut}>
        <ListItemIcon>
          <LogoutIcon fontSize='small' />
        </ListItemIcon>
        Sair
      </MenuItem>
    </Menu>
  )

  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem>
        <IconButton aria-label="show 4 new mails" color="inherit">
          <Badge badgeContent={4} color="secondary">
            <MailIcon />
          </Badge>
        </IconButton>
        <Typography>Messages</Typography>
      </MenuItem>
      <MenuItem>
        <IconButton aria-label="show 11 new notifications" color="inherit">
          <Badge badgeContent={11} color="secondary">
            <EmailIcon className={classes.appIcon} />
          </Badge>
        </IconButton>
        <Typography>Notifications</Typography>
      </MenuItem>
      <MenuItem onClick={handleProfileMenuOpen}>
        <IconButton
          aria-label="account of current user"
          aria-controls="account-menu"
          aria-haspopup="true"
          color="inherit"
        >
          <AccountCircle />
        </IconButton>
        <Typography>Profile</Typography>
      </MenuItem>
    </Menu>
  )

  const TabPanel = (props: TabPanelProps) => {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 1 }}>
            {children}
          </Box>
        )}
      </div>
    );
  }

  const NotificationsPanel = () => {
    return (
      notifications.length > 0
        ? <Box>
            {notifications.map((notification) => (
              <Box className={classes.notificationsBox}>
                <Box>
                  <Typography fontWeight="bold">{notification.notificationSubject}</Typography>
                  <Typography>{notification.notificationMessage}</Typography>
                </Box>

                <Typography>{(new Date(notification.notificationDatetimeSending)).toLocaleDateString()}</Typography>
              </Box>
            ))}
          </Box>
        : <Typography>Não há novas notificações</Typography>
    )
  }

  const MessagesPanel = () => {
    return (
      notifications.length > 0
        ? <Typography>Há novas mensagens</Typography>
        : <Typography>Não há novas mensagens</Typography>
    )
  }

  const renderNotificationMenu = (
    <Menu
      anchorEl={notificationAnchorEl}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      id={notificationMenuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isNotificationMenuOpen}
      onClose={handleNotificationMenuClose}
      classes={{ paper: classes.notificationsMenu }}
    >
      <Tabs value={notificationTabValue} onChange={handleChangeNotificationPage} variant="fullWidth">
        <Tab label="Notificações"/>
        <Tab label="Mensagens"/>
      </Tabs>

      <TabPanel value={notificationTabValue} index={0}>
        <NotificationsPanel />
      </TabPanel>
      <TabPanel value={notificationTabValue} index={1}>
        <MessagesPanel />
      </TabPanel>
    </Menu>
  );

  return (
    <AppBar position="fixed" className={classes.appBar}>
      <Toolbar>
        <Box className={classes.logoArea}>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            className={classes.hamburguerMenuContainer}
          >
            {menuOpen === true ? <MenuIcon className={classes.hamburguerMenu}/> : <MenuOpenIcon className={classes.hamburguerMenu}/>}
          </IconButton>
          <img src={logo} alt="Finteto Logo" className={classes.logo} />
        </Box>

        {user.profiles.includes(ProfileType.ADMIN) &&
          <Select className={classes.profilesSelect} value={selectedProfile} onChange={handleChangeProfile}>
            {profiles.map((profile) => (
              <MenuItem key={profile.id} value={profile.id}>{profile.name}</MenuItem>
            ))}
          </Select>
        }

        <div className={classes.grow} />

        <div className={classes.sectionDesktop}>
          {/* <SearchInput /> */}
          {/* Notifications/Notificação Menu - disabled for now since this will be released latter
          <StyledBadge
            overlap="circular"
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            variant="dot"
            onClick={handleNotificationMenuOpen}
            invisible={!hasNewNotification}
          >
            <Avatar
              sx={{
                bgcolor: 'white',
                height: 30,
                width: 30,
                marginLeft: '10px',
                cursor: 'pointer'
              }}>
              <EmailIcon className={classes.appIcon} />
            </Avatar>
          </StyledBadge> */}


          <Chip
            icon={<SettingsIcon className={classes.profileIcon}/>}
            label={capitalizeFirstName(user.name)}
            onClick={handleProfileMenuOpen}
            color="info"
            size="medium"
            variant="filled"
            sx={{
              backgroundColor: 'white',
              color: theme => theme.palette.primary.main,
              '&:hover': {
                backgroundColor: 'white',
              },
            }}
          />
        </div>

        <div className={classes.sectionMobile}>
          <IconButton
            aria-label="show more"
            aria-controls={mobileMenuId}
            aria-haspopup="true"
            onClick={handleMobileMenuOpen}
          >
            <MoreIcon className={classes.appIcon} />
          </IconButton>
        </div>
      </Toolbar>

      {renderMenu}
      {renderMobileMenu}
      {/* {renderNotificationMenu} */}

    </AppBar>
  )
}

export default AppHeader
