import React from 'react';

const AuthenticationLayout: React.FC = ({ children }) => {
	return (
  	<>
  		{ children }
  	</>
  );
}

export default AuthenticationLayout;
