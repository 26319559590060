import React, { useState, useEffect, useRef, useCallback } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { Box, Paper, Grid, TextField, MenuItem, Typography } from '@mui/material'
import { FormGroup, FormControlLabel, Checkbox } from '@mui/material'
import { useForm, Controller } from 'react-hook-form'
import { FormHeader, FormAlert } from 'components'
import ListIcon from '@mui/icons-material/List'
import * as yup from 'yup'
import { useStyles } from './styles'
import api from 'services/api'
import { IOrganizationDTO } from 'data/dtos/operation/i-organization-dto'
import { ComplementPlaceHolder, accountAgencyPlaceHolder, accountNumberPlaceHolder, addressPlaceHolder, cnpjMask, cnpjMaskPlaceholder, emailPlaceHolder, legalNamePlaceHolder, neighborhoodPlaceHolder, numberPlaceHolder, percentageMask, percentagePlaceHolder, phoneMask, phoneMaskPlaceholder, removeEmptyProperties, sanatizeMoneyValue, sanatizeStringValue, tradeNamePlaceHolder, zipCodeMask, zipCodeMaskPlaceHolder } from 'utils/utils';
import { createMask } from 'imask';
import { ProfileType } from 'hooks/enums/ProfileType';
import { useProfile } from 'hooks/use-profile';
import { invalidEmail, requiredField } from 'utils/error-messages';
import { IsEmailRegex } from 'utils/regex'
import { OrganizationType, showOrganizationType } from 'hooks/enums/OrganizationType'
import { getUsers } from 'services/users'

interface IRouteParams {
  organizationID: string
}

const OrganizationForm: React.FC = () => {
  const [mainError, setMainError] = useState('')

  const [statesB, setStatesB] = useState([])
  const [citiesC, setCitiesC] = useState([])
  const [accountBanks, setAccountBanks] = useState([])
  const [financialInstitutions, setFinancialInstitutions] = useState([])

  const params = useParams<IRouteParams>()
  const { organizationID } = params
  const isEditing = !!organizationID
  const firstInputElement = useRef(null)
  const classes = useStyles()
  const history = useHistory()
  const [selectedStateID, setSelectedStateID] = useState<string>()
  const { selectedProfile } = useProfile()
  const [responsibleUsers, setResponsibleUsers] = useState([])
  const isAdmin = selectedProfile === ProfileType.ADMIN

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    watch,
    formState: { errors },
    control
  } = useForm<IOrganizationDTO>({
    defaultValues: {
      cnpj: '',
      legalName: '',
      tradeName: '',
      address: '',
      number: '',
      neighborhood: '',
      complement: '',
      zipCode: '',
      stateID: null,
      cityID: null,
      email: '',
      phoneNumber: '',
      accountAgency: '',
      accountNumber: '',
      financialInstitutionID: null,
      type: OrganizationType.OFFICE,
      accountBankID: null,
      disabled: false,
    }
  })

  // initial load

  useEffect(() => {
    async function loadData() {
      // select UF

      await api
        .post('/states/select')
        .then(response => {
          const { data } = response.data

          return data
        })
        .then((statesResult) => {
          setStatesB(statesResult)
        })
        .catch(error => {
          return error
        })

        await api
        .get('/account-banks/')
        .then(response => {
          return response.data
        })
        .then((accountBanksResults) => {
          setAccountBanks(accountBanksResults)
        })
        .catch(error => {
          return error
        })

        await api
        .get('/financial-institutions/')
        .then(response => {
          return response.data
        })
        .then((financialInstitutionsResults) => {
          setFinancialInstitutions(financialInstitutionsResults)
        })
        .catch(error => {
          return error
        })
    }

    loadData()
  }, [])

  useEffect(() => {
    async function loadData() {
      // select Cidade
      await api
        .get(`/states/${selectedStateID}/cities`)
        .then(response => {
          return response.data
        })
        .then((citiesResult) => {
          setCitiesC(citiesResult)
        })
        .catch(error => {
          return error
        })
    }

    if(selectedStateID) {
      loadData()
    }

  }, [selectedStateID])

  useEffect(() => {
    async function loadResponsibleUsers() {
      const response = await getUsers({ profileIDs: [ProfileType.OFFICER] })
      setResponsibleUsers(response)
    }
    if(isAdmin) {
      loadResponsibleUsers()
    }
  }, [isAdmin])


  // main data load

  useEffect(() => {
    async function loadData() {
      const { organizationID } = params

      // form data

      await api
        .get(`/organizations/${organizationID}`)
        .then(response => {
          const data = response.data

          const organizationResult = {
            cnpj: data.cnpj,
            legalName: data.legalName,
            tradeName: data.tradeName,
            address: data.address,
            number: data.number,
            neighborhood: data.neighborhood,
            complement: data.complement,
            zipCode: data.zipCode,
            stateID: data.state.id,
            cityID: data.city?.id || null,
            email: data.email,
            phoneNumber: data.phoneNumber,
            accountAgency: data.accountAgency,
            accountNumber: data.accountNumber,
            accountBankID: data.accountBankID,
            financialInstitutionID: data.financialInstitutionID,
            type: data.type,
            commissionRate: data.commissionRate,
            disabled: data.disabled,
            officerUserID: data.officerUserID
          }

          setSelectedStateID(data.stateID)
          return organizationResult
        })
        .then((organizationResult: IOrganizationDTO) => {
          const { cnpj, phoneNumber, zipCode, commissionRate } = organizationResult

          organizationResult.cnpj = createMask({ mask: cnpjMask }).resolve(cnpj)
          organizationResult.phoneNumber = createMask({ mask: phoneMask }).resolve(phoneNumber)
          organizationResult.zipCode = createMask({ mask: zipCodeMask }).resolve(zipCode)
          organizationResult.commissionRate = createMask({ mask: percentageMask }).resolve(commissionRate)

          reset(organizationResult)
        })
        .catch(error => {
          return error
        })
    }

    if (params.organizationID) {
      loadData()
    }
  }, [params])


  // data save

  const onSubmit = useCallback(async (data: IOrganizationDTO) => {
    let payLoad: IOrganizationDTO = {
      cnpj: data.cnpj ? sanatizeStringValue(data.cnpj) : undefined,
      legalName: data.legalName,
      tradeName: data.tradeName,
      address: data.address,
      number: data.number,
      neighborhood: data.neighborhood,
      complement: data.complement,
      zipCode: data.zipCode ? sanatizeStringValue(data.zipCode) : undefined,
      stateID: data.stateID,
      cityID: data.cityID,
      email: data.email,
      phoneNumber: data.phoneNumber ? sanatizeStringValue(data.phoneNumber) : undefined,
      accountAgency: data.accountAgency,
      accountNumber: data.accountNumber,
      accountBankID: data.accountBankID,
      financialInstitutionID: data.financialInstitutionID,
      type: data.type,
    }

    if(isAdmin) {
      payLoad = {
        ...payLoad,
        disabled: data.disabled,
        commissionRate: data.commissionRate ? sanatizeMoneyValue(data.commissionRate) : undefined,
        officerUserID: data.officerUserID
      }
    }

    payLoad = removeEmptyProperties<IOrganizationDTO>(payLoad)

    if (isEditing) {
      const { organizationID } = params
      payLoad.id = organizationID

      await api
        .put(`/organizations`, payLoad)
        .then(history.push('/organizations'))
        .catch(error => {
          setMainError(error.response.data.data.name)
          return error.response.data.data
        })
    } else {
      await api
        .post('/organizations', payLoad)
        .then(history.push('/organizations/new'))
        .then(() => reset())
        .then(() => setTimeout(() => { firstInputElement.current.focus() }, 0))
        .catch(error => {
          setMainError(error.response.data.data.name)
          return error.response.data.data
        })
    }
  }, [])


  const handleChange = (formField: any) => {
    setMainError('')
  }

  return (
    <Paper elevation={0} className={classes.paper}>
      <Box
        component="form"
        onSubmit={handleSubmit(onSubmit)}
        noValidate
        data-testid="form"
      >
        <FormHeader
          title="Parceiros"
          icon={ListIcon}
          showSaveButton={true}
          showBackButton={isAdmin}
          backRoute="/organizations"
        />

        <FormAlert setMainError={setMainError} mainError={mainError} />

        <Paper elevation={1} className={classes.gridPaper}>
          <Typography variant="h6" display="block" gutterBottom color="textPrimary" >
            Dados Gerais
          </Typography>
          <Grid container spacing={1} className={classes.formContainer}>
            <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
              <Typography variant="caption" display="block" gutterBottom >
                CNPJ
              </Typography>
              <TextField
                id="cnpj"
                fullWidth={true}
                error={!!errors.cnpj}
                helperText={errors?.cnpj?.message}
                placeholder={cnpjMaskPlaceholder}
                variant="outlined"
                margin="dense"
                size="small"
                inputRef={firstInputElement}
                InputLabelProps={{
                  shrink: true
                }}
                {...register("cnpj", {
                    required: requiredField,
                    onChange: (e) => {
                    handleChange(e)
                  }}
                )}
                onChange={(event) => {
                  const { value } = event.target
                  event.target.value = createMask({ mask: cnpjMask }).resolve(value)
                }}
              />
            </Grid>

            <Grid item xs={12} sm={12} md={8} lg={4} xl={4}>
              <Typography variant="caption" display="block" gutterBottom >
                Razão Social
              </Typography>
              <TextField
                id="legalName"
                error={!!errors.legalName}
                helperText={errors?.legalName?.message}
                placeholder={legalNamePlaceHolder}
                variant="outlined"
                margin="dense"
                size="small"
                fullWidth={true}
                InputLabelProps={{
                  shrink: true
                }}
                inputProps={{
                  maxLength: 100
                }}
                {...register("legalName", {
                    required: requiredField,
                    onChange: (e) => handleChange(e)
                  }
                )}
              />
            </Grid>

            <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
              <Typography variant="caption" display="block" gutterBottom >
                Nome Fantasia
              </Typography>
              <TextField
                id="tradeName"
                error={!!errors.tradeName}
                helperText={errors?.tradeName?.message}
                placeholder={tradeNamePlaceHolder}
                variant="outlined"
                margin="dense"
                size="small"
                fullWidth={true}
                InputLabelProps={{
                  shrink: true
                }}
                inputProps={{
                  maxLength: 100
                }}
                {...register("tradeName", {
                    required: requiredField,
                    onChange: (e) => handleChange(e)
                  }
                )}
              />
            </Grid>

            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <Typography variant="caption" display="block" gutterBottom >
                Afiliação
              </Typography>
              <TextField
                id="financialInstitutionID"
                error={!!errors.financialInstitutionID}
                helperText={errors?.financialInstitutionID?.message}
                variant="outlined"
                margin="dense"
                size="small"
                fullWidth={true}
                value={`${watch().financialInstitutionID}`}
                select
                {...register("financialInstitutionID", {
                  required: requiredField,
                  onChange: (e) => {
                    setValue("financialInstitutionID", e.target.value)
                    handleChange(e)
                  }
                })}
              >
              {financialInstitutions.map((financialInstitution) => (
                <MenuItem
                  key={financialInstitution.id}
                  value={financialInstitution.id}
                >
                  {financialInstitution.name}
                </MenuItem>
              ))}
              </TextField>
            </Grid>

            <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
              <Typography variant="caption" display="block" gutterBottom >
                Tipo
              </Typography>
              <TextField
                id="type"
                error={!!errors.type}
                helperText={errors?.type?.message}
                variant="outlined"
                margin="dense"
                size="small"
                fullWidth={true}
                value={`${watch().type}`}
                select
                {...register("type", {
                  required: requiredField,
                  onChange: (e) => {
                    setValue("type", e.target.value)
                    handleChange(e)
                  }
                })}
              >
              {Object.entries(OrganizationType).map(([key, value]) => (
                <MenuItem
                  key={key}
                  value={value}
                >
                  {showOrganizationType(value)}
                </MenuItem>
              ))}
              </TextField>
            </Grid>
          </Grid>

          <Typography variant="h6" display="block" gutterBottom color="textPrimary" mt={2} >
            Informações de Contato
          </Typography>
          <Grid container spacing={1} className={classes.formContainer}>
            <Grid item xs={12} sm={12} md={5} lg={4} xl={4}>
              <Typography variant="caption" display="block" gutterBottom >
                Endereço
              </Typography>
              <TextField
                id="address"
                error={!!errors.address}
                helperText={errors?.address?.message}
                placeholder={addressPlaceHolder}
                variant="outlined"
                margin="dense"
                size="small"
                fullWidth={true}
                InputLabelProps={{
                  shrink: true
                }}
                inputProps={{
                  maxLength: 100
                }}
                {...register("address",
                  { onChange: (e) => handleChange(e) }
                )}
              />
            </Grid>

            <Grid item xs={12} sm={12} md={2} lg={1} xl={1}>
              <Typography variant="caption" display="block" gutterBottom >
                Número
              </Typography>
              <TextField
                id="number"
                error={!!errors.number}
                helperText={errors?.number?.message}
                placeholder={numberPlaceHolder}
                variant="outlined"
                margin="dense"
                size="small"
                fullWidth={true}
                InputLabelProps={{
                  shrink: true
                }}
                inputProps={{
                  maxLength: 5
                }}
                {...register("number",
                  { onChange: (e) => handleChange(e) }
                )}
              />
            </Grid>

            <Grid item xs={12} sm={12} md={5} lg={3} xl={3}>
              <Typography variant="caption" display="block" gutterBottom >
                Complemento
              </Typography>
              <TextField
                id="complement"
                error={!!errors.complement}
                helperText={errors?.complement?.message}
                placeholder={ComplementPlaceHolder}
                variant="outlined"
                margin="dense"
                size="small"
                fullWidth={true}
                InputLabelProps={{
                  shrink: true
                }}
                inputProps={{
                  maxLength: 100
                }}
                {...register("complement",
                  { onChange: (e) => handleChange(e) }
                )}
              />
            </Grid>

            <Grid item xs={12} sm={12} md={4} lg={2} xl={2}>
              <Typography variant="caption" display="block" gutterBottom >
                Bairro
              </Typography>
              <TextField
                id="neighborhood"
                error={!!errors.neighborhood}
                helperText={errors?.neighborhood?.message}
                placeholder={neighborhoodPlaceHolder}
                variant="outlined"
                margin="dense"
                size="small"
                fullWidth={true}
                InputLabelProps={{
                  shrink: true
                }}
                {...register("neighborhood",
                  { onChange: (e) => handleChange(e) }
                )}
              />
            </Grid>

            <Grid item xs={12} sm={12} md={4} lg={2} xl={2}>
              <Typography variant="caption" display="block" gutterBottom >
                CEP
              </Typography>
              <TextField
                id="zipCode"
                fullWidth={true}
                error={!!errors.zipCode}
                helperText={errors?.zipCode?.message}
                placeholder={zipCodeMaskPlaceHolder}
                variant="outlined"
                margin="dense"
                size="small"
                InputLabelProps={{
                  shrink: true,
                }}
                {...register("zipCode",
                  { onChange: (e) => handleChange(e) }
                )}
                onChange={(event) => {
                  const { value } = event.target
                  event.target.value = createMask({ mask: zipCodeMask }).resolve(value)
                }}
              />
            </Grid>

            <Grid item xs={12} sm={12} md={4} lg={2} xl={2}>
              <Typography variant="caption" display="block" gutterBottom >
                Estado
              </Typography>
              <TextField
                id="stateID"
                error={!!errors.stateID}
                helperText={errors?.stateID?.message}
                variant="outlined"
                margin="dense"
                size="small"
                fullWidth={true}
                value={`${watch().stateID}`}
                select
                {...register("stateID", { onChange: (e) => {
                  setValue("stateID", e.target.value)
                  setSelectedStateID(e.target.value)
                  handleChange(e)
                }})}
              >
              {statesB.map((state) => (
                <MenuItem
                  key={state.id}
                  value={state.id}
                >
                  {state.code}
                </MenuItem>
              ))}
              </TextField>
            </Grid>

            <Grid item xs={12} sm={12} md={4} lg={3} xl={4}>
              <Typography variant="caption" display="block" gutterBottom >
                Cidade
              </Typography>
              <TextField
                id="cityId"
                error={!!errors.cityID}
                helperText={errors?.cityID?.message}
                variant="outlined"
                margin="dense"
                size="small"
                fullWidth={true}
                value={`${watch().cityID}`}
                select
                {...register("cityID", { onChange: (e) => {
                  setValue("cityID", e.target.value)
                  handleChange(e)
                }})}
              >
              {citiesC.map((city) => (
                <MenuItem
                  key={city.id}
                  value={city.id}
                >
                  {city.name}
                </MenuItem>
              ))}
              </TextField>
            </Grid>

            <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
              <Typography variant="caption" display="block" gutterBottom >
                Email
              </Typography>
              <TextField
                id="email"
                error={!!errors.email}
                helperText={errors?.email?.message}
                placeholder={emailPlaceHolder}
                variant="outlined"
                margin="dense"
                size="small"
                fullWidth={true}
                InputLabelProps={{
                  shrink: true
                }}
                inputProps={{
                  maxLength: 100
                }}
                {...register("email", {
                  required: requiredField,
                  pattern: {
                    value: IsEmailRegex,
                    message: invalidEmail,
                  },
                  onChange: (e) => handleChange(e) }
                )}
              />
            </Grid>

            <Grid item xs={12} sm={12} md={4} lg={3} xl={2}>
              <Typography variant="caption" display="block" gutterBottom >
                Telefone
              </Typography>
              <TextField
                id="phoneNumber"
                error={!!errors.phoneNumber}
                helperText={errors?.phoneNumber?.message}
                variant="outlined"
                margin="dense"
                size="small"
                InputLabelProps={{
                  shrink: true
                }}
                placeholder={phoneMaskPlaceholder}
                {...register("phoneNumber",
                { onChange: (e) => handleChange(e) }
                )}
                onChange={(event) => {
                  const { value } = event.target
                  event.target.value = createMask({ mask: phoneMask }).resolve(value)
                }}
              />
            </Grid>
          </Grid>

          <Typography variant="h6" display="block" gutterBottom color="textPrimary" mt={2} >
            Dados Bancários
          </Typography>
          <Grid container spacing={1} className={classes.formContainer}>
            <Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
              <Typography variant="caption" display="block" gutterBottom >
                Agência
              </Typography>
              <TextField
                id="accountAgency"
                error={!!errors.accountAgency}
                helperText={errors?.accountAgency?.message}
                placeholder={accountAgencyPlaceHolder}
                variant="outlined"
                margin="dense"
                size="small"
                fullWidth={true}
                InputLabelProps={{
                  shrink: true
                }}
                {...register("accountAgency",
                  { onChange: (e) => handleChange(e) }
                )}
              />
            </Grid>

            <Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
              <Typography variant="caption" display="block" gutterBottom >
                Conta
              </Typography>
              <TextField
                id="accountNumber"
                error={!!errors.accountNumber}
                helperText={errors?.accountNumber?.message}
                placeholder={accountNumberPlaceHolder}
                variant="outlined"
                margin="dense"
                size="small"
                fullWidth={true}
                InputLabelProps={{
                  shrink: true
                }}
                {...register("accountNumber",
                  { onChange: (e) => handleChange(e) }
                )}
              />
            </Grid>

            <Grid item xs={12} sm={12} md={8} lg={8} xl={3}>
              <Typography variant="caption" display="block" gutterBottom >
                Banco
              </Typography>
              <TextField
                id="accountBankID"
                error={!!errors.accountBankID}
                helperText={errors?.accountBankID?.message}
                variant="outlined"
                margin="dense"
                size="small"
                fullWidth={true}
                value={`${watch().accountBankID}`}
                select
                {...register("accountBankID", { onChange: (e) => {
                  setValue("accountBankID", e.target.value)
                  handleChange(e)
                }})}
              >
              {accountBanks.map((accountBank) => (
                <MenuItem
                  key={accountBank.id}
                  value={accountBank.id}
                >
                  {accountBank.febrabanCode + ' - ' + accountBank.name}
                </MenuItem>
              ))}
              </TextField>
            </Grid>
          </Grid>

          {isAdmin &&
            <>
              <Typography variant="h6" display="block" gutterBottom color="textPrimary" mt={2} >
                Ações Administrativas
              </Typography>

              <Grid container spacing={1} className={classes.formContainer}>
                <Grid item xs={12} sm={12} md={4} lg={3} xl={2}>
                  <Typography variant="caption" display="block" gutterBottom >
                    Porcentagem Comissão
                  </Typography>
                  <TextField
                    id="commissionRate"
                    error={!!errors.commissionRate}
                    helperText={errors?.commissionRate?.message}
                    placeholder={percentagePlaceHolder}
                    variant="outlined"
                    margin="dense"
                    size="small"
                    fullWidth={true}
                    InputLabelProps={{
                      shrink: true
                    }}
                    {...register("commissionRate",
                      { onChange: (e) => handleChange(e) }
                    )}
                    onChange={(event) => {
                      const { value } = event.target
                      event.target.value = createMask({ mask: percentageMask }).resolve(value)
                    }}
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={4} lg={3} xl={2}>
                  <Typography variant="caption" display="block" gutterBottom>
                    Officer Responsável
                  </Typography>
                  <TextField
                    id="officerUserID"
                    error={!!errors.officerUserID}
                    helperText={errors?.officerUserID?.message}
                    variant="outlined"
                    margin="dense"
                    size="small"
                    fullWidth={true}
                    value={`${watch().officerUserID}`}
                    select
                    {...register("officerUserID", { onChange: (e) => {
                      setValue("officerUserID", e.target.value)
                      handleChange(e)
                    }})}
                  >
                  {responsibleUsers.map((user) => (
                    <MenuItem
                      key={user.id}
                      value={user.id}
                    >
                      {user.name}
                    </MenuItem>
                  ))}
                  </TextField>
                </Grid>

                <Grid item xs={12} sm={12} md={6} lg={6} xl={3}>
                  <Typography variant="caption" display="block" gutterBottom >
                    Status
                  </Typography>
                  <FormGroup className={classes.checkBox}>
                    <Controller
                      name="disabled"
                      control={control}
                      render={({ field }) => (
                        <FormControlLabel
                          control={
                            <Checkbox
                              onChange={(e) => field.onChange(e.target.checked)}
                              checked={field.value}
                            />
                          }
                          label={
                            <Typography variant="caption" display="block" gutterBottom >
                              Ativo
                            </Typography>
                          }
                        />
                      )}
                    />
                  </FormGroup>
                </Grid>
              </Grid>
            </>
          }
        </Paper>
      </Box>
    </Paper>
  )
}

export default OrganizationForm
