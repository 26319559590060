import React, { useState } from 'react'
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material'
import { useStyles } from '../login-footer/styles'

export enum DialogType {
  CONFIRMATION = 'CONFIRMATION',
  ACTION = 'ACTION'
}

type Props = {
  isOpen: boolean
  title: string
  bodyContent: React.ReactNode
  onConfirmModal?: Function
  onCloseModal: Function
  type: DialogType
  confirmModalValue?: string
  cancelModalValue?: string
}

const DefaultModal = ({ isOpen, title, bodyContent, onConfirmModal, onCloseModal, type, confirmModalValue, cancelModalValue }: Props) => {
	const classes = useStyles()

  const handleCloseModal = () => {
    onCloseModal();
  }

  const handleConfirmModal = () => {
    onConfirmModal()
    onCloseModal()
  }

  const getModalType = () => {
    switch(type) {
      case DialogType.ACTION:
        return (
          <DialogActions style={{ justifyContent: 'center', marginBottom: '30px' }} >
            <Button onClick={handleConfirmModal} variant="contained">{confirmModalValue || 'Confirmar'}</Button>
            <Button onClick={handleCloseModal} variant="outlined">{cancelModalValue || 'Cancelar'}</Button>
          </DialogActions>
        )
      case DialogType.CONFIRMATION:
        return (
          <DialogActions style={{ justifyContent: 'center', marginBottom: '30px' }} >
            <Button onClick={handleCloseModal} variant='contained'>OK</Button>
          </DialogActions>
        )
    }
  }

	return (
    <>
      <Dialog
        open={isOpen}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" style={{ textAlign: 'center', fontSize: '22px', margin: '20px' }}>{title}</DialogTitle>

        <DialogContent style={{ textAlign: 'center', minWidth: '500px' }}>
          <DialogContentText id="alert-dialog-description">
            {bodyContent}
          </DialogContentText>
        </DialogContent>

        {getModalType()}

      </Dialog>
    </>
	)
}

export default DefaultModal
