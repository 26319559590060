// App.tsx
import React, { useEffect, useState } from 'react';
import { Container, Typography, Card, CardContent, CardHeader, Box, Grid, Paper } from '@mui/material';
import { styled } from '@mui/system';
import { useHistory } from 'react-router-dom';
import { useStyles } from './styles';
import { ProposalPhase, ProposalStatus, showProposalPhase } from 'hooks/enums/ProposalStatus';
import { formatCurrency, formatDate } from 'utils/utils';
import { useAlreadyMounted } from 'utils/use-already-mounted';

export interface ProposalSummary {
  id: string
  scheduledPaymentDate: Date | null
  status: ProposalStatus
  proposalDetailPhase: ProposalPhase
  partnerBankName: string | null
  assessorName: string | null
  assessorEmail: string | null
  officerName: string | null
  officerEmail: string | null
  createdAt: Date
  financingValue: string
  realEstateAppraisalValue: string | null
}

type Props = {
  proposals: ProposalSummary[]
}

const ProposalSummaries = ({ proposals }: Props) => {
  const classes = useStyles()
  const history = useHistory()

  const handleOnCardClick = (id: string) => {
    history.push(`/proposals/edit/${id}`)
  }

  return (
    <Box display="flex" flexDirection="column" width="100%" className={classes.container}>
      {proposals.map((proposal, index) => (
        <div key={index} className={classes.cardContainer} onClick={() => handleOnCardClick(proposal.id)}>
          <Card >
            <CardHeader
              title={`Proposta ${proposal.partnerBankName || ' em análise'}`}
              subheader={`Status: ${showProposalPhase(proposal.proposalDetailPhase)}`}
              className={classes.cardHeader}
            />
            <CardContent className={classes.cardContent}>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Typography variant="body1" gutterBottom><strong>Valor da Proposta:</strong> {formatCurrency(proposal.financingValue)}</Typography>
                  <Typography variant="body1" gutterBottom><strong>Nome do Assessor:</strong> {`${proposal.assessorName || 'N/A'}`}</Typography>
                  <Typography variant="body1" gutterBottom><strong>Email do Assessor:</strong> {`${proposal.assessorEmail || 'N/A'}`}</Typography>
                  <Typography variant="body1" gutterBottom><strong>Data da Proposta:</strong> {formatDate(proposal.createdAt)}</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body1" gutterBottom><strong>Valor da Avaliação:</strong> {formatCurrency(proposal.realEstateAppraisalValue)}</Typography>
                  <Typography variant="body1" gutterBottom><strong>Nome do Officer:</strong> {`${proposal.officerName || 'N/A'}`}</Typography>
                  <Typography variant="body1" gutterBottom><strong>Email do Officer:</strong> {`${proposal.officerEmail || 'N/A'}`}</Typography>
                  <Typography variant="body1" gutterBottom><strong>Data do Desembolso:</strong> {formatDate(proposal.scheduledPaymentDate)}</Typography>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </div>
      ))}
    </Box>
  );
}

export default ProposalSummaries;
