import React, { useState, useEffect, useRef, useCallback } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { Box, Paper, Grid, TextField, MenuItem, Typography, Tabs, Tab, Button } from '@mui/material'
import { useForm, Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import NumericInput from 'material-ui-numeric-input';
import { FormHeader, FormAlert } from 'components'
import ListIcon from '@mui/icons-material/List'
import * as yup from 'yup'
import { useStyles } from './styles'
import api from 'services/api'
import { ISimulationDTO } from 'data/dtos/operation/i-simulation-dto'
import { ContactlessOutlined, NoBackpackSharp } from '@mui/icons-material'

interface ITabPanelProps {
  children?: React.ReactNode
  index: number
  value: number
}

interface IResult {
  sequence: number
  interest: number
  dfiInsurance: number
  mpiInsurance: number
  fees: number
  instalment: number
  amortization: number
  debitBalance: number
}

const calculateAgeInMonths = (birthDay: string) => {
  const endDate = new Date()
  const startDate = new Date(birthDay)
  const inMonths = endDate.getMonth() - startDate.getMonth() + 12 * (endDate.getFullYear() - startDate.getFullYear())

  return inMonths
}

const calculateAge = (birthDay: string) => {
  const targetDate = new Date(birthDay)
  const ageDifMs = Date.now() - +targetDate
  const ageDate = new Date(ageDifMs)

  return Math.abs(ageDate.getUTCFullYear() - 1970)
}

const pmt = (ir: number, np: number, pv: number, fv: number, type: number) => {
  const presentValueInterestFactor = Math.pow((1 + ir), np)
  const pmt = ir * pv  * (presentValueInterestFactor + fv) / (presentValueInterestFactor - 1)

  return pmt
}


function TabPanel(props: ITabPanelProps) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box style={{padding: '10px 0px 10px 0px'}}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  )
}

function ResultsTabPanel(props: ITabPanelProps) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box style={{padding: '10px 0px 10px 0px'}}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  )
}

function tabsProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  }
}

interface IRouteParams {
  id: string
}

interface State {
  numberFormat: string;
}

const SimulationForm: React.FC = () => {
  const [mainError, setMainError] = useState('')
  const [banksA, setBanksA] = useState([])
  const [instalments, setInstalments] = useState([])
  const [currentTab, setCurrentTab] = useState(0)
  const [currentResultTab, setCurrentResultTab] = useState(0)
  const [showResults, setShowResults] = useState(false)
  const [priceResults, setPriceResults] = useState<IResult[]>([])
  const [sacResults, setSacResults] = useState<IResult[]>([])
  const [values, setValues] = useState<State>({ numberFormat: '1320' });

  const params = useParams<IRouteParams>()
  const firstInputElement = useRef(null)
  const classes = useStyles()
  const history = useHistory()

  const validationSchema = yup.object().shape({
    simulationDate: yup.string()
      .required('Campo obrigatório'),
    name: yup.string()
      .required('Campo obrigatório'),
    email: yup.string()
      .required('Campo obrigatório'),
    birthDate: yup.string()
      .required('Campo obrigatório'),
    propertyPrice: yup.string()
      .required('Campo obrigatório'),
    financedAmount: yup.string()
      .required('Campo obrigatório'),
  })

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    watch,
    formState: { errors },
    getValues,
    setError,
    clearErrors,
    control
  } = useForm<ISimulationDTO>({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      simulationDate: null,
      name: 'André Dourado',
      email: 'andreldcastro@gmail.com',
      birthDate: '1964-08-03',
      age: '',
      propertyPrice: 1000000,
      financedAmount: 700000,
      financedAmountPercentage: 0.00,
      instalments: '',
      includeItbi: false,
      bankId: null,
      totalEffectiveCostRateYearPrice: 12.63,
      totalEffectiveCostRateMonthPrice: 0.9961,
      totalEffectiveCostRateYearSac: 12.49,
      totalEffectiveCostRateMonthSac: 0.9856,
      nominalInterestRateYear: 9.48,
      nominalInterestRateMonth: 0.7576,
      effectiveInterestRateYear: 9.9,
      effectiveInterestRateMonth: 0.7898,
      maximumAgeInMonths: 0,
      dfiPropertyPhysicalDamageInsurance: 0.0055,
      ceshEffectiveCostOfInsurance: 18.5503,
      mipDeathAndPermanentDisabilityInsurance: 0.1131,
      tacContractAdministrationFee: 25,
      propertyValuationFee: 3400,
      iofTaxOnFinancialOperations: 0,
      itbi: 0,
      minimumIncome: 0,
      firstInstalment: 0,
    }
  })

  // initial load

  useEffect(() => {
    async function loadData() {

      // select Banco

      await api
        .post('/banks/select')
        .then(response => {
          const { data } = response.data

          return data
        })
        .then((banksResult) => {
          setBanksA(banksResult)
        })
        .catch(error => {
          return error
        })
    }

    loadData()
  }, [])


  // main data load

  useEffect(() => {
    async function loadData() {
      const { id } = params

      // form data

      await api
        .get(`/simulations/${id}`)
        .then(response => {
          const { data } = response.data

          const simulationResult = {
            simulationDate: data.simulationDate,
            name: data.name,
            email: data.email,
            birthDate: data.birthDate,
            age: data.age,
            propertyPrice: data.propertyPrice,
            financedAmount: data.financedAmount,
            financedAmountPercentage: data.financedAmountPercentage,
            instalments: data.instalments,
            includeItbi: data.includeItbi,
            bankId: data.bankId.id,
            totalEffectiveCostRateYearPrice: data.totalEffectiveCostRateYearPrice,
            totalEffectiveCostRateMonthPrice: data.totalEffectiveCostRateMonthPrice,
            totalEffectiveCostRateYearSac: data.totalEffectiveCostRateYearSac,
            totalEffectiveCostRateMonthSac: data.totalEffectiveCostRateMonthSac,
            nominalInterestRateYear: data.nominalInterestRateYear,
            nominalInterestRateMonth: data.nominalInterestRateMonth,
            effectiveInterestRateYear: data.effectiveInterestRateYear,
            effectiveInterestRateMonth: data.effectiveInterestRateMonth,
            maximumAgeInMonths: data.maximumAgeInMonths,
            dfiPropertyPhysicalDamageInsurance: data.dfiPropertyPhysicalDamageInsurance,
            ceshEffectiveCostOfInsurance: data.ceshEffectiveCostOfInsurance,
            mipDeathAndPermanentDisabilityInsurance: data.mipDeathAndPermanentDisabilityInsurance,
            tacContractAdministrationFee: data.tacContractAdministrationFee,
            propertyValuationFee: data.propertyValuationFee,
            iofTaxOnFinancialOperations: data.iofTaxOnFinancialOperations,
            itbi: data.itbi,
            minimumIncome: data.minimumIncome,
            firstInstalment: data.firstInstalment,
            sendDate: data.sendDate,
            clickDate: data.clickDate,
          }

          return simulationResult
        })
        .then((simulationResult: ISimulationDTO) => {
          reset(simulationResult)
        })
        .catch(error => {
          return error
        })
    }

    if (params.id) {
      loadData()
    }
  }, [params, params.id])


  // data save

  const onSubmit = useCallback(async (data: ISimulationDTO) => {
    const payLoad: ISimulationDTO = {
      simulationDate: data.simulationDate,
      name: data.name,
      email: data.email,
      birthDate: data.birthDate,
      age: data.age,
      propertyPrice: data.propertyPrice,
      financedAmount: data.financedAmount,
      financedAmountPercentage: data.financedAmountPercentage,
      instalments: data.instalments,
      includeItbi: data.includeItbi,
      bankId: data.bankId,
      totalEffectiveCostRateYearPrice: data.totalEffectiveCostRateYearPrice,
      totalEffectiveCostRateMonthPrice: data.totalEffectiveCostRateMonthPrice,
      totalEffectiveCostRateYearSac: data.totalEffectiveCostRateYearSac,
      totalEffectiveCostRateMonthSac: data.totalEffectiveCostRateMonthSac,
      nominalInterestRateYear: data.nominalInterestRateYear,
      nominalInterestRateMonth: data.nominalInterestRateMonth,
      effectiveInterestRateYear: data.effectiveInterestRateYear,
      effectiveInterestRateMonth: data.effectiveInterestRateMonth,
      maximumAgeInMonths: data.maximumAgeInMonths,
      dfiPropertyPhysicalDamageInsurance: data.dfiPropertyPhysicalDamageInsurance,
      ceshEffectiveCostOfInsurance: data.ceshEffectiveCostOfInsurance,
      mipDeathAndPermanentDisabilityInsurance: data.mipDeathAndPermanentDisabilityInsurance,
      tacContractAdministrationFee: data.tacContractAdministrationFee,
      propertyValuationFee: data.propertyValuationFee,
      iofTaxOnFinancialOperations: data.iofTaxOnFinancialOperations,
      itbi: data.itbi,
      minimumIncome: data.minimumIncome,
      firstInstalment: data.firstInstalment,
      sendDate: data.sendDate,
      clickDate: data.clickDate,
    }

    if (params.id) {
      const { id } = params

      //getValues(['id']) = id

      await api
        .put(`/simulations`, payLoad)
        .then(history.push('/simulations'))
        .catch(error => {
          setMainError(error.response.data.data.name)
          return error.response.data.data
        })
    } else {
      await api
        .post('/simulations', payLoad)
        .then(history.push('/simulations/new'))
        .then(() => reset())
        .then(() => setTimeout(() => { firstInputElement.current.focus() }, 0))
        .catch(error => {
          setMainError(error.response.data.data.name)
          return error.response.data.data
        })
    }
  }, [])


  const handleChange = (formField: any) => {
    setTimeout(() => {

      console.log('=====>', getValues(['propertyPrice']), getValues(['financedAmount']))

      switch(formField.target.name) {
        case 'birthDate':
          clearErrors('birthDate')

          const age = calculateAge(formField.target.value)

          if ((age >= 18) && (age <= 70)) {
            if (formField.target.value.length == 10) {
              setValue('age', age.toString())

              if (age > 70) {
                setError('birthDate', { type: 'custom', message: 'Idade máxima é 70 anos' })
              } else {
                clearErrors('birthDate')
              }
            }

            //let maxInstalments = (960 - calculateAgeInMonths(formField.target.value)) + 6
            let maxInstalments = ((80 - age) * 12) - 5

            if (maxInstalments > 420) {
              maxInstalments = 420
            }

            let instalments = []
            for(let i = maxInstalments; i > 0; --i) {
              instalments.push(i)
            }

            setInstalments(instalments)
            setValue('instalments', maxInstalments.toString())
          } else {
            setError('birthDate', { type: 'custom', message: 'Idade entre 18 e 70 anos' })
          }
          break

        case 'propertyPrice':
          const propertyPrice1 = getValues(['propertyPrice'])
          const financedAmount1 = getValues(['financedAmount'])
          const percent1 = (financedAmount1[0] / propertyPrice1[0]) * 100

          console.log('---->', percent1)

          if (percent1 > 80) {
            setError('birthDate', { type: 'custom', message: 'Idade entre 18 e 70 anos' })
          }

          setValue('financedAmountPercentage', Math.round(percent1 * 100) / 100)
          break

        case 'financedAmount':
          const propertyPrice2 = getValues(['propertyPrice'])
          const financedAmount2 = getValues(['financedAmount'])
          const percent2 = (financedAmount2[0] / propertyPrice2[0]) * 100

          console.log('---->', percent2)

          setValue('financedAmountPercentage', Math.round(percent2 * 100) / 100)
          break
      }
    }, 1000)

    setMainError('')
  }

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setCurrentTab(newValue)
  }

  const handleTabResultChange = (event: React.SyntheticEvent, newValue: number) => {
    setCurrentResultTab(newValue)
  }

  const processSimulation = () => {
    let tempoPriceResult: IResult[] = []
    let tempoSacResult: IResult[] = []

    const instalments = parseInt(getValues('instalments'))

    for (let i = 0; i < instalments; i++) {

      const dfiInsurance = (getValues('dfiPropertyPhysicalDamageInsurance') / 100.0) * getValues('propertyPrice')
      const fees = i == 0 ? getValues('propertyValuationFee') + getValues('iofTaxOnFinancialOperations') : 25

      const priceInterest = i == 0 ? 0 : (getValues('effectiveInterestRateMonth') / 100.0) * tempoPriceResult[i - 1].debitBalance
      const priceMpiInsurance = i == 0 ? (getValues('mipDeathAndPermanentDisabilityInsurance') / 100.0) * getValues('financedAmount') : (getValues('mipDeathAndPermanentDisabilityInsurance') / 100.0) * tempoPriceResult[i - 1].debitBalance
      const fisrtInstalment = dfiInsurance + priceMpiInsurance + fees
      const priceInstalment = i == 0 ? fisrtInstalment : pmt((getValues('effectiveInterestRateMonth') / 100.0), parseInt(getValues('instalments')) - (i - 1), tempoPriceResult[i - 1].debitBalance, 0, 0) + dfiInsurance + priceMpiInsurance + fees
      const priceAmortization = i == 0 ? 0 : priceInstalment - fees - priceMpiInsurance - dfiInsurance - priceInterest
      const priceDebitBalance = i == 0 ? getValues('financedAmount') * 1.0 : tempoPriceResult[i - 1].debitBalance - priceAmortization

      const sacInterest = i == 0 ? 0 : (getValues('effectiveInterestRateMonth') / 100.0) * tempoSacResult[i - 1].debitBalance
      const sacAmortization = i == 0 ? 0 : getValues('financedAmount') / parseInt(getValues('instalments'))
      const sacDebitBalance = i == 0 ? getValues('financedAmount') * 1.0 : tempoSacResult[i - 1].debitBalance - sacAmortization
      const sacMpiInsurance = (getValues('mipDeathAndPermanentDisabilityInsurance') / 100.0) * sacDebitBalance
      const sacInstalment = i == 0 ? dfiInsurance + sacMpiInsurance + fees : sacInterest + dfiInsurance + sacMpiInsurance + fees + sacAmortization

      if (i > 0) {
        console.log('======>', getValues('effectiveInterestRateMonth'),  tempoSacResult[i - 1].debitBalance)
        console.log('------', sacInterest)
      }

      const priceTempo: IResult ={
        sequence: i,
        interest: priceInterest,
        dfiInsurance: dfiInsurance,
        mpiInsurance: priceMpiInsurance,
        fees: fees,
        instalment: priceInstalment,
        amortization: priceAmortization,
        debitBalance: priceDebitBalance
      }

      const sacTempo: IResult ={
        sequence: i,
        interest: sacInterest,
        dfiInsurance: dfiInsurance,
        mpiInsurance: sacMpiInsurance,
        fees: fees,
        instalment: sacInstalment,
        amortization: sacAmortization,
        debitBalance: sacDebitBalance
      }

      tempoPriceResult.push(priceTempo)
      tempoSacResult.push(sacTempo)
    }

    setPriceResults(tempoPriceResult)
    setSacResults(tempoSacResult)
    setShowResults(true)
  }

  const renderResultTableHeader = () => {
    return (
      <Grid container spacing={0}>
        <Grid item xs={12} sm={12} md={6} lg={1} xl={1}>
          <Box className={classes.gridHeader}>
            <Typography align="left" fontSize={14}>
              Parcela
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={1} xl={1}>
          <Box className={classes.gridHeader}>
            <Typography align="right" fontSize={14}>
              Juros
            </Typography>
          </Box>
        </Grid><Grid item xs={12} sm={12} md={6} lg={1} xl={1}>
          <Box className={classes.gridHeader}>
            <Typography align="right" fontSize={14}>
              DFI
            </Typography>
          </Box>
        </Grid><Grid item xs={12} sm={12} md={6} lg={1} xl={1}>
          <Box className={classes.gridHeader}>
            <Typography align="right" fontSize={14}>
              MPI
            </Typography>
          </Box>
        </Grid><Grid item xs={12} sm={12} md={6} lg={2} xl={2}>
          <Box className={classes.gridHeader}>
            <Typography align="right" fontSize={14}>
              Tarifas
            </Typography>
          </Box>
        </Grid><Grid item xs={12} sm={12} md={6} lg={2} xl={2}>
          <Box className={classes.gridHeader}>
            <Typography align="right" fontSize={14}>
              Valor Parcela
            </Typography>
          </Box>
        </Grid><Grid item xs={12} sm={12} md={6} lg={2} xl={2}>
          <Box className={classes.gridHeader}>
            <Typography align="right" fontSize={14}>
              Amortização
            </Typography>
          </Box>
        </Grid><Grid item xs={12} sm={12} md={6} lg={2} xl={2}>
          <Box className={classes.gridHeader}>
            <Typography align="right" fontSize={14}>
              Saldo Devedor
            </Typography>
          </Box>
        </Grid>
      </Grid>
    )
  }

	const renderResultTable = (results: IResult[]) => {
    const reaisBRLocale = Intl.NumberFormat('pt-BR', {minimumFractionDigits: 2, maximumFractionDigits: 2})

		return (
      <Grid container spacing={0}>
        {results.map((item: IResult) => (
          <Grid container spacing={0}>
            <Grid item xs={12} sm={12} md={6} lg={1} xl={1}>
              <Box className={classes.gridBody}>
                <Typography align="left" fontSize={14}>
                  {item.sequence}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={1} xl={1}>
              <Box className={classes.gridBody}>
                <Typography align="right" fontSize={14}>
                  {reaisBRLocale.format(item.interest)}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={1} xl={1}>
              <Box className={classes.gridBody}>
                <Typography align="right" fontSize={14}>
                  {reaisBRLocale.format(item.dfiInsurance)}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={1} xl={1}>
              <Box className={classes.gridBody}>
                <Typography align="right" fontSize={14}>
                  {reaisBRLocale.format(item.mpiInsurance)}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={2} xl={2}>
              <Box className={classes.gridBody}>
                <Typography align="right" fontSize={14}>
                  {reaisBRLocale.format(item.fees)}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={2} xl={2}>
              <Box className={classes.gridBody}>
                <Typography align="right" fontSize={14}>
                  {reaisBRLocale.format(item.instalment)}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={2} xl={2}>
              <Box className={classes.gridBody}>
                <Typography align="right" fontSize={14}>
                  {reaisBRLocale.format(item.amortization)}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={2} xl={2}>
              <Box className={classes.gridBody}>
                <Typography align="right" fontSize={14}>
                  {reaisBRLocale.format(item.debitBalance)}
                </Typography>
              </Box>
            </Grid>
          </Grid>
        ))}
      </Grid>
    )
	}

  return (
    <Paper elevation={0} className={classes.paper}>
      <Box
        component="form"
        onSubmit={handleSubmit(onSubmit)}
        noValidate
        data-testid="form"
      >
        <FormHeader
          title="Simulações"
          icon={ListIcon}
          backRoute="/simulations"
          showBackButton={true}
          showSaveButton={true}
        />

        <FormAlert setMainError={setMainError} mainError={mainError} />

        <Paper elevation={1} className={classes.gridPaper}>

        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs value={currentTab} onChange={handleTabChange} aria-label="basic tabs example">
            <Tab label="Simulação" {...tabsProps(0)} />
            <Tab label="Parâmetros" {...tabsProps(1)} />
          </Tabs>
        </Box>

        <TabPanel value={currentTab} index={0}>
          <Grid container spacing={1} className={classes.formContainer}>

            {/* --- line 1 --- */}

            <Grid item xs={12} sm={12} md={6} lg={5} xl={5}>
              <Box className={classes.gridBoxFirst}>
                <Typography variant="caption" display="block" gutterBottom >
                  Nome
                </Typography>
                <TextField
                  id="name"
                  error={!!errors.name}
                  helperText={errors?.name?.message}
                  variant="outlined"
                  margin="dense"
                  size="small"
                  fullWidth={true}
                  InputLabelProps={{
                    shrink: true
                  }}
                  inputProps={{
                    maxLength: 60
                  }}
                  {...register("name",
                    { onChange: (e) => handleChange(e) }
                  )}
                />
              </Box>
            </Grid>

            <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
              <Box className={classes.gridBox}>
                <Typography variant="caption" display="block" gutterBottom >
                  Email
                </Typography>
                <TextField
                  id="email"
                  error={!!errors.email}
                  helperText={errors?.email?.message}
                  variant="outlined"
                  margin="dense"
                  size="small"
                  fullWidth={true}
                  InputLabelProps={{
                    shrink: true
                  }}
                  inputProps={{
                    maxLength: 60
                  }}
                  {...register("email",
                    { onChange: (e) => handleChange(e) }
                  )}
                />
              </Box>
            </Grid>

            <Grid item xs={12} sm={12} md={6} lg={2} xl={2}>
              <Box className={classes.gridBox}>
                <Typography variant="caption" display="block" gutterBottom >
                  Data de Nascimento
                </Typography>
                <TextField
                  id="birthDate"
                  error={!!errors.birthDate}
                  helperText={errors?.birthDate?.message}
                  variant="outlined"
                  margin="dense"
                  size="small"
                  fullWidth={true}
                  type="date"
                  //defaultValue={getValues('birthDate').toISOString().substring(0,10)}
                  InputLabelProps={{
                    shrink: true
                  }}
                  {...register("birthDate", { onChange: (e) => {
                    setValue("birthDate", e.target.value)
                    handleChange(e)
                  }})}
                />
              </Box>
            </Grid>

            <Grid item xs={12} sm={6} md={1} lg={1} xl={1}>
              <Box className={classes.gridBox}>
                <Typography variant="caption" display="block" gutterBottom >
                  Idade
                </Typography>
                <TextField
                  id="age"
                  error={!!errors.age}
                  helperText={errors?.age?.message}
                  variant="outlined"
                  margin="dense"
                  size="small"
                  fullWidth={true}
                  type="number"
                  InputLabelProps={{
                    shrink: true
                  }}
                  disabled
                  {...register("age", { onChange: (e) => {}})}
                />
              </Box>
            </Grid>

            {/* --- line 2 --- */}

            <Grid item xs={12} sm={12} md={6} lg={2} xl={2}>
              <Box className={classes.gridBoxFirst}>
                <Typography variant="caption" display="block" gutterBottom >
                  Valor do Imóvel
                </Typography>
                <Controller
                  render={({ field }) => (
                    <NumericInput
                      precision={2}
                      decimalChar=','
                      thousandChar='.'
                      variant='outlined'
                      value={getValues("propertyPrice")}
                      {...register("propertyPrice", { onChange: (e) => {
                        setValue("propertyPrice", e.target.value)
                        handleChange(e)
                      }})}
                    />
                  )}
                  name="propertyPrice"
                  control={control}
                />
              </Box>
            </Grid>

            <Grid item xs={12} sm={12} md={6} lg={2} xl={2}>
              <Box className={classes.gridBox}>
                <Typography variant="caption" display="block" gutterBottom >
                  Valor do Financiamento
                </Typography>
                <Controller
                  render={({ field }) => (
                    <NumericInput
                      precision={2}
                      decimalChar=','
                      thousandChar='.'
                      variant='outlined'
                      value={getValues("financedAmount")}
                      {...register("financedAmount", { onChange: (e) => {
                        setValue("financedAmount", e.target.value)
                        handleChange(e)
                      }})}
                    />
                  )}
                  name="financedAmount"
                  control={control}
                />
              </Box>
            </Grid>

            <Grid item xs={12} sm={12} md={6} lg={2} xl={2}>
              <Box className={classes.gridBox}>
                <Typography variant="caption" display="block" gutterBottom >
                  % Financiado
                </Typography>
                <TextField
                  id="financedAmountPercentage"
                  error={!!errors.financedAmountPercentage}
                  helperText={errors?.financedAmountPercentage?.message}
                  variant="outlined"
                  margin="dense"
                  size="small"
                  fullWidth={true}
                  InputLabelProps={{
                    shrink: true
                  }}
                  {...register("financedAmountPercentage",
                    { onChange: (e) => handleChange(e) }
                  )}
                  disabled
                />
              </Box>
            </Grid>

            <Grid item xs={12} sm={6} md={1} lg={2} xl={2}>
              <Box className={classes.gridBox}>
                <Typography variant="caption" display="block" gutterBottom >
                  Meses
                </Typography>
                <TextField
                  id="instalments"
                  error={!!errors.instalments}
                  helperText={errors?.instalments?.message}
                  variant="outlined"
                  margin="dense"
                  size="small"
                  fullWidth={true}
                  value={`${watch().instalments}`}
                  select
                  {...register("instalments", { onChange: (e) => {
                    setValue("instalments", e.target.value)
                    handleChange(e)
                  }})}
                >
                {instalments.map((instalment) => (
                  <MenuItem
                    key={instalment}
                    value={instalment}
                  >
                    {instalment}
                  </MenuItem>
                ))}
                </TextField>
              </Box>
            </Grid>

            <Grid item xs={12} sm={6} md={1} lg={2} xl={2}>
              <Box className={classes.gridBox}>
                <Typography variant="caption" display="block" gutterBottom >
                  ITBI
                </Typography>
                <TextField
                  id="itbi"
                  error={!!errors.itbi}
                  helperText={errors?.itbi?.message}
                  variant="outlined"
                  margin="dense"
                  size="small"
                  fullWidth={true}
                  value={`${watch().itbi}`}
                  select
                  {...register("itbi", { onChange: (e) => {
                    setValue("itbi", e.target.value)
                    handleChange(e)
                  }})}
                >
                  <MenuItem key={0} value={0}>Não</MenuItem>
                  <MenuItem key={1} value={1}>Sim</MenuItem>
                </TextField>
              </Box>
            </Grid>

            <Grid item xs={12} sm={12} md={1} lg={2} xl={2}>
                <Button
                  type="button"
                  variant="contained"
                  color="primary"
                  style={{marginTop: '20px', marginBottom: '8px'}}
                  fullWidth
                  onClick={() => { processSimulation() }}
                >Simular</Button>
            </Grid>
          </Grid>

          {/* --- result tabs --- */}

          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs value={currentResultTab} onChange={handleTabResultChange} aria-label="basic tabs example">
              <Tab label="Price" {...tabsProps(0)} />
              <Tab label="SAC" {...tabsProps(1)} />
            </Tabs>
          </Box>
          <ResultsTabPanel value={currentResultTab} index={0}>
            <Box className={classes.formResultsContainer}>
              <Grid container spacing={0}>
                <Grid item xs={12}>
                  { renderResultTableHeader() }
                </Grid>
                <Grid item xs={12}>
                  <Box className={classes.resultBox}>
                    {showResults ? renderResultTable(priceResults) : ''}
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </ResultsTabPanel>
          <ResultsTabPanel value={currentResultTab} index={1}>
            <Box className={classes.formResultsContainer}>
              <Grid container spacing={0} className={classes.formResultsContainer}>
                <Grid item xs={12}>
                  { renderResultTableHeader() }
                </Grid>
                <Grid item xs={12}>
                  <Box className={classes.resultBox}>
                    {showResults ? renderResultTable(sacResults) : ''}
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </ResultsTabPanel>
        </TabPanel>

        <TabPanel value={currentTab} index={1}>
          <Grid container spacing={1} className={classes.formContainer}>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <Box className={classes.gridBoxFirst}>
                <Typography variant="caption" display="block" gutterBottom >
                  Taxa Custo Efetivo (a.a.)
                </Typography>
                <Controller
                  render={({ field }) => (
                    <NumericInput
                      precision={4}
                      decimalChar=','
                      thousandChar='.'
                      variant='outlined'
                      value={getValues("totalEffectiveCostRateYearPrice")}
                      {...register("totalEffectiveCostRateYearPrice", { onChange: (e) => {
                        setValue("totalEffectiveCostRateYearPrice", e.target.value)
                        handleChange(e)
                      }})}
                    />
                  )}
                  name="totalEffectiveCostRateYearPrice"
                  control={control}
                />
              </Box>
            </Grid>

            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <Box className={classes.gridBox}>
                <Typography variant="caption" display="block" gutterBottom >
                  Taxa Custo Efetivo (a.m.)
                </Typography>
                <Controller
                  render={({ field }) => (
                    <NumericInput
                      precision={4}
                      decimalChar=','
                      thousandChar='.'
                      variant='outlined'
                      value={getValues("totalEffectiveCostRateMonthPrice")}
                      {...register("totalEffectiveCostRateMonthPrice", { onChange: (e) => {
                        setValue("totalEffectiveCostRateMonthPrice", e.target.value)
                        handleChange(e)
                      }})}
                    />
                  )}
                  name="totalEffectiveCostRateMonthPrice"
                  control={control}
                />
              </Box>
            </Grid>

            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <Box className={classes.gridBoxFirst}>
                <Typography variant="caption" display="block" gutterBottom >
                  Taxa Custo Efetivo (a.a.)
                </Typography>
                <Controller
                  render={({ field }) => (
                    <NumericInput
                      precision={4}
                      decimalChar=','
                      thousandChar='.'
                      variant='outlined'
                      value={getValues("totalEffectiveCostRateYearSac")}
                      {...register("totalEffectiveCostRateYearSac", { onChange: (e) => {
                        setValue("totalEffectiveCostRateYearSac", e.target.value)
                        handleChange(e)
                      }})}
                    />
                  )}
                  name="totalEffectiveCostRateYearSac"
                  control={control}
                />
              </Box>
            </Grid>

            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <Box className={classes.gridBox}>
                <Typography variant="caption" display="block" gutterBottom >
                  Taxa Custo Efetivo (a.m.)
                </Typography>
                <Controller
                  render={({ field }) => (
                    <NumericInput
                      precision={4}
                      decimalChar=','
                      thousandChar='.'
                      variant='outlined'
                      value={getValues("totalEffectiveCostRateMonthSac")}
                      {...register("totalEffectiveCostRateMonthSac", { onChange: (e) => {
                        setValue("totalEffectiveCostRateMonthSac", e.target.value)
                        handleChange(e)
                      }})}
                    />
                  )}
                  name="totalEffectiveCostRateMonthSac"
                  control={control}
                />
              </Box>
            </Grid>

            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <Box className={classes.gridBoxFirst}>
                <Typography variant="caption" display="block" gutterBottom >
                  Taxa Juros Nominal (a.a.)
                </Typography>
                <Controller
                  render={({ field }) => (
                    <NumericInput
                      precision={4}
                      decimalChar=','
                      thousandChar='.'
                      variant='outlined'
                      value={getValues("nominalInterestRateYear")}
                      {...register("nominalInterestRateYear", { onChange: (e) => {
                        setValue("nominalInterestRateYear", e.target.value)
                        handleChange(e)
                      }})}
                    />
                  )}
                  name="nominalInterestRateYear"
                  control={control}
                />
              </Box>
            </Grid>

            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <Box className={classes.gridBox}>
                <Typography variant="caption" display="block" gutterBottom >
                  Taxa Juros Nominal (a.m.)
                </Typography>
                <Controller
                  render={({ field }) => (
                    <NumericInput
                      precision={4}
                      decimalChar=','
                      thousandChar='.'
                      variant='outlined'
                      value={getValues("nominalInterestRateMonth")}
                      {...register("nominalInterestRateMonth", { onChange: (e) => {
                        setValue("nominalInterestRateMonth", e.target.value)
                        handleChange(e)
                      }})}
                    />
                  )}
                  name="nominalInterestRateMonth"
                  control={control}
                />
              </Box>
            </Grid>

            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <Box className={classes.gridBoxFirst}>
                <Typography variant="caption" display="block" gutterBottom >
                  Taxa Juros Efetivo (a.a.)
                </Typography>
                <Controller
                  render={({ field }) => (
                    <NumericInput
                      precision={4}
                      decimalChar=','
                      thousandChar='.'
                      variant='outlined'
                      value={getValues("effectiveInterestRateYear")}
                      {...register("effectiveInterestRateYear", { onChange: (e) => {
                        setValue("effectiveInterestRateYear", e.target.value)
                        handleChange(e)
                      }})}
                    />
                  )}
                  name="effectiveInterestRateYear"
                  control={control}
                />
              </Box>
            </Grid>

            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <Typography variant="caption" display="block" gutterBottom >
                  Taxa Juros Efetivo (a.m.)
                </Typography>
                <Controller
                  render={({ field }) => (
                    <NumericInput
                      precision={4}
                      decimalChar=','
                      thousandChar='.'
                      variant='outlined'
                      value={getValues("effectiveInterestRateMonth")}
                      {...register("effectiveInterestRateMonth", { onChange: (e) => {
                        setValue("effectiveInterestRateMonth", e.target.value)
                        handleChange(e)
                      }})}
                    />
                  )}
                  name="effectiveInterestRateMonth"
                  control={control}
                />
            </Grid>

            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <Box className={classes.gridBoxFirst}>
                <Typography variant="caption" display="block" gutterBottom >
                  DFI - Seguro de danos físicos ao imóvel
                </Typography>
                <Controller
                  render={({ field }) => (
                    <NumericInput
                      precision={4}
                      decimalChar=','
                      thousandChar='.'
                      variant='outlined'
                      value={getValues("dfiPropertyPhysicalDamageInsurance")}
                      {...register("dfiPropertyPhysicalDamageInsurance", { onChange: (e) => {
                        setValue("dfiPropertyPhysicalDamageInsurance", e.target.value)
                        handleChange(e)
                      }})}
                    />
                  )}
                  name="dfiPropertyPhysicalDamageInsurance"
                  control={control}
                />
              </Box>
            </Grid>

            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <Box className={classes.gridBox}>
                <Typography variant="caption" display="block" gutterBottom >
                  CESH - Custo efetivo do seguro habitacional
                </Typography>
                <Controller
                  render={({ field }) => (
                    <NumericInput
                      precision={4}
                      decimalChar=','
                      thousandChar='.'
                      variant='outlined'
                      value={getValues("ceshEffectiveCostOfInsurance")}
                      {...register("ceshEffectiveCostOfInsurance", { onChange: (e) => {
                        setValue("ceshEffectiveCostOfInsurance", e.target.value)
                        handleChange(e)
                      }})}
                    />
                  )}
                  name="ceshEffectiveCostOfInsurance"
                  control={control}
                />
              </Box>
            </Grid>

            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <Box className={classes.gridBoxFirst}>
                <Typography variant="caption" display="block" gutterBottom >
                  MIP - Seguro de morte e invalidez
                </Typography>
                <Controller
                  render={({ field }) => (
                    <NumericInput
                      precision={4}
                      decimalChar=','
                      thousandChar='.'
                      variant='outlined'
                      value={getValues("mipDeathAndPermanentDisabilityInsurance")}
                      {...register("mipDeathAndPermanentDisabilityInsurance", { onChange: (e) => {
                        setValue("mipDeathAndPermanentDisabilityInsurance", e.target.value)
                        handleChange(e)
                      }})}
                    />
                  )}
                  name="mipDeathAndPermanentDisabilityInsurance"
                  control={control}
                />
              </Box>
            </Grid>

            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <Box className={classes.gridBox}>
                <Typography variant="caption" display="block" gutterBottom >
                  TAC - Tarifa de administração de contrato
                </Typography>
                <Controller
                  render={({ field }) => (
                    <NumericInput
                      precision={2}
                      decimalChar=','
                      thousandChar='.'
                      variant='outlined'
                      value={getValues("tacContractAdministrationFee")}
                      {...register("tacContractAdministrationFee", { onChange: (e) => {
                        setValue("tacContractAdministrationFee", e.target.value)
                        handleChange(e)
                      }})}
                    />
                  )}
                  name="tacContractAdministrationFee"
                  control={control}
                />
              </Box>
            </Grid>

            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <Box className={classes.gridBoxFirst}>
                <Typography variant="caption" display="block" gutterBottom >
                  Tarifa de avaliação do imóvel
                </Typography>
                <Controller
                  render={({ field }) => (
                    <NumericInput
                      precision={2}
                      decimalChar=','
                      thousandChar='.'
                      variant='outlined'
                      value={getValues("propertyValuationFee")}
                      {...register("propertyValuationFee", { onChange: (e) => {
                        setValue("propertyValuationFee", e.target.value)
                        handleChange(e)
                      }})}
                    />
                  )}
                  name="propertyValuationFee"
                  control={control}
                />
              </Box>
            </Grid>

            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <Typography variant="caption" display="block" gutterBottom >
                IOF - Imposto sobre operações financeiras
              </Typography>
                <Controller
                  render={({ field }) => (
                    <NumericInput
                      precision={2}
                      decimalChar=','
                      thousandChar='.'
                      variant='outlined'
                      value={getValues("iofTaxOnFinancialOperations")}
                      {...register("iofTaxOnFinancialOperations", { onChange: (e) => {
                        setValue("iofTaxOnFinancialOperations", e.target.value)
                        handleChange(e)
                      }})}
                    />
                  )}
                  name="iofTaxOnFinancialOperations"
                  control={control}
                />
            </Grid>

          </Grid>
        </TabPanel>
        </Paper>
      </Box>
    </Paper>
  )
}

export default SimulationForm
