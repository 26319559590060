import { Box, Button, FormControl, Grid, MenuItem, TextField, Typography } from "@mui/material";
import { ProposalContext } from "contexts/proposal-context"
import { IProposalDTO } from "data/dtos/i-proposal-dto";
import { useContext } from "react"
import { useForm } from "react-hook-form";
import { completeNamePlaceHolder, cpfMask, cpfMaskPlaceholder, emailPlaceHolder, identityIssuer, identityMask, nationalityPlaceHolder, phoneMask, phoneMaskPlaceholder, sanatizeStringValue } from "utils/utils";
import { useStyles } from "../../styles";
import { createMask } from "imask";
import { DocumentType, showDocumentType } from "hooks/enums/DocumentType";
import { invalidCelPhoneLength, invalidCpfLength, invalidDate, invalidEmail, requiredField } from "utils/error-messages";
import { ProposalDataFormStep } from "./form-step";
import { IsEmailRegex } from "utils/regex";

type Props = {
  isDisabled: boolean
  setProposalFormStep: React.Dispatch<React.SetStateAction<ProposalDataFormStep>>
}

export const SpousePersonalData = ({ isDisabled, setProposalFormStep }: Props) => {
  const { proposal, setProposal } = useContext(ProposalContext);
  const classes = useStyles()

  const saveData = (spousePersonalData: IProposalDTO) => {
    setProposal({...proposal, ...spousePersonalData})
    setProposalFormStep(ProposalDataFormStep.SPOUSE_PROFESSIONAL_DATA)
  }

  const handleBack = () => {
    setProposalFormStep(ProposalDataFormStep.PERSON_PROFESSIONAL_DATA)
  }

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm<IProposalDTO>({
    defaultValues: {
      spouseCompleteName: proposal.spouseCompleteName || '',
      spouseEmail: proposal.spouseEmail || '',
      spouseBirthDate: proposal.spouseBirthDate || '',
      spouseCpf: proposal.spouseCpf || '',

      spouseDocumentType: proposal.spouseDocumentType || '',
      spouseDocumentNumber: proposal.spouseDocumentNumber || '',
      spouseDocumentIssuingAgency: proposal.spouseDocumentIssuingAgency || '',
      spousePhoneNumber:  proposal.spousePhoneNumber || '',
      spouseNationality:  proposal.spouseNationality || '',
    },
    reValidateMode: 'onChange'
  })

  return (
    <form onSubmit={handleSubmit(saveData)}>
      <FormControl >
        <Grid container spacing={1} className={classes.formContainer}>

          <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
            <Typography variant="caption" display="block" gutterBottom >
              Nome Completo *
            </Typography>
            <TextField
              id="spouseCompleteName"
              name="spouseCompleteName"
              error={!!errors.spouseCompleteName}
              helperText={errors?.spouseCompleteName?.message}
              placeholder={completeNamePlaceHolder}
              variant="outlined"
              margin="dense"
              size="small"
              fullWidth={true}
              InputLabelProps={{
                shrink: true
              }}
              inputProps={{
                maxLength: 60
              }}
              {...register("spouseCompleteName", {
                required: requiredField,
                onChange: (e) => {
                  setProposal({ ...proposal, spouseCompleteName: e.target.value })
                }
              })}
              disabled={isDisabled}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
            <Typography variant="caption" display="block" gutterBottom >
              Email *
            </Typography>
            <TextField
              id="spouseEmail"
              name="spouseEmail"
              error={!!errors.spouseEmail}
              helperText={errors?.spouseEmail?.message}
              placeholder={emailPlaceHolder}
              variant="outlined"
              margin="dense"
              size="small"
              fullWidth={true}
              InputLabelProps={{
                shrink: true
              }}
              inputProps={{
                maxLength: 100
              }}
              {...register("spouseEmail", {
                required: requiredField,
                pattern: {
                  value: IsEmailRegex,
                  message: invalidEmail,
                },
                onChange: (e) => {
                  setProposal({ ...proposal, spouseEmail: e.target.value })
                }
              })}
              disabled={isDisabled}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={4} lg={2} xl={2}>
            <Typography variant="caption" display="block" gutterBottom >
              Data Nascimento *
            </Typography>
            <TextField
              id="spouseBirthDate"
              name="spouseBirthDate"
              error={!!errors.spouseBirthDate}
              helperText={errors?.spouseBirthDate?.message}
              variant="outlined"
              margin="dense"
              size="small"
              fullWidth={true}
              type="date"
              InputLabelProps={{
                shrink: true
              }}
              {...register("spouseBirthDate", {
                required: requiredField,
                min: {
                  value: '1920-01-01',
                  message: invalidDate
                },
                onChange: (e) => {
                  setProposal({ ...proposal, spouseBirthDate: e.target.value })
                }
            })}
            disabled={isDisabled}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={4} lg={2} xl={2}>
            <Typography variant="caption" display="block" gutterBottom >
              CPF *
            </Typography>
            <TextField
              id="spouseCpf"
              name="spouseCpf"
              error={!!errors.spouseCpf}
              helperText={errors?.spouseCpf?.message}
              placeholder={cpfMaskPlaceholder}
              variant="outlined"
              margin="dense"
              size="small"
              fullWidth={true}
              InputLabelProps={{
                shrink: true
              }}
              {...register("spouseCpf", {
                required: requiredField,
                validate: (value: string) => {
                  const cpf = sanatizeStringValue(value);
                  return cpf.length === 11 || invalidCpfLength;
                },
                onChange: (e) => {
                  const personCPF = createMask({ mask: cpfMask }).resolve(e.target.value)
                  setValue("spouseCpf", personCPF)
                  setProposal({ ...proposal, personCpf: personCPF })
                }
              })}
              disabled={isDisabled}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
            <Typography variant="caption" display="block" gutterBottom >
              Tipo de Documento *
            </Typography>
            <TextField
              id="spouseDocumentType"
              name="spouseDocumentType"
              error={!!errors.spouseDocumentType}
              helperText={errors?.spouseDocumentType?.message}
              variant="outlined"
              margin="dense"
              size="small"
              fullWidth={true}
              value={watch('spouseDocumentType')}
              select
              {...register("spouseDocumentType", {
                required: requiredField,
                onChange: (e) => {
                  setProposal({ ...proposal, spouseDocumentType: e.target.value })
                }
              })}
              disabled={isDisabled}
            >
            {Object.entries(DocumentType).map(([key, value]) => (
              <MenuItem
                key={key}
                value={value}
              >
                {showDocumentType(value)}
              </MenuItem>
            ))}
            </TextField>
          </Grid>

          <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
            <Typography variant="caption" display="block" gutterBottom >
              Numero do documento *
            </Typography>
            <TextField
              id="spouseDocumentNumber"
              name="spouseDocumentNumber"
              error={!!errors.spouseDocumentNumber}
              helperText={errors?.spouseDocumentNumber?.message}
              placeholder={identityMask}
              variant="outlined"
              margin="dense"
              size="small"
              fullWidth={true}
              InputLabelProps={{
                shrink: true
              }}
              inputProps={{
                maxLength: 100
              }}
              {...register("spouseDocumentNumber", {
                required: requiredField,
                onChange: (e) => {
                  setProposal({ ...proposal, spouseDocumentNumber: e.target.value })
                }
              })}
              disabled={isDisabled}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={4} lg={2} xl={2}>
            <Typography variant="caption" display="block" gutterBottom >
              Orgão Emissor
            </Typography>
            <TextField
              disabled={watch('spouseDocumentType') !== DocumentType.RG || isDisabled}
              id="spouseDocumentIssuingAgency"
              name="spouseDocumentIssuingAgency"
              error={!!errors.spouseDocumentIssuingAgency}
              helperText={errors?.spouseDocumentIssuingAgency?.message}
              placeholder={identityIssuer}
              variant="outlined"
              margin="dense"
              size="small"
              fullWidth={true}
              InputLabelProps={{
                shrink: true
              }}
              inputProps={{
                maxLength: 100
              }}
              {...register("spouseDocumentIssuingAgency", {
                onChange: (e) => {
                  setProposal({ ...proposal, spouseDocumentIssuingAgency: e.target.value })
                }
              })}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={4} lg={2} xl={2}>
            <Typography variant="caption" display="block" gutterBottom >
              Celular *
            </Typography>
            <TextField
              id="spousePhoneNumber"
              name="spousePhoneNumber"
              error={!!errors.spousePhoneNumber}
              helperText={errors?.spousePhoneNumber?.message}
              placeholder={phoneMaskPlaceholder}
              variant="outlined"
              margin="dense"
              size="small"
              fullWidth={true}
              InputLabelProps={{
                shrink: true
              }}
              inputProps={{
                maxLength: 100
              }}
              {...register("spousePhoneNumber", {
                required: requiredField,
                validate: (value) => {
                  const cellPhone = sanatizeStringValue(value);
                  return cellPhone.length === 11 || invalidCelPhoneLength;
                },
                onChange: (e) => {
                  const spousePhoneNumber = createMask({ mask: phoneMask }).resolve(e.target.value)
                  setValue("spousePhoneNumber", spousePhoneNumber)
                  setProposal({ ...proposal, spousePhoneNumber })
                }
              })}
              disabled={isDisabled}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={4} lg={2} xl={2}>
            <Typography variant="caption" display="block" gutterBottom >
              Nacionalidade *
            </Typography>
            <TextField
              id="spouseNationality"
              name="spouseNationality"
              error={!!errors.spouseNationality}
              helperText={errors?.spouseNationality?.message}
              placeholder={nationalityPlaceHolder}
              variant="outlined"
              margin="dense"
              size="small"
              fullWidth={true}
              InputLabelProps={{
                shrink: true
              }}
              inputProps={{
                maxLength: 100
              }}
              {...register("spouseNationality", {
                required: requiredField,
                onChange: (e) => {
                  setProposal({ ...proposal, spouseNationality: e.target.value })
                }
              })}
              disabled={isDisabled}
            />
          </Grid>

          <Grid container spacing={1}>
            <Button variant="contained" type="submit" sx={{ mt: 1, mb: 1 }}>
              Continuar
            </Button>
            <Button onClick={handleBack} sx={{ mt: 1, mr: 1, ml: 1 }}>
              Voltar
            </Button>
          </Grid>

        </Grid>
      </FormControl>
    </form>
  )
}
