import React, { useEffect, useState } from 'react'
import { Box, Grid, Paper, Typography } from '@mui/material'
import { useStyles } from "./styles"
import { CustomTable, FormHeader } from 'components';
import { comboBoxValues, FormatTableFieldType, ITableHeadCellDTO } from 'data/dtos/components/i-table-head-cell-dto';
import { useProfile } from 'hooks/use-profile';
import { ProfileType } from 'hooks/enums/ProfileType';
import FileOpenIcon from '@mui/icons-material/FileOpen';
import { useHistory } from 'react-router-dom';
import api from 'services/api';
import { ProposalPerformanceStatus } from 'hooks/enums/ProposalStatus';
import NoProposals from 'components/no-proposals';
import FunnelChart, { FunnelChartCategories, FunnelChartProps } from './FunnelChart';
import PieChart, { PieChartCategories } from './PieChart';
import DefaultModal, { DialogType } from 'components/modal';
import { getUsers, UpdateProposal } from 'services/users';
import { ProposalReport, ProposalStatusesReport, UpdateOfficerProposal } from './types';

const headCells: ITableHeadCellDTO[] = [
  {
    id: 'proposalPersonCompleteName',
    label: 'Cliente',
    width: 2
  },
  {
    id: 'proposalCreatedAt',
    label: 'Data da Proposta',
    width: 2,
    format: FormatTableFieldType.DATE
  },
  {
    id: 'partnerBankName',
    label: 'Banco Parceiro',
    width: 2
  },
  {
    id: 'proposalFinancingValue',
    label: 'Valor Proposta',
    width: 2,
    format: FormatTableFieldType.MONEY
  },
  {
    id: 'proposalPerformanceStatus',
    label: 'Fase',
    width: 2,
    format: FormatTableFieldType.PROPOSAL_PERFORMANCE_STATUS
  },
  {
    id: 'proposalDeclineReason',
    label: 'Motivo',
    width: 2
  },
]

const officerGestorHeadCells: ITableHeadCellDTO[] = [
  {
    id: 'proposalPersonCompleteName',
    label: 'Cliente',
    width: 2
  },
  {
    id: 'proposalCreatedAt',
    label: 'Data da Proposta',
    width: 1,
    format: FormatTableFieldType.DATE
  },
  {
    id: 'partnerBankName',
    label: 'Banco Parceiro',
    width: 1
  },
  {
    id: 'proposalFinancingValue',
    label: 'Valor Proposta',
    width: 1,
    format: FormatTableFieldType.MONEY
  },
  {
    id: 'proposalPerformanceStatus',
    label: 'Fase',
    width: 1,
    format: FormatTableFieldType.PROPOSAL_PERFORMANCE_STATUS
  },
  {
    id: 'proposalDeclineReason',
    label: 'Motivo',
    width: 1
  },
  {
    id: 'assessorName',
    label: 'Assesor',
    width: 1
  },
  {
    id: 'organizationName',
    label: 'Parceiro',
    width: 3
  },
]

const adminHeadCells: ITableHeadCellDTO[] = [
  {
    id: 'proposalPersonCompleteName',
    label: 'Cliente',
    width: 2
  },
  {
    id: 'proposalCreatedAt',
    label: 'Data da Proposta',
    width: 1,
    format: FormatTableFieldType.DATE
  },
  {
    id: 'partnerBankName',
    label: 'Banco Parceiro',
    width: 1
  },
  {
    id: 'proposalFinancingValue',
    label: 'Valor Proposta',
    width: 1,
    format: FormatTableFieldType.MONEY
  },
  {
    id: 'proposalPerformanceStatus',
    label: 'Fase',
    width: 1,
    format: FormatTableFieldType.PROPOSAL_PERFORMANCE_STATUS
  },
  {
    id: 'proposalDeclineReason',
    label: 'Motivo',
    width: 1
  },
  {
    id: 'assessorName',
    label: 'Assesor',
    width: 1
  },
  {
    id: 'organizationName',
    label: 'Parceiro',
    width: 2
  }
]

const Performance: React.FC = () => {
  const classes = useStyles()
  const [loading, setLoading] = useState(0)
  const [search, setSearch] = useState('')
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(100)
  const [orderByDirection, setOrderByDirection] = useState(true)
  const [rowsCount, setRowsCount] = useState(0)
  const [columnOrder, setColumnOrder] = useState<('ASC' | 'DESC')[]>([])
  const { selectedProfile } = useProfile();
  const [proposalReportList, setProposalReportList] = useState<ProposalReport>({proposals: [], proposalStatuses: []})
  const [pieChartCategories, setPieCategories] = useState<PieChartCategories[]>([])
  const [funnelChartCategories, setFunnelChartCategories] = useState<FunnelChartCategories[]>([])
  const [refusedProposalsAmount, setRefusedProposalsAmount] = useState<Number>()
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false)
  const [proposalsToUpdate, setProposalsToUpdate] = useState<UpdateOfficerProposal[]>();
  const shouldDisplayPieChart = pieChartCategories.some(category => category.value !== 0)
  const isAdmin = selectedProfile === ProfileType.ADMIN
  const [responsibleUsers, setResponsibleUsers] = useState<comboBoxValues[]>([])

  const handleCloseModal = async (shouldNotifyOfficer: boolean) => {
    setIsConfirmationModalOpen(false)
    for(const proposalToUpdate of proposalsToUpdate) {
      const { id, officerUserID } = proposalToUpdate
      await UpdateProposal({ proposalID: id, payload: { officerUserID }, shouldNotifyOfficer })
    }
    setProposalsToUpdate([])
  }

  const newHeadCell = {
    id: 'officerID',
    label: 'Officer',
    width: 1,
    format: FormatTableFieldType.COMBO_BOX,
    possibleValues: responsibleUsers,
    onChange: (proposalToUpdate: UpdateOfficerProposal) => {
      setProposalsToUpdate(prevState => {
        let updatedProposals: UpdateOfficerProposal[] = []
        if(prevState) {
          updatedProposals = prevState.filter(
            proposal => proposal.id !== proposalToUpdate.id
          );
        }
        return [...updatedProposals, proposalToUpdate];
      });
    }
  };

  const exists = adminHeadCells.some(cell => cell.id === newHeadCell.id);

  if (!exists && responsibleUsers.length !== 0) {
    adminHeadCells.push(newHeadCell);
  }



  const getTableHeadCells = (selectedProfile: string) => {
    switch (selectedProfile) {
      case ProfileType.ADMIN:
        return adminHeadCells;
      case ProfileType.OFFICER:
      case ProfileType.GESTOR:
        return officerGestorHeadCells;
      default:
        return headCells;
    }
  };

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number, ) => {
    setPage(newPage);
  }

  const handleChangeRowsPerPage = async (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }
  const history = useHistory()


  const prepareChartData = (proposalStatuses: ProposalStatusesReport[]) => {
    const pieChart: PieChartCategories[] = []
    const temp: FunnelChartCategories[] = [];
    let refusedProposalsAmount: number = 0

    for(const proposalStatus of proposalStatuses) {
      switch(proposalStatus.status) {
        case ProposalPerformanceStatus.MAPPED:
          temp.push({
            name: 'Mapeada',
            value: Number(proposalStatus.amount),
          })
          break
        case ProposalPerformanceStatus.IN_ANALYSIS:
          temp.push({
            name: 'Análise de Documentos',
            value: Number(proposalStatus.amount),
          })
          break
        case ProposalPerformanceStatus.HIRING:
          temp.push({
            name: 'Em Contratação',
            value: Number(proposalStatus.amount),
          })
          break
        case ProposalPerformanceStatus.COMPLETED:
          temp.push({
            name: 'Concluído',
            value: Number(proposalStatus.amount),
          })
          break

        case ProposalPerformanceStatus.DECLINED_CREDIT_REFUSAL:
          pieChart.push({
            value: Number(proposalStatus.amount),
            name: 'Recusada de crédito',
          })
          refusedProposalsAmount += Number(proposalStatus.amount)
          break
        case ProposalPerformanceStatus.DECLINED_LACK_OF_INFORMATION:
          pieChart.push({
            value: Number(proposalStatus.amount),
            name: 'Recusada (falta de informação)',
          })
          refusedProposalsAmount += Number(proposalStatus.amount)
          break
        case ProposalPerformanceStatus.DECLINED_COMPETITOR_CONDITIONS:
          pieChart.push({
            value: Number(proposalStatus.amount),
            name: 'Condições dos concorrente',
          })
          refusedProposalsAmount += Number(proposalStatus.amount)
          break
        case ProposalPerformanceStatus.DECLINED_ABANDONMENT:
          pieChart.push({
            value: Number(proposalStatus.amount),
            name: 'Abandono',
          })
          refusedProposalsAmount += Number(proposalStatus.amount)
          break
        case ProposalPerformanceStatus.DECLINED_BY_CLIENT:
          pieChart.push({
            value: Number(proposalStatus.amount),
            name: 'Cancelado pelo cliente',
          })
          refusedProposalsAmount += Number(proposalStatus.amount)
          break
      }
    }

    setRefusedProposalsAmount(refusedProposalsAmount)
    setPieCategories(pieChart)
    setFunnelChartCategories(temp)
  }

  const loadProposals = async () => {
    setLoading(1)

    let queryParams = `?search=${search}`

    await api
      .get('/proposals' + queryParams)
      .then(async listResponse => {
        const data = listResponse.data as ProposalReport

        setProposalReportList(data)
        prepareChartData(data.proposalStatuses)
      })
      .catch(error => {
        return error
      })
      .finally(() => {
        setLoading(0)
      })
  }

  useEffect(() => {
    loadProposals()
  }, [search])

  useEffect(() => {
    async function loadResponsibleUsers() {
      const response = await getUsers({ profileIDs: [ProfileType.OFFICER]})
      setResponsibleUsers(response.map(user => {
        return {
          id: user.id,
          value: user.name
        }
      }))
    }
    if(isAdmin) {
      loadResponsibleUsers()
    }

  }, [isAdmin])

  const tableHeadCells = getTableHeadCells(selectedProfile);

  if(!proposalReportList || !proposalReportList.proposals) {
    return <NoProposals />
  }

  return (
    <Paper elevation={0} className={classes.paper}>

      <FormHeader
        title="Performance"
        buttons={isAdmin ? [{
          text: 'Salvar',
          color: 'primary',
          onClick() {
              setIsConfirmationModalOpen(true)
          },
          isDisabled: proposalsToUpdate === undefined || proposalsToUpdate.length === 0
        }] : []}
      />

      <Grid container spacing={1} className={classes.formContainer} >
      </Grid>

      <Box display="flex" alignItems="center" justifyContent="space-evenly" flexDirection="row" paddingBottom={3}>
        <Box display="flex" alignItems="center" flexDirection="column">
          <Typography variant="h6" gutterBottom>
            Situação das Oportunidades
          </Typography>
          <FunnelChart categories={funnelChartCategories} />
        </Box>
          { shouldDisplayPieChart &&
            <Box display="flex" alignItems="center" flexDirection="column">
              <Typography variant="h6" gutterBottom mb={3}>
                Motivo das Perdas {` - ${refusedProposalsAmount} perda(s) ` }
              </Typography>
              <PieChart  categories={pieChartCategories} />
            </Box>
          }
      </Box>

      <CustomTable
        headCells={tableHeadCells}
        rows={proposalReportList.proposals}
        totalRows={rowsCount}
        handleSearch={setSearch}
        isLoading={loading}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        rowsPerPage={rowsPerPage}
        handleChangePage={handleChangePage}
        page={page}
        columnOrder={columnOrder}
        setColumnOrder={setColumnOrder}
        orderByDirection={orderByDirection}
        setOrderByDirection={setOrderByDirection}
        buttons={[
          {
            name: 'Detalhes',
            icon: FileOpenIcon,
            onClick(proposalID) {
              history.push(`proposals/edit/${proposalID}`)
            },
          }
        ]}
      />

      <DefaultModal
        isOpen={isConfirmationModalOpen}
        title='Deseja notificar o(s) responsável(is)?'
        bodyContent={"Deseja notificar o(s) responsável(is) que uma nova proposta foi atribuída a ele(s) por e-mail?"}
        onConfirmModal={() => handleCloseModal(true)}
        onCloseModal={() => handleCloseModal(false)}
        type={DialogType.ACTION}
        confirmModalValue='Sim'
        cancelModalValue='Não'
      />

    </Paper>
  )
}

export default Performance
