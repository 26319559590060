export enum HouseType {
  OWN = 'OWN',
  RENT = 'RENT',
}

export const showHouseType = (houseType: HouseType): string => {
  switch (houseType) {
    case HouseType.OWN:
      return 'Residência Própria'
    case HouseType.RENT:
        return 'Alugada'
    default:
      return 'Tipo de residência inválido'
  }
}
