import React from 'react';
import ReactApexChart from 'react-apexcharts';
import { ApexOptions } from 'apexcharts';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  labelStyle: {
    '& .apexcharts-datalabel-text': {
      dominantBaseline: 'middle !important', // Centraliza horizontalmente
    },
  },
});

export interface PieChartCategories {
  name: string,
  value: number,
}

export interface PieChartProps {
  categories: PieChartCategories[]
}

const PieChart = ({ categories }: PieChartProps) => {
  const classes = useStyles();
  const options: ApexOptions = {
    chart: {
      type: 'donut',
    },
    responsive: [{
      breakpoint: 480,
      options: {
        chart: {
          width: 400
        },
        legend: {
          position: 'bottom'
        }
      }
    }],
    labels: categories.map(category => category.name)
  }

  const series = categories.map(category => category.value)

  return (
    <div className={classes.labelStyle}>
      <div id="chart">
        <ReactApexChart options={ options } series={series} type="donut" height={500} width={500}/>
      </div>
      <div id="html-dist"></div>
    </div>
  )
};

export default PieChart;
