import { Box, Button, FormControl, Grid, MenuItem, TextField, Typography } from "@mui/material";
import { ProposalContext } from "contexts/proposal-context"
import { IProposalDTO } from "data/dtos/i-proposal-dto";
import { useContext, useState } from "react"
import { useForm } from "react-hook-form";
import { currencyMask, emailPlaceHolder, incomeMaskPlaceHolder, jobPositionPlaceHolder, legalNamePlaceHolder, nationalityPlaceHolder, phoneMask, phoneMaskPlaceholder, professionPlaceHolder } from "utils/utils";
import { useStyles } from "../../styles";
import { IncomeType, showIncomeType } from "hooks/enums/IncomeType";
import { ProposalDataFormStep } from "./form-step";
import { MaritalStatus } from "hooks/enums/MaritalStatus";

type Props = {
  isDisabled: boolean
  setProposalFormStep: React.Dispatch<React.SetStateAction<ProposalDataFormStep>>
  setShouldResetForm: React.Dispatch<React.SetStateAction<boolean>>
}

export const PersonProfessionalData = ({ isDisabled, setProposalFormStep, setShouldResetForm }: Props) => {
  const { proposal, setProposal } = useContext(ProposalContext);
  const classes = useStyles()

  const saveData = (personProfessionalData: IProposalDTO) => {
    setProposal({...proposal, ...personProfessionalData})
    setProposalFormStep(proposal.personMaritalStatus === MaritalStatus.MARRIED ? ProposalDataFormStep.SPOUSE_PERSONAL_DATA : ProposalDataFormStep.REAL_STATE_DATA)
  }

  const handleBack = () => {
    setProposalFormStep(ProposalDataFormStep.PERSON_PERSONAL_DATA)
    setShouldResetForm(false)
  }

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm<IProposalDTO>({
    defaultValues: {
      personProfession: proposal.personProfession || '',
      personJobPosition: proposal.personJobPosition || '',
      personCompanyName: proposal.personCompanyName || '',

      personHireDate: proposal.personHireDate || '',
      personGrossIncome: proposal.personGrossIncome || '',
      personNetIncome: proposal.personNetIncome || '',
      personIncomeType: proposal.personIncomeType || '',
    },
    reValidateMode: 'onChange'
  })

  return (
    <form onSubmit={handleSubmit(saveData)}>
      <FormControl >
        <Grid container spacing={1} className={classes.formContainer}>

          <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
            <Typography variant="caption" display="block" gutterBottom >
              Profissão *
            </Typography>
            <TextField
              id="personProfession"
              name="personProfession"
              error={!!errors.personProfession}
              helperText={errors?.personProfession?.message}
              placeholder={professionPlaceHolder}
              variant="outlined"
              margin="dense"
              size="small"
              fullWidth={true}
              InputLabelProps={{
                shrink: true
              }}
              inputProps={{
                maxLength: 60
              }}
              {...register("personProfession", {
                required: "Campo Obrigatório",
                onChange: (e) => {
                  setProposal({ ...proposal, personProfession: e.target.value })
                }
              })}
              disabled={isDisabled}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
            <Typography variant="caption" display="block" gutterBottom >
              Cargo *
            </Typography>
            <TextField
              id="personJobPosition"
              name="personJobPosition"
              error={!!errors.personJobPosition}
              helperText={errors?.personJobPosition?.message}
              placeholder={jobPositionPlaceHolder}
              variant="outlined"
              margin="dense"
              size="small"
              fullWidth={true}
              InputLabelProps={{
                shrink: true
              }}
              inputProps={{
                maxLength: 60
              }}
              {...register("personJobPosition", {
                required: "Campo Obrigatório",
                onChange: (e) => {
                  setProposal({ ...proposal, personJobPosition: e.target.value })
                }
              })}
              disabled={isDisabled}
            />
          </Grid>

          <Grid item xs={12} sm={8} md={4} lg={4} xl={4}>
            <Typography variant="caption" display="block" gutterBottom >
              Nome da Empresa *
            </Typography>
            <TextField
              id="personCompanyName"
              name="personCompanyName"
              error={!!errors.personCompanyName}
              helperText={errors?.personCompanyName?.message}
              placeholder={legalNamePlaceHolder}
              variant="outlined"
              margin="dense"
              size="small"
              fullWidth={true}
              InputLabelProps={{
                shrink: true
              }}
              inputProps={{
                maxLength: 60
              }}
              {...register("personCompanyName", {
                required: "Campo Obrigatório",
                onChange: (e) => {
                  setProposal({ ...proposal, personCompanyName: e.target.value })
                }
              })}
              disabled={isDisabled}
            />
          </Grid>

          <Grid item xs={12} sm={4} md={3} lg={3} xl={3}>
            <Typography variant="caption" display="block" gutterBottom >
              Data Admissão *
            </Typography>
            <TextField
              id="personHireDate"
              name="personHireDate"
              error={!!errors.personHireDate}
              helperText={errors?.personHireDate?.message}
              variant="outlined"
              margin="dense"
              size="small"
              fullWidth={true}
              type="date"
              InputLabelProps={{
                shrink: true
              }}
              {...register("personHireDate", {
                required: "Campo Obrigatório",
                onChange: (e) => {
                  setProposal({ ...proposal, personHireDate: e.target.value })
                }
              })}
              disabled={isDisabled}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
            <Typography variant="caption" display="block" gutterBottom >
              Renda Bruta Mensal *
            </Typography>
            <TextField
              id="personGrossIncome"
              name="personGrossIncome"
              error={!!errors.personGrossIncome}
              helperText={errors?.personGrossIncome?.message}
              placeholder={incomeMaskPlaceHolder}
              variant="outlined"
              margin="dense"
              size="small"
              fullWidth={true}
              InputLabelProps={{
                shrink: true
              }}
              inputProps={{
                maxLength: 60
              }}
              {...register("personGrossIncome", {
                required: "Campo Obrigatório",
                onChange: (e) => {
                  const personGrossIncome = currencyMask(e.target.value)
                  setValue("personGrossIncome", personGrossIncome);
                  setProposal({ ...proposal, personGrossIncome })
                }
              })}
              disabled={isDisabled}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
            <Typography variant="caption" display="block" gutterBottom >
              Renda Líquida Mensal *
            </Typography>
            <TextField
              id="personNetIncome"
              name="personNetIncome"
              error={!!errors.personNetIncome}
              helperText={errors?.personNetIncome?.message}
              placeholder={incomeMaskPlaceHolder}
              variant="outlined"
              margin="dense"
              size="small"
              fullWidth={true}
              InputLabelProps={{
                shrink: true
              }}
              inputProps={{
                maxLength: 60
              }}
              {...register("personNetIncome", {
                required: "Campo Obrigatório",
                onChange: (e) => {
                  const personNetIncome = currencyMask(e.target.value)
                  setValue("personNetIncome", personNetIncome);
                  setProposal({ ...proposal, personNetIncome })
                }
              })}
              disabled={isDisabled}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
            <Typography variant="caption" display="block" gutterBottom >
              Tipo de Renda *
            </Typography>
            <TextField
              id="personIncomeType"
              name="personIncomeType"
              error={!!errors.personIncomeType}
              helperText={errors?.personIncomeType?.message}
              variant="outlined"
              margin="dense"
              size="small"
              fullWidth={true}
              value={watch('personIncomeType')}
              select
              {...register("personIncomeType", {
                required: "Campo Obrigatório",
                onChange: (e) => {
                  setProposal({ ...proposal, personIncomeType: e.target.value })
                }
              })}
              disabled={isDisabled}
            >
            {Object.entries(IncomeType).map(([key, value]) => (
              <MenuItem
                key={key}
                value={value}
              >
                {showIncomeType(value)}
              </MenuItem>
            ))}
            </TextField>
          </Grid>

          <Grid container spacing={1}>
            <Button variant="contained" type="submit" sx={{ mt: 1, mb: 1 }}>
              Continuar
            </Button>
            <Button onClick={handleBack} sx={{ mt: 1, mr: 1, ml: 1 }}>
              Voltar
            </Button>
          </Grid>

        </Grid>
      </FormControl>
    </form>
  )
}
