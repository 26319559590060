import { Alert, Avatar, Box, Button, Checkbox, FormControl, FormControlLabel, FormGroup, Grid, MenuItem, Paper, RadioGroup, TextField, Typography } from "@mui/material";
import { ProposalContext } from "contexts/proposal-context"
import { IProposalDTO } from "data/dtos/i-proposal-dto";
import { MaritalStatus } from "hooks/enums/MaritalStatus";
import { zodResolver } from '@hookform/resolvers/zod';
import { useContext, useEffect, useState } from "react"
import { Controller, FormProvider, useForm } from "react-hook-form";
import { accountAgencyPlaceHolder, accountNumberPlaceHolder, bankNamePlaceHolder, cnpjMask, cnpjMaskPlaceholder, completeNamePlaceHolder, cpfMask, cpfMaskPlaceholder, emailPlaceHolder, identityIssuer, identityMask, nationalityPlaceHolder, phoneMask, phoneMaskPlaceholder, sanatizeStringValue } from "utils/utils";
import { useStyles } from "../../styles";
import { createMask } from "imask";
import { HouseType } from "hooks/enums/HouseType";
import FileUpload from "components/file-upload";
import { array, object, TypeOf, z } from 'zod';
import PersonIcon from '@mui/icons-material/Person'
import BusinessIcon from '@mui/icons-material/Business'
import { ProposalDocumentationFormStep } from "./form-step";
import { IsEmailRegex } from "utils/regex";
import { invalidCelPhoneLength, invalidEmail, requiredField } from "utils/error-messages";
import ContentCopyRoundedIcon from '@mui/icons-material/ContentCopyRounded';
import api from "services/api";
import { ADDRESS_TOOLTIP_HELPER_TEXT, ATTORNEY_DOCUMENT_TOOLTIP_HELPER_TEXT, CIVIL_STATUS_TOOLTIP_HELPER_TEXT, DOCUMENT_LEGAL_REPRESENTATIVES_TOOLTIP_HELPER_TEXT, POWER_OF_ATTORNEY_TOOLTIP_HELPER_TEXT, RG_BACK_TOOLTIP_HELPER_TEXT, RG_FRONT_TOOLTIP_HELPER_TEXT, SIMPLIFIED_CERTIFICATE_TOOLTIP_HELPER_TEXT, SOCIAL_CONTRACT_TOOLTIP_HELPER_TEXT } from "utils/constants/tooltipHelperMessages";

type Props = {
  isDisabled: boolean
  setProposalFormStep: React.Dispatch<React.SetStateAction<ProposalDocumentationFormStep>>
}

enum SellerType {
  INDIVIDUAL = 'INDIVIDUAL',
  BUSINESS = 'BUSINESS'
}

const imageUploadSchema = object({
  image: z.instanceof(File),
  images: array(z.instanceof(File)),
});

type IUploadImage = TypeOf<typeof imageUploadSchema>;

export const SellerForm = ({ isDisabled, setProposalFormStep }: Props) => {
  const { proposal, setProposal } = useContext(ProposalContext);
  const classes = useStyles()
  const [accountBanks, setAccountBanks] = useState([])
  const [sellerType, setSellerType] = useState<SellerType>(proposal.sellerCNPJ ? SellerType.BUSINESS : SellerType.INDIVIDUAL);
  const [mainError, setMainError] = useState<string>('');

  const radioStyle = {
    display: 'none',
  };

  const handleChange = (event: any) => {
    setSellerType(event.target.value);
  };

  const methods = useForm<IUploadImage>({
    resolver: zodResolver(imageUploadSchema),
  });

  const handleBack = () => {
    setProposalFormStep(ProposalDocumentationFormStep.BUYER_DATA)
  }

  const handleFileUpload = (fieldName: string, file: File): void => {
    setProposal({...proposal, [fieldName]: file})

  }

  const saveData = (personPersonalData: IProposalDTO) => {
    setProposal({...proposal, ...personPersonalData})
    setProposalFormStep(ProposalDocumentationFormStep.PROPERTY_DATA)
  }

  useEffect(() => {
    async function loadData() {
      await api
      .get('/account-banks/')
      .then(response => {
        return response.data
      })
      .then((accountBanksResults) => {
        setAccountBanks(accountBanksResults)
      })
      .catch(error => {
        return error
      })
    }

    loadData()
  }, [])

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm<IProposalDTO>({
    defaultValues: {
      sellerTradeName: proposal.sellerTradeName || '',
      sellerCNPJ: proposal.sellerCNPJ || '',
      sellerCompleteName: proposal.sellerCompleteName || '',
      sellerAccountAgency: proposal.sellerAccountAgency || '',
      sellerAccountNumber: proposal.sellerAccountNumber || '',
      sellerAccountBankID: proposal.sellerAccountBankID || '',
      sellerEmail: proposal.sellerEmail || '',
      sellerPhoneNumber: proposal.sellerPhoneNumber || '',
    },
    reValidateMode: 'onChange'
  })

  return (
    <form onSubmit={handleSubmit(saveData)}>
      <FormProvider {...methods}>
          <Grid container spacing={1} className={classes.formContainer}>

            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <RadioGroup
                  row
                  aria-label="sellerType"
                  name="sellerType"
                  value={sellerType}
                  onChange={handleChange}
                >
                  <div style={{ display: 'flex', width: '100%', justifyContent: 'center', alignItems: 'center' }}>
                    <Box
                      component="div"
                      sx={{
                        marginRight: '10px',
                        borderRadius: '8px',
                        padding: '12px 0',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        cursor: 'pointer',
                        backgroundColor: '#fff',
                        color: sellerType === SellerType.INDIVIDUAL ? '#fff' : 'inherit',
                        border: sellerType === SellerType.INDIVIDUAL ? '2px solid #2614FE' : '2px solid #ccc',
                        width: '50%',
                        maxWidth: '300px',
                        marginBottom: '30px'
                      }}
                      onClick={() => setSellerType(SellerType.INDIVIDUAL)}
                    >
                      <input
                        type="radio"
                        id="individual"
                        value="individual"
                        checked={sellerType === SellerType.INDIVIDUAL}
                        onChange={handleChange}
                        style={radioStyle}
                        disabled={isDisabled}
                      />
                      <PersonIcon fontSize="large" style={{ marginBottom: '8px' }} />
                      <Typography>Vendedor Pessoa Física</Typography>
                    </Box>
                    <Box
                    width={100}
                      component="div"
                      sx={{
                        marginLeft: '10px',
                        borderRadius: '8px',
                        padding: '12px 0',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        cursor: 'pointer',
                        backgroundColor:'#fff',
                        color: sellerType === SellerType.BUSINESS ? '#fff' : 'inherit',
                        border: sellerType === SellerType.BUSINESS ? '2px solid #2614FE' : '2px solid #ccc',
                        width: '50%',
                        maxWidth: '300px',
                        marginBottom: '30px'
                      }}
                      onClick={() => setSellerType(SellerType.BUSINESS)}
                    >
                      <input
                        type="radio"
                        id="business"
                        value="business"
                        checked={sellerType === SellerType.BUSINESS}
                        onChange={handleChange}
                        style={radioStyle}
                        disabled={isDisabled}
                      />
                      <BusinessIcon fontSize="large" style={{ marginBottom: '8px' }} />
                      <Typography>Vendedor Pessoa Jurídica</Typography>
                    </Box>
                  </div>
                </RadioGroup>
              </Grid>
            </Grid>

            {/* INDIVIDUAL SELLER */}

            {sellerType === SellerType.INDIVIDUAL ? (
              <>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={4}>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Typography variant="caption" display="block" gutterBottom>
                      Nome Completo *
                    </Typography>
                    <TextField
                      id="sellerCompleteName"
                      name="sellerCompleteName"
                      error={!!errors.sellerCompleteName}
                      helperText={errors?.sellerCompleteName?.message}
                      placeholder={completeNamePlaceHolder}
                      value={proposal.sellerCompleteName}
                      variant="outlined"
                      margin="dense"
                      size="small"
                      fullWidth={true}
                      InputLabelProps={{
                        shrink: true
                      }}
                      inputProps={{
                        maxLength: 60
                      }}
                      {...register("sellerCompleteName", {
                        required: requiredField,
                        onChange: (e) => {
                          setProposal({ ...proposal, sellerCompleteName: e.target.value })
                        }
                      })}
                      disabled={isDisabled}
                    />
                  </Grid>
                </Grid>

                <Grid item xs={12} sm={12} md={6} lg={6} xl={2}>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Typography variant="caption" display="block" gutterBottom>
                      Agência
                    </Typography>
                    <TextField
                      id="sellerAccountAgency"
                      error={!!errors.sellerAccountAgency}
                      helperText={errors?.sellerAccountAgency?.message}
                      placeholder={accountAgencyPlaceHolder}
                      value={proposal.sellerAccountAgency}
                      variant="outlined"
                      margin="dense"
                      size="small"
                      fullWidth={true}
                      InputLabelProps={{
                        shrink: true
                      }}
                      {...register("sellerAccountAgency", {
                        required: requiredField,
                        onChange: (e) => {
                          setProposal({ ...proposal, sellerAccountAgency: e.target.value })
                        }
                      })}
                      disabled={isDisabled}
                    />
                  </Grid>
                </Grid>

                <Grid item xs={12} sm={12} md={6} lg={6} xl={2}>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Typography variant="caption" display="block" gutterBottom>
                      Conta
                    </Typography>
                    <TextField
                      id="sellerAccountNumber"
                      error={!!errors.sellerAccountNumber}
                      helperText={errors?.sellerAccountNumber?.message}
                      placeholder={accountNumberPlaceHolder}
                      value={proposal.sellerAccountNumber}
                      variant="outlined"
                      margin="dense"
                      size="small"
                      fullWidth={true}
                      InputLabelProps={{
                        shrink: true
                      }}
                      {...register("sellerAccountNumber", {
                        required: requiredField,
                        onChange: (e) => {
                          setProposal({ ...proposal, sellerAccountNumber: e.target.value })
                        }
                      })}
                      disabled={isDisabled}
                    />
                  </Grid>
                </Grid>

                <Grid item xs={12} sm={12} md={6} lg={6} xl={4}>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Typography variant="caption" display="block" gutterBottom>
                      Banco
                    </Typography>
                    <TextField
                      id="sellerAccountBankID"
                      error={!!errors.sellerAccountBankID}
                      helperText={errors?.sellerAccountBankID?.message}
                      variant="outlined"
                      margin="dense"
                      size="small"
                      fullWidth={true}
                      value={`${watch().sellerAccountBankID}`}
                      select
                      {...register("sellerAccountBankID", { onChange: (e) => {
                        setValue("sellerAccountBankID", e.target.value)
                      }})}
                      disabled={isDisabled}
                    >
                    {accountBanks.map((accountBank) => (
                      <MenuItem
                        key={accountBank.id}
                        value={accountBank.id}
                        disabled={isDisabled}
                      >
                        {accountBank.febrabanCode + ' - ' + accountBank.name}
                      </MenuItem>
                    ))}
                    </TextField>
                  </Grid>
                </Grid>

                <Grid item xs={12} sm={12} md={6} lg={6} xl={10}>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Typography variant="caption" display="block" gutterBottom>
                      Email *
                    </Typography>
                    <TextField
                      id="sellerEmail"
                      name="sellerEmail"
                      error={!!errors.sellerEmail}
                      helperText={errors?.sellerEmail?.message}
                      placeholder={emailPlaceHolder}
                      value={proposal.sellerEmail}
                      variant="outlined"
                      margin="dense"
                      size="small"
                      fullWidth={true}
                      InputLabelProps={{
                        shrink: true
                      }}
                      inputProps={{
                        maxLength: 100
                      }}
                      {...register("sellerEmail", {
                        required: requiredField,
                        pattern: {
                          value: IsEmailRegex,
                          message: invalidEmail,
                        },
                        onChange: (e) => {
                          setProposal({ ...proposal, sellerEmail: e.target.value })
                        }
                      })}
                      disabled={isDisabled}
                    />
                  </Grid>
                </Grid>

                <Grid item xs={12} sm={12} md={6} lg={6} xl={2}>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Typography variant="caption" display="block" gutterBottom>
                      Celular *
                    </Typography>
                    <TextField
                      id="sellerPhoneNumber"
                      name="sellerPhoneNumber"
                      error={!!errors.sellerPhoneNumber}
                      helperText={errors?.sellerPhoneNumber?.message}
                      placeholder={phoneMaskPlaceholder}
                      value={proposal.sellerPhoneNumber}
                      variant="outlined"
                      margin="dense"
                      size="small"
                      fullWidth={true}
                      InputLabelProps={{
                        shrink: true
                      }}
                      inputProps={{
                        maxLength: 100
                      }}
                      {...register("sellerPhoneNumber", {
                        required: requiredField,
                        validate: (value) => {
                          const cellPhone = sanatizeStringValue(value);
                          return cellPhone.length === 11 || invalidCelPhoneLength;
                        },
                        onChange: (e) => {
                          const sellerPhoneNumber = createMask({ mask: phoneMask }).resolve(e.target.value)
                          setValue("sellerPhoneNumber", sellerPhoneNumber)
                          setProposal({ ...proposal, sellerPhoneNumber })
                        }
                      })}
                      disabled={isDisabled}
                    />
                  </Grid>
                </Grid>

                <Paper elevation={1} variant="outlined" className={classes.paperWrapper}>
                  {/* {mainError !== '' && (<Alert severity="error" className={classes.alert}>{mainError}</Alert>)} */}

                  <Grid container alignItems='center' direction='row' style={{ maxWidth: '800px'}}>
                    <Grid item xs={1} sm={1} md={1} lg={1} xl={1} style={{ paddingRight: '0px'}} >
                      <Avatar sx={{ backgroundColor: '#F5F8FF' }}>
                        <ContentCopyRoundedIcon />
                      </Avatar>
                    </Grid>
                    <Grid item xs={11} sm={11} md={11} lg={11} xl={11}>
                      <div>
                        <Typography variant="caption" marginBottom={0} paddingBottom={0}>
                          <strong>Faça aqui o upload dos seus documentos</strong>
                        </Typography>
                        <div>
                          <Typography variant="caption" marginBottom={0} paddingBottom={0}>
                            Arquivos suportados nos seguintes formatos: PDF, PNG, JPEG e JPG, com tamanho máximo de 10MBs.
                          </Typography>
                        </div>
                      </div>
                    </Grid>
                  </Grid>

                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <FileUpload label="RG (frente) *" helperText={RG_FRONT_TOOLTIP_HELPER_TEXT} defaultValue={proposal.sellerFileRGFront} imageUrl={proposal.sellerFileRGFrontURL} name='sellerFileRGFront' isDisabled={isDisabled} onFileUpload={handleFileUpload} shouldShowHeader={true}isRequired={true}/>
                  </Grid>

                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <FileUpload label="RG (verso) *" helperText={RG_BACK_TOOLTIP_HELPER_TEXT} defaultValue={proposal.sellerFileRGBack} imageUrl={proposal.sellerFileRGBackURL} name='sellerFileRGBack' isDisabled={isDisabled} onFileUpload={handleFileUpload} isRequired={true}/>
                  </Grid>

                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <FileUpload label="Certidão de Estado Civil *" helperText={CIVIL_STATUS_TOOLTIP_HELPER_TEXT} defaultValue={proposal.sellerFileCertificateCivilStatus} imageUrl={proposal.sellerFileCertificateCivilStatusURL} name='sellerFileCertificateCivilStatus' isDisabled={isDisabled} onFileUpload={handleFileUpload} isRequired={true}/>
                  </Grid>

                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <FileUpload label="Comprovante de Endereço: Mês atual ou anterior *" helperText={ADDRESS_TOOLTIP_HELPER_TEXT} defaultValue={proposal.sellerFileAddressVerification} imageUrl={proposal.sellerFileAddressVerificationURL} name='sellerFileAddressVerification' isDisabled={isDisabled} onFileUpload={handleFileUpload} isRequired={true}/>
                  </Grid>
                </Paper>
              </>
            ) : (
              <>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={3}>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Typography variant="caption" display="block" gutterBottom>
                      Razão social *
                    </Typography>
                    <TextField
                      id="sellerTradeName"
                      name="sellerTradeName"
                      error={!!errors.sellerTradeName}
                      helperText={errors?.sellerTradeName?.message}
                      placeholder={completeNamePlaceHolder}
                      value={proposal.sellerTradeName}
                      variant="outlined"
                      margin="dense"
                      size="small"
                      fullWidth={true}
                      InputLabelProps={{
                        shrink: true
                      }}
                      inputProps={{
                        maxLength: 60
                      }}
                      {...register("sellerTradeName", {
                        required: requiredField,
                        onChange: (e) => {
                          setProposal({ ...proposal, sellerTradeName: e.target.value })
                        }
                      })}
                      disabled={isDisabled}
                    />
                  </Grid>
                </Grid>

                <Grid item xs={12} sm={12} md={6} lg={6} xl={2}>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Typography variant="caption" display="block" gutterBottom>
                      CNPJ
                    </Typography>
                    <TextField
                      id="sellerCNPJ"
                      fullWidth={true}
                      error={!!errors.sellerCNPJ}
                      helperText={errors?.sellerCNPJ?.message}
                      placeholder={cnpjMaskPlaceholder}
                      value={proposal.sellerCNPJ}
                      variant="outlined"
                      margin="dense"
                      size="small"
                      InputLabelProps={{
                        shrink: true
                      }}
                      onChange={(event) => {
                        const sellerCNPJ = createMask({ mask: cnpjMask }).resolve(event.target.value)
                        setValue("sellerCNPJ", sellerCNPJ)
                        setProposal({ ...proposal, sellerCNPJ })
                      }}
                      disabled={isDisabled}
                    />
                  </Grid>
                </Grid>

                <Grid item xs={12} sm={12} md={6} lg={6} xl={2}>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Typography variant="caption" display="block" gutterBottom>
                      Agência
                    </Typography>
                    <TextField
                      id="sellerAccountAgency"
                      error={!!errors.sellerAccountAgency}
                      helperText={errors?.sellerAccountAgency?.message}
                      placeholder={accountAgencyPlaceHolder}
                      value={proposal.sellerAccountAgency}
                      variant="outlined"
                      margin="dense"
                      size="small"
                      fullWidth={true}
                      InputLabelProps={{
                        shrink: true
                      }}
                      {...register("sellerAccountAgency", {
                        required: requiredField,
                        onChange: (e) => {
                          setProposal({ ...proposal, sellerAccountAgency: e.target.value })
                        }
                      })}
                      disabled={isDisabled}
                    />
                  </Grid>
                </Grid>

                <Grid item xs={12} sm={12} md={6} lg={6} xl={2}>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Typography variant="caption" display="block" gutterBottom>
                      Conta
                    </Typography>
                    <TextField
                      id="accountNumber"
                      error={!!errors.sellerAccountNumber}
                      helperText={errors?.sellerAccountNumber?.message}
                      placeholder={accountNumberPlaceHolder}
                      value={proposal.sellerAccountNumber}
                      variant="outlined"
                      margin="dense"
                      size="small"
                      fullWidth={true}
                      InputLabelProps={{
                        shrink: true
                      }}
                      {...register("sellerAccountNumber", {
                        required: requiredField,
                        onChange: (e) => {
                          setProposal({ ...proposal, sellerAccountNumber: e.target.value })
                        }
                      })}
                      disabled={isDisabled}
                    />
                  </Grid>
                </Grid>

                <Grid item xs={12} sm={12} md={6} lg={6} xl={3}>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Typography variant="caption" display="block" gutterBottom>
                      Banco
                    </Typography>
                    <TextField
                      id="accountBankID"
                      error={!!errors.sellerAccountBankID}
                      helperText={errors?.sellerAccountBankID?.message}
                      variant="outlined"
                      margin="dense"
                      size="small"
                      fullWidth={true}
                      value={`${watch().sellerAccountBankID}`}
                      select
                      {...register("sellerAccountBankID", {
                        onChange: (e) => {
                          setValue("sellerAccountBankID", e.target.value)
                          setProposal({ ...proposal, sellerAccountBankID: e.target.value })
                        }
                      })}
                      disabled={isDisabled}
                    >
                    {accountBanks.map((accountBank) => (
                      <MenuItem
                        key={accountBank.id}
                        value={accountBank.id}
                        disabled={isDisabled}
                      >
                        {accountBank.febrabanCode + ' - ' + accountBank.name}
                      </MenuItem>
                    ))}
                    </TextField>
                  </Grid>
                </Grid>

                <Grid item xs={12} sm={12} md={6} lg={6} xl={10}>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Typography variant="caption" display="block" gutterBottom>
                      Email *
                    </Typography>
                    <TextField
                      id="sellerEmail"
                      name="sellerEmail"
                      error={!!errors.sellerEmail}
                      helperText={errors?.sellerEmail?.message}
                      placeholder={emailPlaceHolder}
                      value={proposal.sellerEmail}
                      variant="outlined"
                      margin="dense"
                      size="small"
                      fullWidth={true}
                      InputLabelProps={{
                        shrink: true
                      }}
                      inputProps={{
                        maxLength: 100
                      }}
                      {...register("sellerEmail", {
                        required: requiredField,
                        pattern: {
                          value: IsEmailRegex,
                          message: invalidEmail,
                        },
                        onChange: (e) => {
                          setProposal({ ...proposal, sellerEmail: e.target.value })
                        }
                      })}
                      disabled={isDisabled}
                    />
                  </Grid>
                </Grid>

                <Grid item xs={12} sm={12} md={6} lg={6} xl={2}>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Typography variant="caption" display="block" gutterBottom>
                      Celular *
                    </Typography>
                    <TextField
                      id="sellerPhoneNumber"
                      name="sellerPhoneNumber"
                      error={!!errors.sellerPhoneNumber}
                      helperText={errors?.sellerPhoneNumber?.message}
                      placeholder={phoneMaskPlaceholder}
                      variant="outlined"
                      margin="dense"
                      size="small"
                      fullWidth={true}
                      InputLabelProps={{
                        shrink: true
                      }}
                      inputProps={{
                        maxLength: 100
                      }}
                      {...register("sellerPhoneNumber", {
                        required: requiredField,
                        validate: (value) => {
                          const cellPhone = sanatizeStringValue(value);
                          return cellPhone.length === 11 || invalidCelPhoneLength;
                        },
                        onChange: (e) => {
                          const sellerPhoneNumber = createMask({ mask: phoneMask }).resolve(e.target.value)
                          setValue("sellerPhoneNumber", sellerPhoneNumber)
                          setProposal({ ...proposal, sellerPhoneNumber })
                        }
                      })}
                      disabled={isDisabled}
                    />
                  </Grid>
                </Grid>

                <Paper elevation={1} variant="outlined" className={classes.paperWrapper} sx={{ overflowY: 'auto'}}>
                  <Grid container alignItems='center' direction='row' style={{ maxWidth: '800px'}}>
                    <Grid item xs={1} sm={1} md={1} lg={1} xl={1} style={{ paddingRight: '0px'}} >
                      <Avatar sx={{ backgroundColor: '#F5F8FF' }}>
                        <ContentCopyRoundedIcon />
                      </Avatar>
                    </Grid>
                    <Grid item xs={11} sm={11} md={11} lg={11} xl={11}>
                      <div>
                        <Typography variant="caption" marginBottom={0} paddingBottom={0}>
                          <strong>Faça aqui o upload dos seus documentos</strong>
                        </Typography>
                        <div>
                          <Typography variant="caption" marginBottom={0} paddingBottom={0}>
                            Arquivos suportados nos seguintes formatos: PDF, PNG, JPEG e JPG, com tamanho máximo de 10MBs.
                          </Typography>
                        </div>
                      </div>
                    </Grid>
                  </Grid>

                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <FileUpload shouldShowHeader={true} label="Contrato Social e Alterações *" helperText={SOCIAL_CONTRACT_TOOLTIP_HELPER_TEXT} defaultValue={proposal.sellerFileSocialContract} imageUrl={proposal.sellerFileSocialContractURL} name='sellerFileSocialContract' isDisabled={isDisabled} onFileUpload={handleFileUpload} isRequired={true}/>
                  </Grid>

                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <FileUpload label="Certidão Simplificada *" helperText={SIMPLIFIED_CERTIFICATE_TOOLTIP_HELPER_TEXT} defaultValue={proposal.sellerFileSimplifiedCertificate} imageUrl={proposal.sellerFileSimplifiedCertificateURL} name='sellerFileSimplifiedCertificate' isDisabled={isDisabled} onFileUpload={handleFileUpload} isRequired={true}/>
                  </Grid>

                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <FileUpload label="Documentos dos representantes legais *" helperText={DOCUMENT_LEGAL_REPRESENTATIVES_TOOLTIP_HELPER_TEXT} defaultValue={proposal.sellerFileDocumentsOfLegalRepresentatives} imageUrl={proposal.sellerFileDocumentsOfLegalRepresentativesURL} name='sellerFileDocumentsOfLegalRepresentatives' isDisabled={isDisabled} onFileUpload={handleFileUpload} isRequired={true}/>
                  </Grid>

                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <FileUpload label="Procuração atualizada *" helperText={POWER_OF_ATTORNEY_TOOLTIP_HELPER_TEXT} defaultValue={proposal.sellerFilePowerOfAttorney} imageUrl={proposal.sellerFilePowerOfAttorneyURL} name='sellerFilePowerOfAttorney' isDisabled={isDisabled} onFileUpload={handleFileUpload} isRequired={true}/>
                  </Grid>

                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <FileUpload label="Documento do procurador *" helperText={ATTORNEY_DOCUMENT_TOOLTIP_HELPER_TEXT} defaultValue={proposal.sellerFileAttorneysDocument} imageUrl={proposal.sellerFileAttorneysDocumentURL} name='sellerFileAttorneysDocument' isDisabled={isDisabled} onFileUpload={handleFileUpload} isRequired={true}/>
                  </Grid>
                </Paper>
              </>
            )}
          </Grid>

          <Grid container spacing={1}>
            <Button variant="contained" type="submit" sx={{ mt: 1, mb: 1 }}>
              Continuar
            </Button>
            <Button onClick={handleBack} sx={{ mt: 1, mr: 1, ml: 1 }}>
              Voltar
            </Button>
          </Grid>

        </FormProvider>
    </form>
  )
}
