import React, { useState, useEffect, useRef, useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import { Box, Typography, Button } from '@mui/material'
import { useStyles } from './styles'
import emptyBox from 'assets/empty-box.png'

const NoProposals: React.FC = () => {
  const classes = useStyles()
  const history = useHistory();

  return (
    <>
      <Box className={classes.container}>
        <img src={emptyBox} alt="Caixa Vazia" className={classes.emptyBoxLogo}/>
        <Box mt={5}>
          <Typography variant="h4" className={classes.elementSpacing}>Nenhuma Proposta foi cadastrada até o momento.</Typography>
        </Box>

        <Box mt={3}>
          <Typography variant="body1">Siga para a aba "Cadastro" ou clique no botão abaixo para cadastrar sua primeira proposta.</Typography>
        </Box>

        <Box mt={3}>
          <Button variant="contained" color="primary" onClick={() => history.push('/proposals/new')} className={classes.registerButton}>Cadastrar</Button>
        </Box>

      </Box>
    </>
  )
}

export default NoProposals
