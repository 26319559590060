import { makeStyles } from '@mui/styles'
import { Theme } from '@mui/material'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    width: '100%',
    height: '100%',
  },

  paper: {
    background: "#FFFFFF",
    padding: '20px',
    maxHeight: 'calc(100vh - 520px)',
    height: '100%',
    width: '100%'
  },

  formTitle: {
    display: 'flex',
    width: '100%',
    fontSize: 24,
    fontWeight: 'bold',
    alignItems: 'center',
    justifyContent: 'space-between',
    color: theme.palette.primary.main
  },

  tableContainer: {
    height: '500px',
    display: 'flex',
    flexDirection: 'column',
  },

  formTitleLeft: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: 800
  },

  formTitleLeftIcon: {
    marginRight: 5
  },

  formContainer: {
    maxHeight: 'calc(100vh - 200px)',
    overflow: 'overlay',
    padding: '15px 0'
  },

  head: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
  },

  iconHead: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    width: 1,
    minWidth: 1,
    maxWidth: 1,
  },

  actionButton: {
    marginLeft: '10px !important',
  },
  submitButton: {
    marginTop: 20,
    justifyContent: 'flex-end',
  },

  checkBox: {
    marginTop: 3,
    marginBottom: 5
  },

  gridBox: {
    paddingLeft: 5
  },

  gridPaper: {
    padding: '20px 17px 10px 17px',
    marginTop: '20px',
  },

  chipLabel: {
    fontWeight: 'bold',
    color: 'white'
  }

}))

export { useStyles }
