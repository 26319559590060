export const requiredField = 'Campo Obrigatório'

export const invalidEmail = 'Formato de email inválido'

export const invalidDate = 'Data inválida'

export const invalidCpfLength = 'O CPF deve possuir 11 caracteres'

export const invalidCepLength = 'O CEP deve possuir 8 caracteres'

export const invalidCelPhoneLength = 'Número de telefone inválido'
