export interface CommissionsType {
  organizationName: string,
  assessorName: string,
  clientName: string,
  approvedValue: string,
  bank: string,
  contractPaymentDate: string,
  commissionValue: string,
  commissionPaymentDate: string,
}

export const dummyData: CommissionsType[] = [
  {
    organizationName: "XPG Capital - Investimentos Inteligente LTDA",
    assessorName: "Lilian Pereira da Silva",
    clientName: "José Fernando",
    approvedValue: "990000.00",
    bank: "Banco Itaú S.A.",
    contractPaymentDate: "19/04/2022",
    commissionValue: "650.00",
    commissionPaymentDate: "29/10/2020"
  },
  {
    organizationName: "Levante - Investimentos Visionários S/A",
    assessorName: "João Santos",
    clientName: "Ana Souza",
    approvedValue: "960000.00",
    bank: "Banco Itaú S.A.",
    contractPaymentDate: "06/06/2021",
    commissionValue: "4600.00",
    commissionPaymentDate: "29/06/2023"
  },
  {
    organizationName: "Varos Investimentos - Prospere & Cresça",
    assessorName: "Maria Fernandes",
    clientName: "Pedro Almeida",
    approvedValue: "4800000.00",
    bank: "Caixa Econômica Federal",
    contractPaymentDate: "23/04/2020",
    commissionValue: "1400.00",
    commissionPaymentDate: "23/06/2022"
  },
  {
    organizationName: "ABAP - Consultoria Estratégica",
    assessorName: "Carlos Oliveira",
    clientName: "Sandra Lima",
    approvedValue: "3700000.00",
    bank: "Banco Bradesco",
    contractPaymentDate: "23/01/2022",
    commissionValue: "3500.00",
    commissionPaymentDate: "02/11/2021"
  },
  {
    organizationName: "Inovação e Soluções Avançadas",
    assessorName: "Mariana Costa",
    clientName: "Ricardo Santos",
    approvedValue: "4400000.00",
    bank: "Caixa Econômica Federal",
    contractPaymentDate: "07/01/2023",
    commissionValue: "4100.00",
    commissionPaymentDate: "10/09/2022"
  },
  {
    organizationName: "Companhia de Sucesso Global",
    assessorName: "Rafael Souza",
    clientName: "Beatriz Oliveira",
    approvedValue: "3100000.00",
    bank: "Caixa Econômica Federal",
    contractPaymentDate: "21/04/2020",
    commissionValue: "700.00",
    commissionPaymentDate: "23/07/2021"
  },
  {
    organizationName: "Black Rock - Companhia de invetimento Global",
    assessorName: "Fernanda Lima",
    clientName: "Lucas Alves",
    approvedValue: "385000.00",
    bank: "Banco Itaú S.A.",
    contractPaymentDate: "08/11/2022",
    commissionValue: "2900.00",
    commissionPaymentDate: "31/01/2022"
  },
  {
    organizationName: "Corretora de Investimentos Alpha",
    assessorName: "Ricardo Oliveira",
    clientName: "Carolina Fernandes",
    approvedValue: "1850000.00",
    bank: "Banco Inter",
    contractPaymentDate: "18/01/2023",
    commissionValue: "4200.00",
    commissionPaymentDate: "29/10/2020"
  },
  {
    organizationName: "Contajá Assessoria Premium Financeira",
    assessorName: "Juliana Fernandes",
    clientName: "Guilherme Silva",
    approvedValue: "555000.00",
    bank: "Banco Itaú S.A.",
    contractPaymentDate: "20/05/2023",
    commissionValue: "1500.00",
    commissionPaymentDate: "15/12/2020"
  },
  {
    organizationName: "IKGB - Escritório Especializado em Finanças",
    assessorName: "Pedro Alves",
    clientName: "Isabela Costa",
    approvedValue: "2100000.00",
    bank: "Banco Inter",
    contractPaymentDate: "02/03/2023",
    commissionValue: "1300.00",
    commissionPaymentDate: "06/12/2022"
  },
  {
    organizationName: "Dynamics - Empreendimentos Futuros Ltda",
    assessorName: "Bruna Oliveira",
    clientName: "Rafaela Lima",
    approvedValue: "5000000.00",
    bank: "Banco Itaú S.A.",
    contractPaymentDate: "08/02/2020",
    commissionValue: "5000.00",
    commissionPaymentDate: "21/10/2022"
  },
  {
    organizationName: "Leopard Conexão Financeira S/A",
    assessorName: "Anderson Souza",
    clientName: "Fernando Santos",
    approvedValue: "2000000.00",
    bank: "Banco Itaú S.A.",
    contractPaymentDate: "22/02/2022",
    commissionValue: "2000.00",
    commissionPaymentDate: "10/02/2021"
  },
  {
    organizationName: "Fundo NVME Investimentos e Lucros Consultoria",
    assessorName: "Mariana Silva",
    clientName: "Mariana Almeida",
    approvedValue: "210000.00",
    bank: "Caixa Econômica Federal",
    contractPaymentDate: "15/03/2020",
    commissionValue: "2100.00",
    commissionPaymentDate: "29/04/2023"
  },
  {
    organizationName: "Soluções Financeiras Maximus",
    assessorName: "Renato Lima",
    clientName: "Rodrigo Souza",
    approvedValue: "860000.00",
    bank: "Banco Bradesco",
    contractPaymentDate: "02/03/2022",
    commissionValue: "1500.00",
    commissionPaymentDate: "18/08/2021"
  },
  {
    organizationName: "HSI - Investimentos e Lucros Consultoria",
    assessorName: "Luciana Fernandes",
    clientName: "Letícia Oliveira",
    approvedValue: "4100000.00",
    bank: "Caixa Econômica Federal",
    contractPaymentDate: "03/12/2020",
    commissionValue: "1500.00",
    commissionPaymentDate: "11/06/2023"
  },
  {
    organizationName: "IMOEX - Evolução Financeira e Negócios",
    assessorName: "Roberto Alves",
    clientName: "Roberto Fernandes",
    approvedValue: "1500000.00",
    bank: "Caixa Econômica Federal",
    contractPaymentDate: "01/12/2020",
    commissionValue: "2200.00",
    commissionPaymentDate: "17/04/2020"
  },
  {
    organizationName: "BVSP Consultoria Futuro Promissor",
    assessorName: "Tatiana Costa",
    clientName: "Amanda Silva",
    approvedValue: "1600000.00",
    bank: "Banco Itaú S.A.",
    contractPaymentDate: "10/02/2022",
    commissionValue: "1000.00",
    commissionPaymentDate: "25/10/2020"
  },
  {
    organizationName: "Core Reactor Conexão Financeira S/A",
    assessorName: "Leonardo Pereira",
    clientName: "Felipe Costa",
    approvedValue: "870000.00",
    bank: "Banco Inter",
    contractPaymentDate: "11/08/2020",
    commissionValue: "1600.00",
    commissionPaymentDate: "03/12/2021"
  },
  {
    organizationName: "Corsair Investimentos e Lucros Consultoria",
    assessorName: "Letícia Santos",
    clientName: "Tatiane Alves",
    approvedValue: "295495.49",
    bank: "Banco Itaú S.A.",
    contractPaymentDate: "13/07/2022",
    commissionValue: "3274.75",
    commissionPaymentDate: "06/01/2021"
  },
  {
    organizationName: "Soluções Financeiras Maximus",
    assessorName: "Rodrigo Fernandes",
    clientName: "Leonardo Lima",
    approvedValue: "3400000.00",
    bank: "Banco Inter",
    contractPaymentDate: "08/07/2022",
    commissionValue: "1200.00",
    commissionPaymentDate: "02/02/2021"
  }
]
