import { Box, Step, StepContent, StepLabel, Stepper } from "@mui/material"
import { useState } from "react"
import { BuyerForm } from "./buyer-form";
import { SubmitProposal } from "../proposal-data/submit-proposal";
import { ProposalDocumentationFormStep } from "./form-step";
import { SellerForm } from "./seller-form";
import { PropertyForm } from "./property-form";
import { ProposalPhase } from "hooks/enums/ProposalStatus";

type Props = {
  canEdit: boolean
}

const proposalFormSteps = [
  { label: 'Dados do Comprador' },
  { label: 'Dados do Vendedor' },
  { label: 'Dados do Imóvel' },
];

export const DocumentationDataForm = ({ canEdit }: Props) => {
  const [proposalFormStep, setProposalFormStep] = useState<ProposalDocumentationFormStep>(ProposalDocumentationFormStep.BUYER_DATA);

  function getFormStep(formStep: ProposalDocumentationFormStep) {
    switch (formStep) {
      case ProposalDocumentationFormStep.BUYER_DATA:
        return <BuyerForm isDisabled={!canEdit} setProposalFormStep={setProposalFormStep}/>
      case ProposalDocumentationFormStep.SELLER_DATA:
        return <SellerForm isDisabled={!canEdit} setProposalFormStep={setProposalFormStep}/>
      case ProposalDocumentationFormStep.PROPERTY_DATA:
        return <PropertyForm isDisabled={!canEdit} setProposalFormStep={setProposalFormStep}/>
    }
  }

  return (
    <Box sx={{ maxWidth: 1200, mx: 'auto', mt: 5, mb: 5 }}>
      <Stepper activeStep={proposalFormStep} orientation="vertical">
        {proposalFormSteps.map((step, index) => (
          <Step key={step.label}>
            <StepLabel>
              {step.label}
            </StepLabel>
            <StepContent>
              {getFormStep(proposalFormStep)}
            </StepContent>
          </Step>
        ))}
      </Stepper>
      {proposalFormStep === ProposalDocumentationFormStep.COMPLETED && (
        <SubmitProposal redirectToPhase={ProposalPhase.PROPERTY_EVALUATION} />
      )}
    </Box>
  )
}

