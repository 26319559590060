import { Button, FormControl, Grid, TextField, Typography } from "@mui/material";
import { ProposalContext } from "contexts/proposal-context"
import { IProposalDTO } from "data/dtos/i-proposal-dto";
import { useContext } from "react"
import { useForm } from "react-hook-form";
import { useStyles } from "../../styles";
import { currencyMask, realEstatePricePlaceHolder } from "utils/utils";
import { ProposalDisbursementFormStep } from "./form-step";
import { ProposalStatus } from "hooks/enums/ProposalStatus";

type Props = {
  isDisabled: boolean
  setProposalFormStep: React.Dispatch<React.SetStateAction<ProposalDisbursementFormStep>>
}

export const ScheduledPaymentDataForm = ({ isDisabled, setProposalFormStep }: Props) => {
  const { proposal, setProposal } = useContext(ProposalContext);
  const classes = useStyles()

  const saveData = (spousePersonalData: IProposalDTO) => {
    setProposal({...proposal, ...spousePersonalData, status: ProposalStatus.AWAITING_PAYMENT})
    setProposalFormStep(ProposalDisbursementFormStep.PAYMENT_STATUS_DATA)
  }

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<IProposalDTO>({
    defaultValues: {
      scheduledPaymentDate: proposal.scheduledPaymentDate || '',
    },
    reValidateMode: 'onChange'
  })

  return (
    <form onSubmit={handleSubmit(saveData)}>
      <FormControl >
        <Grid container spacing={1} className={classes.formContainer}>

          <Grid item xs={12} sm={12} md={12} lg={12} xl={12} mt={2} paddingTop={2}>
            <Typography variant="caption" display="block" gutterBottom >
              Data do desembolso *
            </Typography>
            <TextField
              id="scheduledPaymentDate"
              name="scheduledPaymentDate"
              error={!!errors.scheduledPaymentDate}
              helperText={errors?.scheduledPaymentDate?.message}
              placeholder={realEstatePricePlaceHolder}
              variant="outlined"
              margin="dense"
              size="small"
              fullWidth={true}
              type="date"
              InputLabelProps={{
                shrink: true
              }}
              inputProps={{
                maxLength: 60
              }}
              {...register("scheduledPaymentDate", {
                required: "Campo Obrigatório",
                onChange: (e) => {
                  setValue("scheduledPaymentDate", e.target.value);
                },
              })}
              disabled={isDisabled}
            />
          </Grid>

          <Grid container spacing={1}>
            <Button
              variant="contained"
              type="submit"
              sx={{ mt: 1, mb: 1 }}
            >
              Continuar
            </Button>
          </Grid>
        </Grid>
      </FormControl>
    </form>
  )
}
