import { Button, Checkbox, FormControl, FormControlLabel, FormGroup, Grid, MenuItem, Paper, TextField, Typography, CircularProgress, Box, Alert } from "@mui/material";
import { ProposalContext } from "contexts/proposal-context"
import { IProposalDTO } from "data/dtos/i-proposal-dto";
import { useCallback, useContext, useEffect, useState } from "react"
import { Controller, useForm } from "react-hook-form";
import { currencyMask, realEstatePricePlaceHolder, sanatizeStringValue, sanatizeMoneyValue, removeEmptyProperties } from "utils/utils";
import { useStyles } from "../../styles";
import { PaymentType } from "hooks/enums/PaymentType";
import { useHistory } from "react-router-dom";
import api from "services/api";
import DefaultModal, { DialogType } from "components/modal";
import { useProfile } from "hooks/use-profile";
import { ProfileType } from "hooks/enums/ProfileType";
import { ProposalPhase } from "hooks/enums/ProposalStatus";

type Props = {
  setShouldUpdateProposal?: React.Dispatch<React.SetStateAction<boolean>>
  redirectToPhase?: ProposalPhase
}

export const SubmitProposal = ({setShouldUpdateProposal, redirectToPhase}: Props) => {
  const [isLoading, setIsLoading] = useState(true);
  const [mainError, setMainError] = useState('')
  const { proposal, setProposalPhase, setCurrentProposalPhase } = useContext(ProposalContext);
  const [isCreatedModalOpen, setIsCreatedModalOpen] = useState(false)
  const [isUpdatedModalOpen, setIsUpdatedModalOpen] = useState(false)
  const { selectedProfile } = useProfile();
  const isClient = selectedProfile === ProfileType.CLIENTE
  const isRegisteredUser = selectedProfile !== ""
  const history = useHistory()
  const classes = useStyles()
  let proposalID = proposal.id
  const proposalUrl = isRegisteredUser ? '/proposals/' : '/proposals/public/'

  const getRedirectLink = () => {
    if (!isRegisteredUser) {
      return 'https://www.finteto.com'
    }
    return isClient ? '/follow-up' : '/performance'
  }

  const handleConfirmModal = () => {
    if (setShouldUpdateProposal) {
      setShouldUpdateProposal(false)
    }
    setIsCreatedModalOpen(false)
    setIsUpdatedModalOpen(false)

    const redirectLink = getRedirectLink()

    redirectLink.startsWith('http')
      ? window.location.href = redirectLink
      : history.push(redirectLink)
  }

  const handleCloseUpdateModel = () => {
    if(setShouldUpdateProposal) {
      setShouldUpdateProposal(false)
    }
    if(redirectToPhase) {
      setProposalPhase(redirectToPhase)
      setCurrentProposalPhase(redirectToPhase)
    }
    setIsUpdatedModalOpen(false)
  }

  const updateFiles = useCallback(async(proposalID?: string) => {
    setIsLoading(true)

    const formData = new FormData();

    formData.append("personFileCNH", proposal.personFileCNH);
    formData.append("personFileRGFront", proposal.personFileRGFront);
    formData.append("personFileRGBack", proposal.personFileRGBack);
    formData.append("personFileCPF", proposal.personFileCPF);
    formData.append("personFileCertificateCivilStatus", proposal.personFileCertificateCivilStatus);
    formData.append("personFileAddressVerification", proposal.personFileAddressVerification);
    formData.append("personFileLatestPayStub", proposal.personFileLatestPayStub);
    formData.append("personFilePenultimatePayStub", proposal.personFilePenultimatePayStub);
    formData.append("personFileIncomeTax", proposal.personFileIncomeTax);
    formData.append("personFileIncomeTaxReceipt", proposal.personFileIncomeTaxReceipt);

    formData.append("personFileExtractFGTS", proposal.personFileExtractFGTS);
    formData.append("personFileAuthorizationFGTS", proposal.personFileAuthorizationFGTS);

    formData.append("sellerFileRGFront", proposal.sellerFileRGFront);
    formData.append("sellerFileRGBack", proposal.sellerFileRGBack);
    formData.append("sellerFileCertificateCivilStatus", proposal.sellerFileCertificateCivilStatus);
    formData.append("sellerFileAddressVerification", proposal.sellerFileAddressVerification);
    formData.append("sellerFileSocialContract", proposal.sellerFileSocialContract);
    formData.append("sellerFileSimplifiedCertificate", proposal.sellerFileSimplifiedCertificate);
    formData.append("sellerFileDocumentsOfLegalRepresentatives", proposal.sellerFileDocumentsOfLegalRepresentatives);
    formData.append("sellerFilePowerOfAttorney", proposal.sellerFilePowerOfAttorney);
    formData.append("sellerFileAttorneysDocument", proposal.sellerFileAttorneysDocument);


    formData.append("realEstateFileUpdateRegistration", proposal.realEstateFileUpdateRegistration);
    formData.append("realEstateFileIPTU", proposal.realEstateFileIPTU);
    formData.append("realEstateFileOccupancyPermit", proposal.realEstateFileOccupancyPermit);
    formData.append("realEstateFileSupervisorCREACAU", proposal.realEstateFileSupervisorCREACAU);
    formData.append("realEstateFileARTRRT", proposal.realEstateFileARTRRT);
    formData.append("realEstateFileAppraisalReport", proposal.realEstateFileAppraisalReport);

    if(proposalID) {
      api
      .put(`/proposals/files/${proposalID}`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .catch(error => {
        setMainError('Erro ao atualizar os arquivos da sua proposta, por favor tente novamente mais tarde')
        return error.response.data.data
      }).finally(() => {
        setIsLoading(false)
      })
    }
  }, [])

  useEffect(() => {
    setIsLoading(true)
    let payLoad: IProposalDTO = {
      // Person Personal data
      id: proposal.id,
      personCompleteName: proposal.personCompleteName,
      personBirthDate: proposal.personBirthDate,
      personEmail: proposal.personEmail,

      personCpf: sanatizeStringValue(proposal.personCpf),
      personDocumentType: proposal.personDocumentType,
      personDocumentNumber: proposal.personDocumentNumber,
      personDocumentIssuingAgency: proposal.personDocumentIssuingAgency,
      personPhoneNumber: sanatizeStringValue(proposal.personPhoneNumber),

      personNationality: proposal.personNationality,
      personMaritalStatus: proposal.personMaritalStatus,
      personCurrentHouseType: proposal.personCurrentHouseType,
      personBankAccounts: proposal.personBankAccounts,


      // Person Professional data
      personProfession: proposal.personProfession,
      personJobPosition: proposal.personJobPosition,
      personCompanyName: proposal.personCompanyName,

      personHireDate: proposal.personHireDate,
      personGrossIncome: sanatizeMoneyValue(proposal.personGrossIncome),
      personNetIncome: sanatizeMoneyValue(proposal.personNetIncome),
      personIncomeType: proposal.personIncomeType,


      // Spouse personal data
      spouseCompleteName: proposal.spouseCompleteName,
      spouseEmail: proposal.spouseEmail,
      spouseBirthDate: proposal.spouseBirthDate,
      spouseCpf: sanatizeStringValue(proposal.spouseCpf),

      spouseDocumentType: proposal.spouseDocumentType,
      spouseDocumentNumber: proposal.spouseDocumentIssuingAgency,
      spouseDocumentIssuingAgency: proposal.spouseDocumentIssuingAgency,
      spousePhoneNumber: proposal.spousePhoneNumber,
      spouseNationality: proposal.spouseNationality,


      // Spouse professional data
      spouseProfession: proposal.spouseProfession,
      spouseJobPosition: proposal.spouseJobPosition,
      spouseCompanyName: proposal.spouseCompanyName,

      spouseHireDate: proposal.spouseHireDate,
      spouseGrossIncome: sanatizeMoneyValue(proposal.spouseGrossIncome),
      spouseNetIncome: sanatizeMoneyValue(proposal.spouseNetIncome),
      spouseIncomeType: proposal.spouseIncomeType,


      // RealEstate data
      realEstateAddress: proposal.realEstateAddress,
      realEstateComplement: proposal.realEstateComplement,
      realEstateNumber: proposal.realEstateNumber,
      realEstateNeighborhood: proposal.realEstateNeighborhood,
      realEstateZipCode: sanatizeStringValue(proposal.realEstateZipCode),

      realEstateStateID: proposal.realEstateStateID,
      realEstateCityID: proposal.realEstateCityID,
      realEstateType: proposal.realEstateType,
      realEstateSituation: proposal.realEstateSituation,
      realEstatePrice: sanatizeMoneyValue(proposal.realEstatePrice),


      // Financing data
      financingValue: sanatizeMoneyValue(proposal.financingValue),
      financingPayType: proposal.financingPayType,
      financingSpouse: proposal.financingSpouse,


      // Public Banks data
      publicBanksShouldSend: proposal.publicBanksShouldSend || false,
    }

    payLoad = removeEmptyProperties(payLoad)

    if(proposalID) {
      payLoad = {
        ...payLoad,

        // Seller data
        sellerTradeName: proposal.sellerTradeName,
        sellerCNPJ: proposal.sellerCNPJ ? sanatizeStringValue(proposal.sellerCNPJ) : undefined,
        sellerCompleteName: proposal.sellerCompleteName,
        sellerAccountAgency: proposal.sellerAccountAgency,
        sellerAccountNumber: proposal.sellerAccountNumber,
        sellerAccountBankID: proposal.sellerAccountBankID,
        sellerEmail: proposal.sellerEmail,
        sellerPhoneNumber: proposal.sellerPhoneNumber ? sanatizeStringValue(proposal.sellerPhoneNumber) : undefined,

        status: proposal.status ? proposal.status : undefined,
        scheduledPaymentDate: proposal.scheduledPaymentDate,

        realEstateAppraisalValue: proposal.realEstateAppraisalValue ? sanatizeMoneyValue(proposal.realEstateAppraisalValue) : undefined,
      }

      payLoad = removeEmptyProperties(payLoad)

      api
        .put(`/proposals/${proposalID}`, payLoad)
        // .then(history.push('/performance'))
        .catch(error => {
          setMainError('Erro ao atualizar sua proposta, por favor tente novamente mais tarde')
          return error.response.data.data
        }).finally(() => {
          setIsLoading(false)
        })
        updateFiles(proposalID)

      setIsUpdatedModalOpen(true)
    } else {
      api
        .post(proposalUrl, payLoad)
        .then(proposalResponse => {
          updateFiles(proposalResponse.data.id)
        })
        .catch(error => {
          setMainError('Erro ao gravar sua proposta, por favor tente novamente mais tarde')
          return error.response.data.data
        }).finally(() => {
          setIsLoading(false)
        })

      setIsCreatedModalOpen(true)
    }
  }, [proposal])

  if(mainError !== '') {
    return <Alert severity="error" className={classes.alert}>{mainError}</Alert>
  }

  return (
    <>
      { isLoading && 
        <CircularProgress color="primary" className={classes.spinner}/>
      }

      <DefaultModal
        isOpen={isCreatedModalOpen}
        title={'Proposta cadastrada com sucesso!'}
        bodyContent={"Aguarde nossa análise e aprovação para seguir para a fase de análise de documentos. Um Officer Finteto entrará em contato."}
        onCloseModal={() => handleConfirmModal()}
        type={DialogType.CONFIRMATION}
      />

      <DefaultModal
        isOpen={isUpdatedModalOpen}
        title={'Proposta atualizada.'}
        bodyContent={"Proposta atualizada com sucesso."}
        onConfirmModal={() => handleConfirmModal()}
        onCloseModal={() => handleCloseUpdateModel()}
        type={DialogType.ACTION}
        confirmModalValue={isClient ? 'Seguir para Acompanhamento' : 'Seguir para Performance'}
        cancelModalValue='Continuar editando'
      />
    </>

  )
}
