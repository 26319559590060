export enum ProposalContractingFormStep {
  AWAITING_CLIENT_SIGNED = 0,
  AWAITING_SELLER_SIGNED = 1,
  AWAITING_BANK_SIGNED = 2,
  AWAITING_ITBI_GENERATED = 3,
  AWAITING_ITBI_PAID = 4,
  AWAITING_NOTARY_EXPENSES_PAID = 5,
  AWAITING_DOCUMENTATION_DELIVERED = 6,
  COMPLETED = 7,
}
