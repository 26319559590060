import { makeStyles } from '@mui/styles'
import { Theme } from '@mui/material'

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    textAlign: 'center',
    maxHeight: 'calc(100vh - 320px)',
    margin: 'auto',
    justifyContent: 'center',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  spinner: {
    marginTop: '50px',
  },
}))

export { useStyles }
