export const cpfMask = '000.000.000-00'
export const cpfMaskPlaceholder = '000.000.000-00'

export const cnpjMask = '00.000.000/0000-00'
export const cnpjMaskPlaceholder = '00.000.000/0000-00'

export const phoneMask = '(00) 00000-0000'
export const phoneMaskPlaceholder = '(00) 00000-0000'

export const zipCodeMask = '00000-000'
export const zipCodeMaskPlaceHolder = '00000-000'

export const incomeMaskPlaceHolder = 'R$ 10.000,00'
export const realEstatePricePlaceHolder = 'R$ 3.000.000,00'

export const legalNamePlaceHolder = 'Razão Social'
export const tradeNamePlaceHolder = 'Nome Fantasia'
export const addressPlaceHolder = 'Rua Manoel Gonçalves'
export const ComplementPlaceHolder = 'Apartamento 502'
export const numberPlaceHolder = '999'
export const neighborhoodPlaceHolder = 'Jardim Glória'
export const emailPlaceHolder = 'mail@gmail.com'

export const accountAgencyPlaceHolder = '0428'
export const accountNumberPlaceHolder = '95486-8'

export const completeNamePlaceHolder = 'José da Silva Fagundes'
export const nationalityPlaceHolder = 'Brasileiro'
export const bankNamePlaceHolder = 'Bradesco, Itaú, Banco Inter, Caixa Econômica Federal'

export const professionPlaceHolder = 'Médico'
export const jobPositionPlaceHolder = 'Cirurgião Cardíaco'
export const identityMask = 'Somente números'
export const identityIssuer = 'SSP MG'

export const percentagePlaceHolder = '1,25'
export const percentageMask = '0,00'

export const currencyMask = (input: string) => {
  let value = input;
  value = value.replace(/\D/g, "")
  value = value.replace(/(\d)(\d{2})$/, "$1,$2")
  value = value.replace(/(?=(\d{3})+(\D))\B/g, ".")
  return `R$ ${value}`
}

export const sanatizeStringValue = (value: string) => {
  return value?.replace(/[.()\/ -]/g, '') || '';
}

export const sanatizeMoneyValue = (value: string): string | null => {
  if(!value) {
    return value
  }
  const regex = /\d{1,3}(?:\.\d{3})*(?:,\d{2})?/;
  const number = value?.match(regex)[0].replace(/\./g, '').replace(',', '.');
  return number;
}

export const removeEmptyProperties = <T extends Record<string, any>>(obj: T): Partial<T> => {
  return Object.keys(obj).reduce((acc, key) => {
    if (obj[key as keyof T] !== '' && obj[key as keyof T] !== "null") {
      acc[key as keyof T] = obj[key as keyof T];
    }
    return acc;
  }, {} as Partial<T>);
};

export const capitalizeFirstName = (str: string): string => {
  const firstName = str.split(' ')
  return firstName[0].charAt(0).toUpperCase() + firstName[0].slice(1)
}

export const formatCurrency = (value: string): string => {
  const convertedAmount = Number.parseInt(value)
  return isNaN(convertedAmount) ? "N/A" : convertedAmount.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
}

export const formatDate = (date: Date | null): string => {
  if (!date) return 'N/A';

  const formattedDate = new Date(date);
  const day = formattedDate.getDate();
  const month = formattedDate.getMonth() + 1;
  const year = formattedDate.getFullYear();

  const formattedDay = day < 10 ? '0' + day : day;
  const formattedMonth = month < 10 ? '0' + month : month;

  return `${formattedDay}/${formattedMonth}/${year}`;
}
