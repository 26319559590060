import { Box, Step, StepContent, StepLabel, Stepper } from "@mui/material"
import { FinancingForm } from "./financing-form"
import { ProposalDataFormStep } from "./form-step"
import { PersonPersonalData } from "./person-personal-form"
import { PersonProfessionalData } from "./person-professional-form"
import { PublicBanksForm } from "./public-banks-form"
import { RealStateForm } from "./real-state-form"
import { SpousePersonalData } from "./spouse-personal-form"
import { SpouseProfessionalData } from "./spouse-professional-form"
import { SubmitProposal } from "./submit-proposal"
import { useContext, useState } from "react"
import { ProposalContext } from "contexts/proposal-context"
import { MaritalStatus } from "hooks/enums/MaritalStatus"
import { ProposalPhase } from "hooks/enums/ProposalStatus"
import { useProfile } from "hooks/use-profile"

type Props = {
  canEdit: boolean
  isExistingProposal: boolean
}

export const ProposalDataForm = ({ canEdit, isExistingProposal }: Props) => {
  const [proposalFormStep, setProposalFormStep] = useState<ProposalDataFormStep>(ProposalDataFormStep.PERSON_PERSONAL_DATA);
  const [shouldResetForm, setShouldResetForm] = useState(isExistingProposal)
  const { selectedProfile } = useProfile()
  const isRegisteredUser = selectedProfile !== ""
  const { proposal } = useContext(ProposalContext);

  const proposalFormSteps = [
    { label: 'Dados Pessoais' },
    { label: 'Dados Profissionais' },
    { label: 'Dados Pessoais - Cônjuge' },
    { label: 'Dados Profissionais - Cônjuge' },
    { label: 'Dados do Imóvel' },
    { label: 'Dados do Financiamento' },
    { label: isRegisteredUser ? 'Documentos Pessoais' : 'Envio'},
  ];

  let disabledSteps: number[] = []
  if(proposal.personMaritalStatus !== MaritalStatus.MARRIED) {
    disabledSteps = [2, 3]
  }

  function getFormStep(formStep: ProposalDataFormStep) {
    switch (formStep) {
      case ProposalDataFormStep.PERSON_PERSONAL_DATA:
        return <PersonPersonalData isDisabled={!canEdit} setProposalFormStep={setProposalFormStep} shouldResetForm={shouldResetForm}/>
      case ProposalDataFormStep.PERSON_PROFESSIONAL_DATA:
        return <PersonProfessionalData isDisabled={!canEdit} setProposalFormStep={setProposalFormStep} setShouldResetForm={setShouldResetForm}/>
      case ProposalDataFormStep.SPOUSE_PERSONAL_DATA:
        return <SpousePersonalData isDisabled={!canEdit} setProposalFormStep={setProposalFormStep}/>
      case ProposalDataFormStep.SPOUSE_PROFESSIONAL_DATA:
        return <SpouseProfessionalData isDisabled={!canEdit} setProposalFormStep={setProposalFormStep}/>
      case ProposalDataFormStep.REAL_STATE_DATA:
        return <RealStateForm isDisabled={!canEdit} setProposalFormStep={setProposalFormStep}/>
      case ProposalDataFormStep.FINANCING_DATA:
        return <FinancingForm isDisabled={!canEdit} setProposalFormStep={setProposalFormStep}/>
      case ProposalDataFormStep.PUBLIC_BANK_DATA:
        return <PublicBanksForm isDisabled={!canEdit} setProposalFormStep={setProposalFormStep}/>
    }
  }

  return (
    <Box sx={{ maxWidth: 1200, mx: 'auto', mt: 5, mb: 5 }}>
      <Stepper activeStep={proposalFormStep} orientation="vertical">
        {proposalFormSteps.map((step, index) => (
          <Step
            key={step.label}
            sx={{
              '& .MuiStepLabel-root .Mui-completed': {
                color: disabledSteps.includes(index) ? '#A8A8A8' : '',
              },
            }}
          >
            <StepLabel>
              {step.label}
            </StepLabel>
            <StepContent>
              {getFormStep(proposalFormStep)}
            </StepContent>
          </Step>
        ))}
      </Stepper>
      {proposalFormStep === ProposalDataFormStep.COMPLETED && (
        <SubmitProposal redirectToPhase={ProposalPhase.DOCUMENTATION} />
      )}
    </Box>
  )
}

