import React, { useState, useRef, useCallback, useEffect, useContext, ChangeEvent } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { Box, Paper, Typography, StepContent, Button, CircularProgress, Stack, StepConnector, stepConnectorClasses, StepIconProps, TextField, Grid, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, RadioGroup, FormControlLabel, Radio, Alert } from '@mui/material'
import { FormHeader, FormAlert, FormHeaderButton } from 'components'
import ListIcon from '@mui/icons-material/List'
import { useStyles } from "./styles"
import api from 'services/api'
import { styled } from '@mui/material/styles';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import { ProposalDetailsResponse } from '../details/types'
import { ProposalContext } from 'contexts/proposal-context'
import { createMask } from 'imask'
import { cnpjMask, cpfMask, currencyMask, phoneMask, zipCodeMask } from 'utils/utils'
import HandshakeIcon from '@mui/icons-material/Handshake';
import { ProfileType } from 'hooks/enums/ProfileType'
import { useProfile } from 'hooks/use-profile'
import { ProposalDataForm } from './phases/proposal-data'
import { DocumentationDataForm } from './phases/documentation'
import { ProposalPhase, ProposalStatus, showProposalStatus } from 'hooks/enums/ProposalStatus'
import DeclineProposalDialog from './decline-proposal-dialog'
import { SubmitProposal } from './phases/proposal-data/submit-proposal'
import PriceCheckIcon from '@mui/icons-material/PriceCheck'
import MapsHomeWorkIcon from '@mui/icons-material/MapsHomeWork'
import FeedIcon from '@mui/icons-material/Feed'
import DocumentScannerIcon from '@mui/icons-material/DocumentScanner'
import ApproveProposalDialog from './approve-proposal-dialog'
import AddPendingToProposalDialog from './add-pending-to-proposal-dialog'
import { showProposalPendingReason } from 'hooks/enums/ProposalPendingReason'
import { PropertyEvaluationForm } from './phases/property-evaluation'
import { ContractingForm } from './phases/contracting'
import { DisbursementDataForm } from './phases/disbursement'

interface Props {
  title?: string
}

interface IRouteParams {
  proposalID?: string
}

const ProposalForm: React.FC<Props> = ({ title }) => {
  const params = useParams<IRouteParams>()
  const proposalID = params.proposalID

  const { selectedProfile } = useProfile();
  const { currentProposalPhase, setCurrentProposalPhase, proposalPhase, setProposalPhase, setProposal, resetProposal } = useContext(ProposalContext);
  const [hasPendency, setHasPendency] = useState(false)

  const isEditing = !!proposalID
  const isFintetoUser = selectedProfile === ProfileType.OFFICER || selectedProfile === ProfileType.ADMIN
  const isClient = selectedProfile === ProfileType.CLIENTE

  const [isLoading, setIsLoading] = useState(false);
  const [mainError, setMainError] = useState<string>('')
  const [proposalStatus, setProposalStatus] = useState<ProposalStatus>(ProposalStatus.CREATED)
  const [shouldUpdateProposal, setShouldUpdateProposal] = useState<boolean>(false)

  const isOldPhase = currentProposalPhase < proposalPhase

  const canEdit = isEditing && (isFintetoUser || hasPendency) && !isOldPhase

  const [isAddPendingToProposalDialogOpen, setIsAddPendingToProposalDialogOpen] = useState(false);
  const [isDeclineProposalDialogOpen, setIsDeclineProposalDialogOpen] = useState(false);
  const [isApproveProposalDialogOpen, setIsApproveProposalDialogOpen] = useState(false);

  const FormHeaderButtons: FormHeaderButton[] = []

  if(proposalPhase !== ProposalPhase.REFUSED && isEditing) {
    FormHeaderButtons.push(
      {
        text: isClient ? 'Anular Proposta' : 'Recusar',
        color: 'error',
        onClick() {
          setIsDeclineProposalDialogOpen(true)
        }
      }
    )
  }

  if(proposalPhase !== ProposalPhase.REFUSED && isFintetoUser && isEditing) {
    FormHeaderButtons.push(
      {
        text: 'Adicionar Pendência',
        color: 'primary',
        onClick() {
          setIsAddPendingToProposalDialogOpen(true)
        }
      },
    )
  }

  if(proposalPhase === ProposalPhase.PROPOSAL_DATA && isFintetoUser && isEditing) {
    FormHeaderButtons.push(
      {
        text: 'Aprovar Proposta',
        color: 'primary',
        onClick() {
          setIsApproveProposalDialogOpen(true)
        }
      }
    )
  }

  if(proposalPhase !== ProposalPhase.REFUSED && isEditing) {
    FormHeaderButtons.push(
      {
        text: 'Salvar',
        color: 'primary',
        onClick() {
          setShouldUpdateProposal(true)
        }
      }
    )
  }

  const classes = useStyles()

  const proposalDetailSteps = [
    'Dados da Proposta',
    'Documentação',
    'Avaliação do Imóvel',
    'Contratação',
    'Desembolso',
  ];

  useEffect(() => {
    async function loadData() {
      setIsLoading(true)
      try {
        const { data: recoveredProposal } = await api.get<ProposalDetailsResponse>(`/proposals/${proposalID}`)

        setProposal({
          id: recoveredProposal.id,
          personCompleteName: recoveredProposal.personCompleteName,
          personEmail: recoveredProposal.personEmail,
          personBirthDate: new Date(recoveredProposal.personBirthDate).toISOString().split('T')[0],

          personCpf: createMask({ mask: cpfMask }).resolve(recoveredProposal.personCpf),
          personDocumentType: String(recoveredProposal.personDocumentType),
          personDocumentNumber: recoveredProposal.personDocumentNumber,
          personDocumentIssuingAgency: recoveredProposal.personDocumentIssuingAgency,
          personPhoneNumber: createMask({ mask: phoneMask }).resolve(recoveredProposal.personPhoneNumber),

          personNationality: recoveredProposal.personNationality,
          personMaritalStatus: recoveredProposal.personMaritalStatus,
          personCurrentHouseType: recoveredProposal.personCurrentHouseType,
          personBankAccounts: recoveredProposal.personBankAccounts,


          personProfession: recoveredProposal.personProfession,
          personJobPosition: recoveredProposal.personJobPosition,
          personCompanyName: recoveredProposal.personCompanyName,

          personHireDate: new Date(recoveredProposal.personHireDate).toISOString().split('T')[0],
          personGrossIncome: currencyMask(recoveredProposal.personGrossIncome),
          personNetIncome: currencyMask(recoveredProposal.personNetIncome),
          personIncomeType: recoveredProposal.personIncomeType,


          spouseCompleteName: recoveredProposal.spouseCompleteName,
          spouseEmail: recoveredProposal.spouseEmail,
          spouseBirthDate: recoveredProposal.spouseBirthDate ? new Date(recoveredProposal.spouseBirthDate).toISOString().split('T')[0] : null,
          spouseCpf: recoveredProposal.spouseCpf ? createMask({ mask: cpfMask }).resolve(recoveredProposal.spouseCpf) : null,

          spouseDocumentType: String(recoveredProposal.spouseDocumentType),
          spouseDocumentNumber: recoveredProposal.spouseDocumentNumber,
          spouseDocumentIssuingAgency: recoveredProposal.spouseDocumentIssuingAgency,
          spousePhoneNumber: recoveredProposal.spousePhoneNumber ? createMask({ mask: phoneMask }).resolve(recoveredProposal.spousePhoneNumber) : null,
          spouseNationality: recoveredProposal.spouseNationality,


          spouseProfession: recoveredProposal.spouseProfession,
          spouseJobPosition: recoveredProposal.spouseJobPosition,
          spouseCompanyName: recoveredProposal.spouseCompanyName,

          spouseHireDate: recoveredProposal.spouseHireDate ? new Date(recoveredProposal.spouseHireDate).toISOString().split('T')[0] : null,
          spouseGrossIncome: recoveredProposal.spouseGrossIncome ? currencyMask(recoveredProposal.spouseGrossIncome) : null,
          spouseNetIncome: recoveredProposal.spouseNetIncome ? currencyMask(recoveredProposal.spouseNetIncome) : null,
          spouseIncomeType: recoveredProposal.spouseIncomeType,


          realEstateAddress: recoveredProposal.realEstateAddress,
          realEstateComplement: recoveredProposal.realEstateComplement,
          realEstateNumber: recoveredProposal.realEstateNumber,
          realEstateNeighborhood: recoveredProposal.realEstateNeighborhood,
          realEstateZipCode: createMask({ mask: zipCodeMask }).resolve(recoveredProposal.realEstateZipCode),

          realEstateStateID: recoveredProposal.realEstateStateID,
          realEstateCityID: recoveredProposal.realEstateCityID,
          realEstateType: recoveredProposal.realEstateType,
          realEstateSituation: recoveredProposal.realEstateSituation,
          realEstatePrice: currencyMask(recoveredProposal.realEstatePrice),

          realEstateAppraisalValue: recoveredProposal.realEstateAppraisalValue ? currencyMask(recoveredProposal.realEstateAppraisalValue) : null,


          financingValue: currencyMask(recoveredProposal.financingValue),
          financingPayType: recoveredProposal.financingPayType,
          financingSpouse: recoveredProposal.financingSpouse,

          // Public Banks data
          publicBanksShouldSend: recoveredProposal.publicBanksShouldSend,


          // Seller data
          sellerTradeName: recoveredProposal.sellerTradeName,
          sellerCNPJ: recoveredProposal.sellerCNPJ ? createMask({ mask: cnpjMask }).resolve(recoveredProposal.sellerCNPJ) : null,
          sellerCompleteName: recoveredProposal.sellerCompleteName,
          sellerAccountAgency: recoveredProposal.sellerAccountAgency,
          sellerAccountNumber: recoveredProposal.sellerAccountNumber,
          sellerAccountBankID: recoveredProposal.sellerAccountBankID,
          sellerEmail: recoveredProposal.sellerEmail,
          sellerPhoneNumber: recoveredProposal.sellerPhoneNumber ? createMask({ mask: phoneMask }).resolve(recoveredProposal.sellerPhoneNumber) : null,

          // Person files URL
          personFileCNHURL: recoveredProposal.personFileCNHURL,
          personFileRGFrontURL: recoveredProposal.personFileRGFrontURL,
          personFileRGBackURL: recoveredProposal.personFileRGBackURL,
          personFileCPFURL: recoveredProposal.personFileCPFURL,
          personFileCertificateCivilStatusURL: recoveredProposal.personFileCertificateCivilStatusURL,
          personFileAddressVerificationURL: recoveredProposal.personFileAddressVerificationURL,
          personFileLatestPayStubURL: recoveredProposal.personFileLatestPayStubURL,
          personFilePenultimatePayStubURL: recoveredProposal.personFilePenultimatePayStubURL,
          personFileIncomeTaxURL: recoveredProposal.personFileIncomeTaxURL,
          personFileIncomeTaxReceiptURL: recoveredProposal.personFileIncomeTaxReceiptURL,
          personFileExtractFGTSURL: recoveredProposal.personFileExtractFGTSURL,
          personFileAuthorizationFGTSURL: recoveredProposal.personFileAuthorizationFGTSURL,

          // Seller Files URL
          sellerFileRGFrontURL: recoveredProposal.sellerFileRGFrontURL,
          sellerFileRGBackURL: recoveredProposal.sellerFileRGBackURL,
          sellerFileCertificateCivilStatusURL: recoveredProposal.sellerFileCertificateCivilStatusURL,
          sellerFileAddressVerificationURL: recoveredProposal.sellerFileAddressVerificationURL,
          sellerFileSocialContractURL: recoveredProposal.sellerFileSocialContractURL,
          sellerFileSimplifiedCertificateURL: recoveredProposal.sellerFileSimplifiedCertificateURL,
          sellerFileDocumentsOfLegalRepresentativesURL: recoveredProposal.sellerFileDocumentsOfLegalRepresentativesURL,
          sellerFilePowerOfAttorneyURL: recoveredProposal.sellerFilePowerOfAttorneyURL,
          sellerFileAttorneysDocumentURL: recoveredProposal.sellerFileAttorneysDocumentURL,


          // RealEstate Files URL
          realEstateFileUpdateRegistrationURL: recoveredProposal.realEstateFileUpdateRegistrationURL,
          realEstateFileIPTUURL: recoveredProposal.realEstateFileIPTUURL,
          realEstateFileOccupancyPermitURL: recoveredProposal.realEstateFileOccupancyPermitURL,
          realEstateFileSupervisorCREACAUURL: recoveredProposal.realEstateFileSupervisorCREACAUURL,
          realEstateFileARTRRTURL: recoveredProposal.realEstateFileARTRRTURL,
          realEstateFileAppraisalReportURL: recoveredProposal.realEstateFileAppraisalReportURL,


          scheduledPaymentDate: recoveredProposal.scheduledPaymentDate ? new Date(recoveredProposal.scheduledPaymentDate).toISOString().split('T')[0] : null,
        })

        setProposalPhase(recoveredProposal.proposalDetailPhase)
        setCurrentProposalPhase(recoveredProposal.proposalDetailPhase)
        setProposalStatus(recoveredProposal.status)

        if(recoveredProposal.proposalDetailPhase === ProposalPhase.REFUSED) {
          setMainError(`Proposta Recusada: ${showProposalStatus(recoveredProposal.status)} ${recoveredProposal.declineObservation ?  " - " + recoveredProposal.declineObservation : "" }`)
        } else if(recoveredProposal.pendency && recoveredProposal.pendency.length) {
          setHasPendency(true)
          const error = `Pendência encontrada: ${showProposalPendingReason(recoveredProposal.pendency[0].reason)}`
          setMainError(error)
        }
      } catch {

      } finally {
        setIsLoading(false)
      }
    }

    if (proposalID) {
      loadData()
    } else {
      resetProposal()
    }
  }, [isEditing, proposalID])

  function ColorlibStepIcon(props: StepIconProps) {
    const { active, completed, className } = props;

    const icons: { [index: string]: React.ReactElement } = {
      1: <FeedIcon />,
      2: <DocumentScannerIcon />,
      3: <MapsHomeWorkIcon />,
      4: <HandshakeIcon />,
      5: <PriceCheckIcon />,
    };

    const iconColor =  active || completed ? 'blue' : 'gray'

    return (
      // <ColorlibStepIconRoot ownerState={{ completed, active }} className={className} sx={{ color: active ? 'inherit' : 'black' }}>
      <ColorlibStepIconRoot ownerState={{ completed, active }} className={className} >
        {React.cloneElement(icons[String(props.icon)], { style: { color: iconColor } })}
      </ColorlibStepIconRoot>
    );
  }

  const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
      top: 22,
    },
    [`&.${stepConnectorClasses.active}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        backgroundColor: '#784AF4'
        // backgroundImage:
          // 'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)',
      },
    },
    [`&.${stepConnectorClasses.completed}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        backgroundColor: '#784AF4'
        // backgroundImage:
          // 'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)',
      },
    },
    [`& .${stepConnectorClasses.line}`]: {
      height: 3,
      border: 0,
      backgroundColor:
        theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
      borderRadius: 1,
    },
  }));

  const ColorlibStepIconRoot = styled('div')<{
    ownerState: { completed?: boolean; active?: boolean };
  }>(({ theme, ownerState }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#ccc',
    zIndex: 1,
    color: '#fff',
    width: 50,
    height: 50,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
    ...(ownerState.active && {
      // backgroundImage:
      //   'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
      boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
    }),
    ...(ownerState.completed && {
      // backgroundImage:
      //   'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
    }),
  }));

  function getProposalPhase(proposalPhase: ProposalPhase) {
    switch (proposalPhase) {
      case ProposalPhase.PROPOSAL_DATA:
        return <ProposalDataForm  canEdit={canEdit} isExistingProposal={!isEditing} />;
      case ProposalPhase.DOCUMENTATION:
        return <DocumentationDataForm canEdit={canEdit} />;
      case ProposalPhase.PROPERTY_EVALUATION:
          return <PropertyEvaluationForm canEdit={canEdit} />
      case ProposalPhase.CONTRACTING:
        return <ContractingForm canEdit={canEdit} proposalStatus={proposalStatus} />
      case ProposalPhase.DISBURSEMENT:
        return <DisbursementDataForm canEdit={canEdit} proposalStatus={proposalStatus} />
      case ProposalPhase.REFUSED:
        return <ProposalDataForm canEdit={false} isExistingProposal={false}/>
    }
  }

  if(isLoading) {
    return <CircularProgress color="primary" className={classes.spinner} />
  }

  return (
    <Paper elevation={0} className={classes.paper}>
      <FormHeader
        title={`${title || "Proposta"}`}
        icon={ListIcon}
        backRoute="/proposals/new"
        showBackButton={false}
        showSaveButton={false}
        buttons={[ ...FormHeaderButtons ]}
      />

      <FormAlert setMainError={setMainError} mainError={mainError} />

      { isEditing ?
        <>
          <Stack sx={{ width: '100%' }} spacing={4} marginTop={5}>
            <Stepper alternativeLabel activeStep={currentProposalPhase} connector={<ColorlibConnector />}>
              {proposalDetailSteps.map((label, index) => (
                <Step key={label} onClick={() => {
                  if(index <= proposalPhase) {
                    setCurrentProposalPhase(index)
                  }
                }}>
                  <StepLabel StepIconComponent={ColorlibStepIcon}>
                    {label}
                  </StepLabel>
                </Step>
              ))}
            </Stepper>
          </Stack>

          {getProposalPhase(currentProposalPhase)}
        </> : <ProposalDataForm canEdit={true} isExistingProposal={!isEditing}/>
      }

      { shouldUpdateProposal && <SubmitProposal setShouldUpdateProposal={() => setShouldUpdateProposal(false)}/> }

      <DeclineProposalDialog  proposalID={proposalID} isOpen={isDeclineProposalDialogOpen} setIsOpen={setIsDeclineProposalDialogOpen} />
      <AddPendingToProposalDialog proposalID={proposalID} isOpen={isAddPendingToProposalDialogOpen} setIsOpen={setIsAddPendingToProposalDialogOpen} />
      <ApproveProposalDialog  proposalID={proposalID} isOpen={isApproveProposalDialogOpen} setIsOpen={setIsApproveProposalDialogOpen} />
    </Paper>
  )
}

export default ProposalForm
