import React, { useState, useEffect, useRef, useCallback } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { Box, Paper, Grid, TextField, InputLabel, MenuItem, Typography } from '@mui/material'
import { FormGroup, FormControlLabel, Checkbox } from '@mui/material'
import { useForm, Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { FormHeader, FormAlert } from 'components'
import ListIcon from '@mui/icons-material/List'
import * as yup from 'yup'
import { useStyles } from './styles'
import api from 'services/api'
import { IManagerDTO } from 'data/dtos/i-officer-dto'

interface IRouteParams {
  id: string
}

const ManagerForm: React.FC = () => {
  const [mainError, setMainError] = useState('')
  const [userGroups, setUserGroups] = useState([])
  const [blockReasons, setBlockReasons] = useState([])

  const params = useParams<IRouteParams>()
  const firstInputElement = useRef(null)
  const classes = useStyles()
  const history = useHistory()

  const validationSchema = yup.object().shape({
    userGroupId: yup.string()
      .required('Campo obrigatório'),
    name: yup.string()
      .required('Campo obrigatório'),
    email: yup.string()
      .required('Campo obrigatório'),
  })

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    watch,
    formState: { errors },
    control
  } = useForm<IManagerDTO>({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      username: '',
      cpf: '',
      name: '',
      email: '',
      phone: ''
    }
  })

  // initial load

  useEffect(() => {
    async function loadData() {

      // select Grupo de Usuários
      await api
        .post('/user-groups/select')
        .then(response => {
          const { data } = response.data

          return data
        })
        .then((userGroupsResult) => {
          setUserGroups(userGroupsResult)
        })
        .catch(error => {
          console.log(error)
          return error
        })

      // select Motivo do Bloqueio

      await api
        .post('/block-reasons/select')
        .then(response => {
          const { data } = response.data

          return data
        })
        .then((blockReasonsResult) => {
          setBlockReasons(blockReasonsResult)
        })
        .catch(error => {
          console.log(error)
          return error
        })
    }

    loadData()
  }, [])


  // main data load

  useEffect(() => {
    async function loadData() {
      const { id } = params

      // form data

      await api
        .get(`/users-security/${id}`)
        .then(response => {
          const { data } = response.data

          const userResult = {
            userGroupId: data.userGroupId === null ? null : data.userGroupId.id,
            name: data.name,
            email: data.email,
            password: '',
            isAdmin: data.isAdmin,
            isSuperUser: data.isSuperUser,
            isBlocked: data.isBlocked,
            blockReasonId: data.blockReasonId === null ? null : data.blockReasonId.id,
            mustChangePasswordNextLogon: data.mustChangePasswordNextLogon,
            isDisabled: data.isDisabled,
            avatar: data.avatar,
          }

          return userResult
        })
        .then((userResult: IManagerDTO) => {
          reset(userResult)
        })
        .catch(error => {
          console.log(error)
          return error
        })
    }

    if (params.id) {
      loadData()
    }
  }, [params, params.id])


  // data save

  const onSubmit = useCallback(async (data: IManagerDTO) => {
    const payLoad: IManagerDTO = {
      username: '',
      cpf: '',
      name: '',
      email: '',
      phone: ''
    }

    if (params.id) {
      const { id } = params

      payLoad.id = id

      await api
        .put(`/users-security`, payLoad)
        .then(history.push('/users'))
        .catch(error => {
          console.log(error.response.data)
          setMainError(error.response.data.data.name)
          return error.response.data.data
        })
    } else {
      await api
        .post('/users-security', payLoad)
        .then(history.push('/users/new'))
        .then(() => reset())
        .then(() => setTimeout(() => { firstInputElement.current.focus() }, 0))
        .catch(error => {
          console.log(error.response.data)
          setMainError(error.response.data.data.name)
          return error.response.data.data
        })
    }
  }, [])


  const handleChange = (formField: any) => {
    setMainError('')
  }

  return (
    <Paper elevation={0} className={classes.paper}>
      <Box
        component="form"
        onSubmit={handleSubmit(onSubmit)}
        noValidate
        data-testid="form"
      >
        <FormHeader
          title="Usuários"
          icon={ListIcon}
          backRoute="/users"
          showBackButton={true}
          showSaveButton={true}
        />

        <FormAlert setMainError={setMainError} mainError={mainError} />

        <Paper elevation={1} className={classes.gridPaper}>
          <Grid container spacing={1} className={classes.formContainer}>

            <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Typography variant="caption" display="block" gutterBottom>
                  Username
                </Typography>
                <TextField
                  id="username"
                  name="username"
                  error={!!errors.username}
                  helperText={errors?.username?.message}
                  variant="outlined"
                  margin="dense"
                  size="small"
                  fullWidth={true}
                  InputLabelProps={{
                    shrink: true
                  }}
                  inputProps={{
                    maxLength: 60
                  }}
                  {...register("username",
                    { onChange: (e) => handleChange(e) }
                  )}
                />
              </Grid>
            </Grid>

            <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Typography variant="caption" display="block" gutterBottom>
                  CPF
                </Typography>
                <TextField
                  id="cpf"
                  name="cpf"
                  error={!!errors.cpf}
                  helperText={errors?.cpf?.message}
                  variant="outlined"
                  margin="dense"
                  size="small"
                  fullWidth={true}
                  InputLabelProps={{
                    shrink: true
                  }}
                  inputProps={{
                    maxLength: 60
                  }}
                  {...register("cpf",
                    { onChange: (e) => handleChange(e) }
                  )}
                />
              </Grid>
            </Grid>


            <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Typography variant="caption" display="block" gutterBottom>
                  Nome
                </Typography>
                <TextField
                  id="name"
                  name="name"
                  error={!!errors.name}
                  helperText={errors?.name?.message}
                  variant="outlined"
                  margin="dense"
                  size="small"
                  fullWidth={true}
                  InputLabelProps={{
                    shrink: true
                  }}
                  inputProps={{
                    maxLength: 60
                  }}
                  {...register("name",
                    { onChange: (e) => handleChange(e) }
                  )}
                />
              </Grid>
            </Grid>

            <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Typography variant="caption" display="block" gutterBottom>
                  Email
                </Typography>
                <TextField
                  id="email"
                  name="email"
                  error={!!errors.email}
                  helperText={errors?.email?.message}
                  variant="outlined"
                  margin="dense"
                  size="small"
                  fullWidth={true}
                  InputLabelProps={{
                    shrink: true
                  }}
                  inputProps={{
                    maxLength: 60
                  }}
                  {...register("email",
                    { onChange: (e) => handleChange(e) }
                  )}
                />
              </Grid>
            </Grid>

            <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Typography variant="caption" display="block" gutterBottom>
                  Celular
                </Typography>
                <TextField
                  id="phone"
                  name="phone"
                  error={!!errors.phone}
                  helperText={errors?.phone?.message}
                  variant="outlined"
                  margin="dense"
                  size="small"
                  fullWidth={true}
                  InputLabelProps={{
                    shrink: true
                  }}
                  inputProps={{
                    maxLength: 60
                  }}
                  {...register("phone",
                    { onChange: (e) => handleChange(e) }
                  )}
                />
              </Grid>
            </Grid>

            <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Typography variant="caption" display="block" gutterBottom>
                  Officer
                </Typography>
                <TextField
                  id="userGroupId"
                  name="userGroupId"
                  error={!!errors.officerId}
                  helperText={errors?.officerId?.message}
                  variant="outlined"
                  margin="dense"
                  size="small"
                  fullWidth={true}
                  value={`${watch().officerId}`}
                  select
                  autoFocus
                  inputRef={firstInputElement}
                  {...register("officerId", { onChange: (e) => {
                    setValue("officerId", e.target.value)
                    handleChange(e)
                  }})}
                >
                {userGroups.map((userGroup) => (
                  <MenuItem
                    key={userGroup.id}
                    value={userGroup.id}
                  >
                    {userGroup.name}
                  </MenuItem>
                ))}
                </TextField>
              </Grid>
            </Grid>

          </Grid>
        </Paper>
      </Box>
    </Paper>
  )
}

export default ManagerForm
