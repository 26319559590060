import { Box, Step, StepContent, StepLabel, Stepper, TextField, Typography } from "@mui/material"
import { useState } from "react"
import { SubmitProposal } from "../proposal-data/submit-proposal"
import { ProposalContractingFormStep } from "./form-step"
import { AwaitingSellerSignedForm } from "./awaiting-seller-signed-form"
import { AwaitingBankSignedForm } from "./awaiting-bank-signed-form"
import { AwaitingClientSignedForm } from "./awaiting-client-signed-form"
import { AwaitingITBIGeneratedForm } from "./awaiting-itbi-generated-form"
import { AwaitingITBIPaidForm } from "./awaiting-itbi-paid-form"
import { AwaitingNotaryExpensesPaidForm } from "./awaiting-notary-expenses-paid-form"
import { AwaitingDocumentationDeliveredForm } from "./awaiting-documentation-delivered-form"
import { ProposalPhase, ProposalStatus } from "hooks/enums/ProposalStatus"

type Props = {
  canEdit: boolean,
  proposalStatus: ProposalStatus
}

const propertyEvaluationFormSteps = [
  { label: 'O contrato foi assinado pelo comprador?' },
  { label: 'O contrato foi assinado pelo vendedor?' },
  { label: 'O contrato foi assinado pelo banco?' },
  { label: 'A guia de imposto ITBI foi gerada?' },
  { label: 'A guia de imposto ITBI foi paga pelo cliente?' },
  { label: 'As despesas Cartorárias foram pagas?' },
  { label: 'A documentação foi entregue no banco?' },
];

const getFormStepByProposalStatus = (proposalStatus: ProposalStatus): ProposalContractingFormStep => {
  switch(proposalStatus) {
    case ProposalStatus.HIRING_AWAITING_SELLER_SIGNED:
      return ProposalContractingFormStep.AWAITING_SELLER_SIGNED;
    case ProposalStatus.HIRING_AWAITING_BANK_SIGNED:
      return ProposalContractingFormStep.AWAITING_BANK_SIGNED;
    case ProposalStatus.HIRING_AWAITING_ITBI_GENERATED:
      return ProposalContractingFormStep.AWAITING_ITBI_GENERATED;
    case ProposalStatus.HIRING_AWAITING_ITBI_PAID:
      return ProposalContractingFormStep.AWAITING_ITBI_PAID;
    case ProposalStatus.HIRING_AWAITING_NOTARY_EXPENSES_PAID:
      return ProposalContractingFormStep.AWAITING_NOTARY_EXPENSES_PAID;
    case ProposalStatus.HIRING_AWAITING_DOCUMENTATION_DELIVERED:
      return ProposalContractingFormStep.AWAITING_DOCUMENTATION_DELIVERED;

    default:
      return ProposalContractingFormStep.AWAITING_CLIENT_SIGNED
  }
}

export const ContractingForm = ({ canEdit, proposalStatus }: Props) => {
  const [proposalFormStep, setProposalFormStep] = useState<ProposalContractingFormStep>(getFormStepByProposalStatus(proposalStatus));

  function getFormStep(formStep: ProposalContractingFormStep) {
    switch (formStep) {
      case ProposalContractingFormStep.AWAITING_CLIENT_SIGNED:
        return <AwaitingClientSignedForm isDisabled={!canEdit} setProposalFormStep={setProposalFormStep} />
      case ProposalContractingFormStep.AWAITING_SELLER_SIGNED:
        return <AwaitingSellerSignedForm isDisabled={!canEdit} setProposalFormStep={setProposalFormStep} />
      case ProposalContractingFormStep.AWAITING_BANK_SIGNED:
        return <AwaitingBankSignedForm isDisabled={!canEdit} setProposalFormStep={setProposalFormStep} />
      case ProposalContractingFormStep.AWAITING_ITBI_GENERATED:
        return <AwaitingITBIGeneratedForm isDisabled={!canEdit} setProposalFormStep={setProposalFormStep} />
      case ProposalContractingFormStep.AWAITING_ITBI_PAID:
        return <AwaitingITBIPaidForm isDisabled={!canEdit} setProposalFormStep={setProposalFormStep} />
      case ProposalContractingFormStep.AWAITING_NOTARY_EXPENSES_PAID:
        return <AwaitingNotaryExpensesPaidForm isDisabled={!canEdit} setProposalFormStep={setProposalFormStep} />
      case ProposalContractingFormStep.AWAITING_DOCUMENTATION_DELIVERED:
        return <AwaitingDocumentationDeliveredForm isDisabled={!canEdit} setProposalFormStep={setProposalFormStep} />
    }
  }

  return (
    <>
      <Box sx={{ maxWidth: 1200, mx: 'auto', mt: 5, mb: 5 }}>
        <Stepper activeStep={proposalFormStep} orientation="vertical" >
          {propertyEvaluationFormSteps.map((step, index) => (
            <Step key={step.label}>
              <StepLabel>
                {step.label}
              </StepLabel>
              <StepContent>
                {getFormStep(proposalFormStep)}
              </StepContent>
            </Step>
          ))}
        </Stepper>
        {proposalFormStep === ProposalContractingFormStep.COMPLETED && (
          <SubmitProposal redirectToPhase={ProposalPhase.DISBURSEMENT} />
        )}
      </Box>
    </>
  )
}

