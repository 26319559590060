import { Avatar, Box, Button, Checkbox, FormControl, FormControlLabel, FormGroup, Grid, MenuItem, Paper, RadioGroup, TextField, Typography } from "@mui/material";
import { ProposalContext } from "contexts/proposal-context"
import { IProposalDTO } from "data/dtos/i-proposal-dto";
import { MaritalStatus } from "hooks/enums/MaritalStatus";
import { zodResolver } from '@hookform/resolvers/zod';
import { useContext, useState } from "react"
import { Controller, FormProvider, useForm } from "react-hook-form";
import { bankNamePlaceHolder, completeNamePlaceHolder, cpfMask, cpfMaskPlaceholder, emailPlaceHolder, identityIssuer, identityMask, nationalityPlaceHolder, phoneMask, phoneMaskPlaceholder } from "utils/utils";
import { useStyles } from "../../styles";
import { createMask } from "imask";
import { DocumentType } from "hooks/enums/DocumentType";
import { HouseType } from "hooks/enums/HouseType";
import FileUpload from "components/file-upload";
import { array, object, TypeOf, z } from 'zod';
import PersonIcon from '@mui/icons-material/Person';
import BusinessIcon from '@mui/icons-material/Business';
import { ProposalDocumentationFormStep } from "./form-step";
import { ProposalStatus } from "hooks/enums/ProposalStatus";
import ContentCopyRoundedIcon from '@mui/icons-material/ContentCopyRounded';
import { ART_RTT_TOOLTIP_HELPER_TEXT, CREA_CAU__TOOLTIP_HELPER_TEXT, IPTU_TOOLTIP_HELPER_TEXT, OCCUPANCY_PERMIT_TOOLTIP_HELPER_TEXT, UPDATE_REGISTRATION_TOOLTIP_HELPER_TEXT } from "utils/constants/tooltipHelperMessages";

type Props = {
  isDisabled: boolean
  setProposalFormStep: React.Dispatch<React.SetStateAction<ProposalDocumentationFormStep>>
}

const imageUploadSchema = object({
  image: z.instanceof(File),
  images: array(z.instanceof(File)),
});

type IUploadImage = TypeOf<typeof imageUploadSchema>;

export const PropertyForm = ({ isDisabled, setProposalFormStep }: Props) => {
  const { proposal, setProposal } = useContext(ProposalContext);
  const classes = useStyles()

  const methods = useForm<IUploadImage>({
    resolver: zodResolver(imageUploadSchema),
  });

  const handleBack = () => {
    setProposalFormStep(ProposalDocumentationFormStep.SELLER_DATA)
  }

  const handleFileUpload = (fieldName: string, file: File): void => {
    setProposal({...proposal, [fieldName]: file})
  }

  const saveData = (personPersonalData: IProposalDTO) => {
    setProposal({...proposal, ...personPersonalData, status: ProposalStatus.AWAITING_EVALUATION_SCHEDULING})
    setProposalFormStep(ProposalDocumentationFormStep.COMPLETED)
  }

  const {
    handleSubmit,
    formState: { errors },
  } = useForm<IProposalDTO>({
    defaultValues: {
      publicBanksShouldSend: proposal.publicBanksShouldSend || false,
    },
    reValidateMode: 'onChange'
  })

  return (
    <form onSubmit={handleSubmit(saveData)}>
      <FormProvider {...methods}>
          <Grid container spacing={1} className={classes.formContainer}>

            <Paper elevation={1} variant="outlined" className={classes.paperWrapper}>
              <Grid container alignItems='center' direction='row' style={{ maxWidth: '800px'}}>
                <Grid item xs={1} sm={1} md={1} lg={1} xl={1} style={{ paddingRight: '0px'}} >
                  <Avatar sx={{ backgroundColor: '#F5F8FF' }}>
                    <ContentCopyRoundedIcon />
                  </Avatar>
                </Grid>
                <Grid item xs={11} sm={11} md={11} lg={11} xl={11}>
                  <div>
                    <Typography variant="caption" marginBottom={0} paddingBottom={0}>
                      <strong>Faça aqui o upload dos seus documentos</strong>
                    </Typography>
                    <div>
                      <Typography variant="caption" marginBottom={0} paddingBottom={0}>
                        Arquivos suportados nos seguintes formatos: PDF, PNG, JPEG e JPG, com tamanho máximo de 10MBs.
                      </Typography>
                    </div>
                  </div>
                </Grid>
              </Grid>

              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <FileUpload label="Matrícula Atualizada *" helperText={UPDATE_REGISTRATION_TOOLTIP_HELPER_TEXT} defaultValue={proposal.realEstateFileUpdateRegistration} imageUrl={proposal.realEstateFileUpdateRegistrationURL} name='realEstateFileUpdateRegistration' isDisabled={isDisabled} onFileUpload={handleFileUpload} shouldShowHeader={true} isRequired={true}/>
              </Grid>

              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <FileUpload label="IPTU do ano vigente *" helperText={IPTU_TOOLTIP_HELPER_TEXT} defaultValue={proposal.realEstateFileIPTU} imageUrl={proposal.realEstateFileIPTUURL} name='realEstateFileIPTU' isDisabled={isDisabled} onFileUpload={handleFileUpload} isRequired={true}/>
              </Grid>

              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <FileUpload label="Habite-se" helperText={OCCUPANCY_PERMIT_TOOLTIP_HELPER_TEXT} defaultValue={proposal.realEstateFileOccupancyPermit} imageUrl={proposal.realEstateFileOccupancyPermitURL} name='realEstateFileOccupancyPermit' isDisabled={isDisabled} onFileUpload={handleFileUpload} isRequired={false}/>
              </Grid>

              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <FileUpload label="CREA ou CAU do responsável técnico" helperText={CREA_CAU__TOOLTIP_HELPER_TEXT} defaultValue={proposal.realEstateFileSupervisorCREACAU} imageUrl={proposal.realEstateFileSupervisorCREACAUURL} name='realEstateFileSupervisorCREACAU' isDisabled={isDisabled} onFileUpload={handleFileUpload} isRequired={false}/>
              </Grid>

              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <FileUpload label="ART e RRT" helperText={ART_RTT_TOOLTIP_HELPER_TEXT} defaultValue={proposal.realEstateFileARTRRT} imageUrl={proposal.realEstateFileARTRRTURL} name='realEstateFileARTRRT' isDisabled={isDisabled} onFileUpload={handleFileUpload} isRequired={false}/>
              </Grid>
            </Paper>
          </Grid>

          <Grid container spacing={1}>
            <Button variant="contained" type="submit" sx={{ mt: 1, mb: 1 }}>
              Continuar
            </Button>
            <Button onClick={handleBack} sx={{ mt: 1, mr: 1, ml: 1 }}>
              Voltar
            </Button>
          </Grid>

        </FormProvider>
    </form>
  )
}
