import { Box, Button, FormControl, Grid, MenuItem, TextField, Typography } from "@mui/material";
import { ProposalContext } from "contexts/proposal-context"
import { IProposalDTO } from "data/dtos/i-proposal-dto";
import { MaritalStatus, showMaritalStatus } from "hooks/enums/MaritalStatus";
import { useContext, useEffect, useState } from "react"
import { useForm } from "react-hook-form";
import { bankNamePlaceHolder, completeNamePlaceHolder, cpfMask, cpfMaskPlaceholder, emailPlaceHolder, identityIssuer, identityMask, nationalityPlaceHolder, phoneMask, phoneMaskPlaceholder, sanatizeStringValue } from "utils/utils";
import { useStyles } from "../../styles";
import { createMask } from "imask";
import { DocumentType, showDocumentType } from "hooks/enums/DocumentType";
import { HouseType, showHouseType } from "hooks/enums/HouseType";
import { IsEmailRegex } from "utils/regex";
import { invalidCelPhoneLength, invalidCpfLength, invalidDate, invalidEmail, requiredField } from "utils/error-messages";
import { ProposalDataFormStep } from "./form-step";

type Props = {
  isDisabled: boolean
  shouldResetForm: boolean
  setProposalFormStep: React.Dispatch<React.SetStateAction<ProposalDataFormStep>>
}

export const PersonPersonalData = ({ isDisabled, shouldResetForm, setProposalFormStep }: Props) => {
  const { proposal, setProposal, resetProposal } = useContext(ProposalContext);
  const classes = useStyles()

  const saveData = (personPersonalData: IProposalDTO) => {
    setProposal({...proposal, ...personPersonalData})
    setProposalFormStep(ProposalDataFormStep.PERSON_PROFESSIONAL_DATA)
  }

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    reset,
    formState: { errors },
  } = useForm<IProposalDTO>({
    defaultValues: {
      personCompleteName: proposal.personCompleteName || '',
      personEmail: proposal.personEmail || '',
      personBirthDate: proposal.personBirthDate,

      personCpf: proposal.personCpf || '',
      personDocumentType: proposal.personDocumentType || '',
      personDocumentNumber: proposal.personDocumentNumber || '',
      personDocumentIssuingAgency: proposal.personDocumentIssuingAgency || '',
      personPhoneNumber: proposal.personPhoneNumber || '',

      personNationality: proposal.personNationality || '',
      personMaritalStatus: proposal.personMaritalStatus || '',
      personCurrentHouseType: proposal.personCurrentHouseType || '',
      personBankAccounts: proposal.personBankAccounts || ''
    },
    reValidateMode: 'onChange'
  })

  useEffect(() => {
    if (shouldResetForm) {
      resetProposal(); // Redefina a proposal para um objeto vazio
      reset({
        personCompleteName: '',
        personEmail: '',
        personBirthDate: '',

        personCpf: '',
        personDocumentType: '',
        personDocumentNumber: '',
        personDocumentIssuingAgency: '',
        personPhoneNumber: '',

        personNationality: '',
        personMaritalStatus: '',
        personCurrentHouseType: '',
        personBankAccounts: '',
      });
    }
  }, [shouldResetForm]);

  return (
    <form onSubmit={handleSubmit(saveData)}>
      <FormControl >
        <Grid container spacing={1} className={classes.formContainer}>
          <Grid item xs={12} sm={6} md={5} lg={5} xl={5}>
            <Typography variant="caption" display="block" gutterBottom>
              Nome Completo *
            </Typography>
            <TextField
              id="personCompleteName"
              name="personCompleteName"
              error={!!errors.personCompleteName}
              helperText={errors?.personCompleteName?.message}
              placeholder={completeNamePlaceHolder}
              value={proposal.personCompleteName}
              variant="outlined"
              margin="dense"
              size="small"
              fullWidth={true}
              InputLabelProps={{
                shrink: true
              }}
              inputProps={{
                maxLength: 60
              }}
              {...register("personCompleteName", {
                required: "Campo Obrigatório",
                onChange: (e) => {
                  setProposal({ ...proposal, personCompleteName: e.target.value })
                }
              })}
              disabled={isDisabled}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={4} lg={5} xl={5}>
            <Typography variant="caption" display="block" gutterBottom>
              Email *
            </Typography>
            <TextField
              id="personEmail"
              name="personEmail"
              error={!!errors.personEmail}
              helperText={errors?.personEmail?.message}
              value={proposal.personEmail}
              placeholder={emailPlaceHolder}
              variant="outlined"
              margin="dense"
              size="small"
              fullWidth={true}
              InputLabelProps={{
                shrink: true
              }}
              inputProps={{
                maxLength: 100
              }}
              {...register("personEmail", {
                required: "Campo Obrigatório",
                pattern: {
                  value: IsEmailRegex,
                  message: invalidEmail,
                },
                onChange: (e) => {
                  setProposal({ ...proposal, personEmail: e.target.value })
                }
              })}
              disabled={isDisabled}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={3} lg={2} xl={2}>
            <Typography variant="caption" display="block" gutterBottom>
              Data Nascimento *
            </Typography>
            <TextField
              id="personBirthDate"
              name="personBirthDate"
              error={!!errors.personBirthDate}
              helperText={errors?.personBirthDate?.message}
              value={proposal.personBirthDate}
              variant="outlined"
              margin="dense"
              size="small"
              fullWidth={true}
              type="date"
              InputLabelProps={{
                shrink: true
              }}
              {...register("personBirthDate", {
                required: "Campo Obrigatório",
                min: {
                  value: '1920-01-01',
                  message: invalidDate
                },
                onChange: (e) => {
                  setProposal({ ...proposal, personBirthDate: e.target.value })
                }
              })}
              disabled={isDisabled}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={3} lg={2} xl={2}>
            <Typography variant="caption" display="block" gutterBottom>
              CPF *
            </Typography>
            <TextField
              id="personCpf"
              name="personCpf"
              error={!!errors.personCpf}
              helperText={errors?.personCpf?.message}
              value={proposal.personCpf}
              placeholder={cpfMaskPlaceholder}
              variant="outlined"
              margin="dense"
              size="small"
              fullWidth={true}
              InputLabelProps={{
                shrink: true
              }}
              {...register("personCpf", {
                required: "Campo Obrigatório",
                validate: (value: string) => {
                  const cpf = sanatizeStringValue(value);
                  return cpf.length === 11 || invalidCpfLength;
                },
                onChange: (e) => {
                  const personCPF = createMask({ mask: cpfMask }).resolve(e.target.value)
                  setValue("personCpf", personCPF)
                  setProposal({ ...proposal, personCpf: personCPF })
                }
              })}
              disabled={isDisabled}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
            <Typography variant="caption" display="block" gutterBottom>
              Tipo de Documento *
            </Typography>
            <TextField
              id="personDocumentType"
              name="personDocumentType"
              error={!!errors.personDocumentType}
              helperText={errors?.personDocumentType?.message}
              variant="outlined"
              margin="dense"
              size="small"
              fullWidth={true}
              value={watch('personDocumentType')}
              select
              {...register("personDocumentType", {
                required: "Campo Obrigatório",
                onChange: (e) => {
                  setProposal({ ...proposal, personDocumentType: e.target.value })
                }
              })}
              disabled={isDisabled}
            >
            {Object.entries(DocumentType).map(([key, value]) => (
              <MenuItem
                key={key}
                value={value}
              >
                {showDocumentType(value)}
              </MenuItem>
            ))}
            </TextField>
          </Grid>

          <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
            <Typography variant="caption" display="block" gutterBottom>
              Numero do documento *
            </Typography>
            <TextField
              id="personDocumentNumber"
              name="personDocumentNumber"
              error={!!errors.personDocumentNumber}
              helperText={errors?.personDocumentNumber?.message}
              value={proposal.personDocumentNumber}
              placeholder={identityMask}
              variant="outlined"
              margin="dense"
              size="small"
              fullWidth={true}
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{
                maxLength: 100,
              }}
              {...register("personDocumentNumber", {
                required: requiredField,
                onChange: (e) => {
                  setProposal({ ...proposal, personDocumentNumber: e.target.value })
                }
              })}
              disabled={isDisabled}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={3} lg={2} xl={2}>
            <Typography variant="caption" display="block">
              Orgão Emissor
            </Typography>
            <TextField
              disabled={watch('personDocumentType') !== DocumentType.RG || isDisabled}
              id="personDocumentIssuingAgency"
              name="personDocumentIssuingAgency"
              error={!!errors.personDocumentIssuingAgency}
              helperText={errors?.personDocumentIssuingAgency?.message}
              value={proposal.personDocumentIssuingAgency}
              placeholder={identityIssuer}
              variant="outlined"
              margin="dense"
              size="small"
              fullWidth={true}
              InputLabelProps={{
                shrink: true
              }}
              inputProps={{
                maxLength: 100
              }}
              {...register("personDocumentIssuingAgency", {
                onChange: (e) => {
                  setProposal({ ...proposal, personDocumentIssuingAgency: e.target.value })
                }
              })}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={4} lg={2} xl={2}>
            <Typography variant="caption" display="block" gutterBottom>
              Celular *
            </Typography>
            <TextField
              id="personPhoneNumber"
              name="personPhoneNumber"
              error={!!errors.personPhoneNumber}
              helperText={errors?.personPhoneNumber?.message}
              value={proposal.personPhoneNumber}
              placeholder={phoneMaskPlaceholder}
              variant="outlined"
              margin="dense"
              size="small"
              fullWidth={true}
              InputLabelProps={{
                shrink: true
              }}
              inputProps={{
                maxLength: 100
              }}
              {...register("personPhoneNumber", {
                required: requiredField,
                validate: (value) => {
                  const cellPhone = sanatizeStringValue(value);
                  return cellPhone.length === 11 || invalidCelPhoneLength;
                },
                onChange: (e) => {
                  const personPhoneNumber = createMask({ mask: phoneMask }).resolve(e.target.value)
                  setValue("personPhoneNumber", personPhoneNumber)
                  setProposal({ ...proposal, personPhoneNumber })
                }
              })}
              disabled={isDisabled}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={4} lg={2} xl={2}>
            <Typography variant="caption" display="block" gutterBottom>
              Nacionalidade *
            </Typography>
            <TextField
              id="personNationality"
              name="personNationality"
              error={!!errors.personNationality}
              helperText={errors?.personNationality?.message}
              value={proposal.personNationality}
              placeholder={nationalityPlaceHolder}
              variant="outlined"
              margin="dense"
              size="small"
              fullWidth={true}
              InputLabelProps={{
                shrink: true
              }}
              inputProps={{
                maxLength: 100
              }}
              {...register("personNationality", {
                required: "Campo Obrigatório",
                onChange: (e) => {
                  setProposal({ ...proposal, personNationality: e.target.value })
                }
              })}
              disabled={isDisabled}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
            <Typography variant="caption" display="block" gutterBottom>
              Estado Civil *
            </Typography>
            <TextField
              id="personMaritalStatus"
              name="personMaritalStatus"
              error={!!errors.personMaritalStatus}
              helperText={errors?.personMaritalStatus?.message}
              variant="outlined"
              margin="dense"
              size="small"
              fullWidth={true}
              value={watch('personMaritalStatus')}
              select
              {...register("personMaritalStatus", {
                required: requiredField,
                onChange: (e) => {
                  setProposal({ ...proposal, personMaritalStatus: e.target.value })
                }
              })}
              disabled={isDisabled}
            >
            {Object.entries(MaritalStatus).map(([key, value]) => (
              <MenuItem
                key={key}
                value={value}
              >
                {showMaritalStatus(value)}
              </MenuItem>
            ))}
            </TextField>
          </Grid>

          <Grid item xs={12} sm={6} md={4} lg={2} xl={2}>
            <Typography variant="caption" display="block" gutterBottom>
              Tipo da residência atual *
            </Typography>
            <TextField
              id="personCurrentHouseType"
              name="personCurrentHouseType"
              error={!!errors.personCurrentHouseType}
              helperText={errors?.personCurrentHouseType?.message}
              variant="outlined"
              margin="dense"
              size="small"
              fullWidth={true}
              value={watch('personCurrentHouseType')}
              select
              {...register("personCurrentHouseType", {
                required: "Campo Obrigatório",
                onChange: (e) => {
                  setProposal({ ...proposal, personCurrentHouseType: e.target.value })
                }
              })}
              disabled={isDisabled}
            >
            {Object.entries(HouseType).map(([key, value]) => (
              <MenuItem
                key={key}
                value={value}
              >
                {showHouseType(value)}
              </MenuItem>
            ))}
            </TextField>
          </Grid>

          <Grid item xs={12} sm={6} md={8} lg={5} xl={5}>
            <Typography variant="caption" display="block" gutterBottom>
              Quais bancos você possui conta *
            </Typography>
            <TextField
              id="personBankAccounts"
              name="personBankAccounts"
              error={!!errors.personBankAccounts}
              helperText={errors?.personBankAccounts?.message}
              value={proposal.personBankAccounts}
              placeholder={bankNamePlaceHolder}
              variant="outlined"
              margin="dense"
              size="small"
              fullWidth={true}
              InputLabelProps={{
                shrink: true
              }}
              inputProps={{
                maxLength: 100
              }}
              {...register("personBankAccounts", {
                required: "Campo Obrigatório",
                onChange: (e) => {
                  setProposal({ ...proposal, personBankAccounts: e.target.value })
                }
              })}
              disabled={isDisabled}
            />
          </Grid>
        </Grid>

        <Grid container spacing={1}>
          <Button variant="contained" type="submit" sx={{ mt: 1, mb: 1 }}>
            Continuar
          </Button>
        </Grid>

      </FormControl>
    </form>
  )
}
