import { Avatar, Box, Button, Checkbox, FormControl, FormControlLabel, FormGroup, Grid, Link, MenuItem, Paper, TextField, Typography } from "@mui/material";
import { ProposalContext } from "contexts/proposal-context"
import { IProposalDTO } from "data/dtos/i-proposal-dto";
import { MaritalStatus } from "hooks/enums/MaritalStatus";
import { zodResolver } from '@hookform/resolvers/zod';
import { useContext, useState } from "react"
import { Controller, FormProvider, useForm } from "react-hook-form";
import { useStyles } from "../../styles";
import { createMask } from "imask";
import { DocumentType } from "hooks/enums/DocumentType";
import { HouseType } from "hooks/enums/HouseType";
import FileUpload from "components/file-upload";
import { array, object, TypeOf, z } from 'zod';
import { ProposalDataFormStep } from "./form-step";
import ContentCopyRoundedIcon from '@mui/icons-material/ContentCopyRounded';
import { ADDRESS_TOOLTIP_HELPER_TEXT, CIVIL_STATUS_TOOLTIP_HELPER_TEXT, CNH_TOOLTIP_HELPER_TEXT, CPF_TOOLTIP_HELPER_TEXT, INCOME_TAX_RECEIPT_TOOLTIP_HELPER_TEXT, INCOME_TAX_TOOLTIP_HELPER_TEXT, LATEST_PAY_STUB_TOOLTIP_HELPER_TEXT, PENULTIMATE_PAY_STUB_TOOLTIP_HELPER_TEXT, RG_BACK_TOOLTIP_HELPER_TEXT, RG_FRONT_TOOLTIP_HELPER_TEXT } from "utils/constants/tooltipHelperMessages";
import { useProfile } from "hooks/use-profile";

type Props = {
  isDisabled: boolean
  setProposalFormStep: React.Dispatch<React.SetStateAction<ProposalDataFormStep>>
}

const imageUploadSchema = object({
  image: z.instanceof(File),
  images: array(z.instanceof(File)),
});

type IUploadImage = TypeOf<typeof imageUploadSchema>;

export const PublicBanksForm = ({ isDisabled, setProposalFormStep }: Props) => {
  const { proposal, setProposal } = useContext(ProposalContext);
  const isEditing = !!proposal.id
   const { selectedProfile } = useProfile()
  const isRegisteredUser = selectedProfile !== ""

  const classes = useStyles()

  const methods = useForm<IUploadImage>({
    resolver: zodResolver(imageUploadSchema),
  });

  const handleBack = () => {
    setProposalFormStep(ProposalDataFormStep.FINANCING_DATA)
  }

  const handleFileUpload = (fieldName: string, file: File): void => {
    setProposal({...proposal, [fieldName]: file})
  }

  const saveData = (personPersonalData: IProposalDTO) => {
    setProposal({...proposal, ...personPersonalData})
    setProposalFormStep(ProposalDataFormStep.COMPLETED)
  }

  const {
    handleSubmit,
    watch,
    control,
    formState: { errors },
  } = useForm<IProposalDTO>({
    defaultValues: {
      publicBanksShouldSend: proposal.publicBanksShouldSend || false,
      agreedPrivacyPolicyAndTermsOfUse: proposal.agreedPrivacyPolicyAndTermsOfUse || false
    },
    reValidateMode: 'onChange'
  })

  return (
    <form onSubmit={handleSubmit(saveData)}>
      <FormProvider {...methods}>
        <Grid container spacing={1} className={classes.formContainer}>

          {isRegisteredUser && <>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <FormGroup className={classes.checkBox}>
                <Controller
                  name="publicBanksShouldSend"
                  control={control}
                  render={({ field }) => (
                    <FormControlLabel
                      control={
                        <Checkbox
                          onChange={(e) => {
                            field.onChange(e.target.checked)
                            setProposal({ ...proposal, publicBanksShouldSend: !!e.target.value })
                          }}
                          checked={field.value}
                        />
                      }
                      label={
                        <Typography variant="caption" display="block" gutterBottom>
                          Deseja enviar sua proposta para bancos públicos (Caixa Econômica Federal, BRB e Banrisul)?
                        </Typography>
                      }
                      disabled={isDisabled}
                    />
                  )}
                />
              </FormGroup>
            </Grid>

            {watch('publicBanksShouldSend') === true && (
              <Paper elevation={1} variant="outlined" className={classes.paperWrapper}>
                <Grid container alignItems='center' direction='row' style={{ maxWidth: '800px'}}>
                  <Grid item xs={1} sm={1} md={1} lg={1} xl={1} style={{ paddingRight: '0px'}} >
                    <Avatar sx={{ backgroundColor: '#F5F8FF' }}>
                      <ContentCopyRoundedIcon />
                    </Avatar>
                  </Grid>
                  <Grid item xs={11} sm={11} md={11} lg={11} xl={11}>
                    <div>
                      <Typography variant="caption" marginBottom={0} paddingBottom={0}>
                        <strong>Faça aqui o upload dos seus documentos</strong>
                      </Typography>
                      <div>
                        <Typography variant="caption" marginBottom={0} paddingBottom={0}>
                          Arquivos suportados nos seguintes formatos: PDF, PNG, JPEG e JPG, com tamanho máximo de 10MBs.
                        </Typography>
                      </div>
                    </div>
                  </Grid>
                </Grid>

                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <FileUpload label="CNH (frente e verso) *" helperText={CNH_TOOLTIP_HELPER_TEXT} defaultValue={proposal.personFileCNH} imageUrl={proposal.personFileCNHURL} name='personFileCNH' isDisabled={isDisabled} onFileUpload={handleFileUpload} shouldShowHeader={true} isRequired={true}/>
                </Grid>

                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <FileUpload label="RG (frente) *" helperText={RG_FRONT_TOOLTIP_HELPER_TEXT} defaultValue={proposal.personFileRGFront} imageUrl={proposal.personFileRGFrontURL} name='personFileRGFront' isDisabled={isDisabled} onFileUpload={handleFileUpload}  isRequired={true}/>
                </Grid>

                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <FileUpload label="RG (verso) *" helperText={RG_BACK_TOOLTIP_HELPER_TEXT} defaultValue={proposal.personFileRGBack} imageUrl={proposal.personFileRGBackURL} name='personFileRGBack' isDisabled={isDisabled} onFileUpload={handleFileUpload}  isRequired={true}/>
                </Grid>

                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <FileUpload label="CPF *" helperText={CPF_TOOLTIP_HELPER_TEXT} defaultValue={proposal.personFileCPF} imageUrl={proposal.personFileCPFURL} name='personFileCPF' isDisabled={isDisabled} onFileUpload={handleFileUpload}  isRequired={true}/>
                </Grid>

                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <FileUpload label="Certidão de Estado Civil *" helperText={CIVIL_STATUS_TOOLTIP_HELPER_TEXT} defaultValue={proposal.personFileCertificateCivilStatus} imageUrl={proposal.personFileCertificateCivilStatusURL} name='personFileCertificateCivilStatus' isDisabled={isDisabled} onFileUpload={handleFileUpload}  isRequired={true}/>
                </Grid>

                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <FileUpload label="Comprovante de Endereço: Mês atual ou anterior *" helperText={ADDRESS_TOOLTIP_HELPER_TEXT} defaultValue={proposal.personFileAddressVerification} imageUrl={proposal.personFileAddressVerificationURL} name='personFileAddressVerification' isDisabled={isDisabled} onFileUpload={handleFileUpload}  isRequired={true}/>
                </Grid>

                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <FileUpload label="Último Holerite *" helperText={LATEST_PAY_STUB_TOOLTIP_HELPER_TEXT} defaultValue={proposal.personFileLatestPayStub} imageUrl={proposal.personFileLatestPayStubURL} name='personFileLatestPayStub' isDisabled={isDisabled} onFileUpload={handleFileUpload}  isRequired={true}/>
                </Grid>

                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <FileUpload label="Penúltimo Holerite *" helperText={PENULTIMATE_PAY_STUB_TOOLTIP_HELPER_TEXT} defaultValue={proposal.personFilePenultimatePayStub} imageUrl={proposal.personFilePenultimatePayStubURL} name='personFilePenultimatePayStub' isDisabled={isDisabled} onFileUpload={handleFileUpload}  isRequired={true}/>
                </Grid>

                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <FileUpload label="Declaração Completa do Imposto de Renda *" helperText={INCOME_TAX_TOOLTIP_HELPER_TEXT} defaultValue={proposal.personFileIncomeTax} imageUrl={proposal.personFileIncomeTaxURL} name='personFileIncomeTax' isDisabled={isDisabled} onFileUpload={handleFileUpload}  isRequired={true}/>
                </Grid>

                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <FileUpload label="Recibo de Entrega do Imposto de Renda *" helperText={INCOME_TAX_RECEIPT_TOOLTIP_HELPER_TEXT} defaultValue={proposal.personFileIncomeTaxReceipt} imageUrl={proposal.personFileIncomeTaxReceiptURL} name='personFileIncomeTaxReceipt' isDisabled={isDisabled} onFileUpload={handleFileUpload}  isRequired={true}/>
                </Grid>
              </Paper>
            )}
          </>}

          <Grid item xs={12} sm={12} md={6} lg={12} xl={12}>
            <FormGroup className={classes.checkBox}>
              <Controller
                name="agreedPrivacyPolicyAndTermsOfUse"
                control={control}
                render={({ field }) => (
                  <FormControlLabel
                    control={
                      <Checkbox
                        onChange={(e) => {
                          field.onChange(e.target.checked)
                          setProposal({ ...proposal, agreedPrivacyPolicyAndTermsOfUse: !!e.target.value })
                        }}
                        checked={field.value}
                      />
                    }
                    label={
                      <Typography variant="caption" display="block" gutterBottom>
                        Li e concordo com a <Link href="https://www.finteto.com/privacidade" variant="body2" target='_blank'>Política de Privacidade</Link> e <Link href="https://www.finteto.com/termos-de-uso" variant="body2" target='_blank'>Termos de Uso</Link> da Finteto.
                      </Typography>
                    }
                    disabled={isDisabled}
                  />
                )}
              />
            </FormGroup>
          </Grid>

        </Grid>

        <Grid container spacing={1}>
          {!isEditing &&
            <Button variant="contained" type="submit" sx={{ mt: 1, mb: 1 }} disabled={watch('agreedPrivacyPolicyAndTermsOfUse') !== true }>
              Enviar
            </Button>
          }

          <Button onClick={handleBack} sx={{ mt: 1, mr: 1, ml: 1 }}>
            Voltar
          </Button>
        </Grid>

      </FormProvider>
    </form>
  )
}
