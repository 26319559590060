import itauLogo from 'assets/banks-logo/itau.png'
import bradescoLogo from 'assets/banks-logo/bradesco.webp'
import caixaLogo from 'assets/banks-logo/caixa.svg'
import brbLogo from 'assets/banks-logo/brb.svg'
import interLogo from 'assets/banks-logo/inter.webp'

export interface BankRates {
  logo: string,
  bankName: string,
  traditional: string,
  savings: string,
  cgi: string,
}

export const homeData: BankRates[] = [
  {
    logo: itauLogo,
    bankName: 'Banco Itaú',
    traditional: 'de 10,39% à 10,99% a.a. + TR',
    savings: 'desde 5,49% a.a. + rem. poupança',
    cgi: 'de 19% a.a. a 22,7% a.a.'
  },
  {
    logo: bradescoLogo,
    bankName: 'Banco Bradesco',
    traditional: 'de 10,49% à 11,49% a.a. + TR',
    savings: 'desde 4,49% a.a. + rem. poupança',
    cgi: 'desde 1,39% a.m.'
  },
  {
    logo: caixaLogo,
    bankName: 'Caixa Econômica Federal',
    traditional: 'desde 8,99% a.a. + TR',
    savings: 'desde 3,10% a.a. + rem. poupança',
    cgi: 'sob consulta'
  },
  {
    logo: brbLogo,
    bankName: 'Banco de Brasilia',
    traditional: 'desde 8,95% a.a. + TR',
    savings: 'desde 4,09% a.a. + rem. poupança',
    cgi: 'sob consulta'
  },
  {
    logo: interLogo,
    bankName: 'Banco Inter',
    traditional: 'desde 9% a.a. + IPCA',
    savings: 'desde 7,58% a.a. + TR',
    cgi: 'desde 1,19% a.m. + IPCA'
  },
];
