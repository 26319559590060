import { ProfileProvider } from 'contexts/profile-context'
import React from 'react'

import { ApplicationProvider } from '../contexts/application-context'
import { AuthProvider } from './auth'
import { ProposalProvider } from 'contexts/proposal-context'

const AppProvider: React.FC = ({ children }) => (
  <AuthProvider>
    <ApplicationProvider>
      <ProfileProvider>
        <ProposalProvider>
          {children}
        </ProposalProvider>
      </ProfileProvider>
    </ApplicationProvider>
  </AuthProvider>
)

export default AppProvider
