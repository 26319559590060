import React, { useState, useRef, useCallback, useEffect, useContext, ChangeEvent } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { Box, Paper, Typography, StepContent, Button, CircularProgress, Stack, StepConnector, stepConnectorClasses, StepIconProps, TextField, Grid, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, RadioGroup, FormControlLabel, Radio } from '@mui/material'
import { ProfileType } from 'hooks/enums/ProfileType'
import { useProfile } from 'hooks/use-profile'
import { ProposalStatus, showProposalPerformanceStatus, showProposalStatus } from 'hooks/enums/ProposalStatus'
import { useStyles } from './style'
import api from 'services/api'

type Props = {
  proposalID: string
  isOpen: boolean
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
}

const DeclineProposalDialog = ({ proposalID, isOpen, setIsOpen }: Props) => {
  const { selectedProfile } = useProfile();
  const classes = useStyles()
  const history = useHistory()

  const isClient = selectedProfile === ProfileType.CLIENTE
  const [isLoading, setIsLoading] = useState(false);
  const [mainError, setMainError] = useState('')
  const [observation, setObservation] = useState('');
  const [declinedReason, setDeclinedReason] = useState<ProposalStatus>(ProposalStatus.DECLINED_ABANDONMENT);

  const handleCloseDeclineProposalDialog = () => {
    setIsOpen(false)
  }

  const handleSubmitDeclineProposalDialog = () => {
    const payLoad = isClient ? {
      declineReason: ProposalStatus.DECLINED_BY_CLIENT
    } : {
      declineReason: declinedReason,
      declineObservation: observation,
    }

    setIsLoading(true)

    api
      .post(`proposals/actions/decline/${proposalID}`, payLoad)
      .then(history.push('/performance'))
      .catch(error => {
        setMainError('Erro ao gravar sua proposta, por favor tente novamente mais tarde')
      }).finally(() => {
        setIsLoading(false)
      })
    setIsOpen(false)
  }

  if(isLoading) {
    return <CircularProgress color="primary" className={classes.spinner}/>
  }

  return (
    <Dialog
      open={isOpen}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className={classes.roundedDialog}
    >
      <DialogTitle id="alert-dialog-title" style={{ textAlign: 'center', margin: '20px', fontSize: '22px' }}>{isClient ? 'Anular Proposta' : 'Recusar Proposta'} </DialogTitle>

      <DialogContent style={{ minWidth: '500px' }}>
        <DialogContentText id="alert-dialog-description">
          { isClient ?
            (
              <Typography textAlign='center'>
                Tem certeza que deseja anular esta proposta? uma vez anulada, não será possível restaurá-la.
              </Typography>
            ) : (
              <>
                <Typography>
                  Selecione o motivo da Recusa:
                </Typography>
                <RadioGroup
                  value={declinedReason}
                  onChange={(e) => setDeclinedReason(e.target.value as ProposalStatus)}
                  style={{ margin: '15px' }}
                >
                  <FormControlLabel value={ProposalStatus.DECLINED_ABANDONMENT} control={<Radio size='small' />} label={showProposalStatus(ProposalStatus.DECLINED_ABANDONMENT)} />
                  <FormControlLabel value={ProposalStatus.DECLINED_COMPETITOR_CONDITIONS} control={<Radio size='small'/>} label={showProposalStatus(ProposalStatus.DECLINED_COMPETITOR_CONDITIONS)} />
                  <FormControlLabel value={ProposalStatus.DECLINED_CREDIT_REFUSAL} control={<Radio size='small'/>} label={showProposalStatus(ProposalStatus.DECLINED_CREDIT_REFUSAL)} />
                  <FormControlLabel value={ProposalStatus.DECLINED_LACK_OF_INFORMATION} control={<Radio size='small'/>} label={showProposalStatus(ProposalStatus.DECLINED_LACK_OF_INFORMATION)} />
                  <FormControlLabel value={ProposalStatus.DECLINED_BY_CLIENT} control={<Radio size='small'/>} label={showProposalStatus(ProposalStatus.DECLINED_BY_CLIENT)} />
                </RadioGroup>

                <Typography>
                  Observação:
                </Typography>

                <TextField
                  id="personNationality"
                  name="personNationality"
                  variant="outlined"
                  margin="dense"
                  size="small"
                  fullWidth={true}
                  InputLabelProps={{
                    shrink: true
                  }}
                  inputProps={{
                    maxLength: 250
                  }}
                  value={observation}
                  onChange={(e) => setObservation(e.target.value)}
                />
              </>
            )
          }

        </DialogContentText>
      </DialogContent>

      <DialogActions style={{ justifyContent: 'center', marginBottom: '30px' }} >
        <Button onClick={handleCloseDeclineProposalDialog} variant="outlined">Cancelar</Button>
        <Button onClick={handleSubmitDeclineProposalDialog} variant="contained">Continuar</Button>
      </DialogActions>
    </Dialog>
  )
}

export default DeclineProposalDialog
