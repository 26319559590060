import React, { useState, useEffect, useRef, useCallback, useMemo } from 'react'
import { useParams, useHistory, useLocation } from 'react-router-dom'
import { Box, Paper, Grid, TextField, InputLabel, MenuItem, Typography, Tabs, Tab, Button } from '@mui/material'
import TextareaAutosize from '@mui/base/TextareaAutosize';
import { Checkbox } from '@mui/material'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import InfoIcon from '@mui/icons-material/Info';
import { FormHeader, FormAlert, CustomTable } from 'components'
import ListIcon from '@mui/icons-material/List'
import * as yup from 'yup'
import { useStyles } from './styles'
import api from 'services/api'
import { IInviteDTO } from 'data/dtos/operation/i-invite-dto'
import { useProfile } from 'hooks/use-profile';
import { FormatTableFieldType, ITableHeadCellDTO } from 'data/dtos/components/i-table-head-cell-dto';
import { ProfileType } from 'hooks/enums/ProfileType';
import { createMask } from 'imask';
import { cnpjMask } from 'utils/utils';
import { FINTETO_ORGANIZATION_ID } from 'hooks/enums/OrganizationID';
import { GetUsersResponse, getUsers } from 'services/users';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import SendIcon from '@mui/icons-material/Send';
import DefaultModal, { DialogType } from 'components/modal';
import { ServerErrorResponse } from 'shared/types';
import { AxiosError } from 'axios';

interface IRouteParams {
  id: string
}

const headCells: ITableHeadCellDTO[] = [
  {
    id: 'name',
    label: 'Nome',
    width: 2
  },
  {
    id: 'email',
    label: 'Email',
    width: 3
  },
  {
    id: 'status',
    label: 'Status',
    width: 1,
    format: FormatTableFieldType.INVITE_STATUS
  },
  {
    id: 'updatedAt',
    label: 'Email Enviado em',
    width: 2,
    format: FormatTableFieldType.DATE
  },
  {
    id: 'expiryAt',
    label: 'Data Expiração',
    width: 3,
    format: FormatTableFieldType.DATE
  },
]

const usersHeadCells: ITableHeadCellDTO[] = [
  {
    id: 'name',
    label: 'Nome',
    width: 2
  },
  {
    id: 'email',
    label: 'Email',
    width: 3
  },
  {
    id: 'organizationName',
    label: 'Parceiro',
    width: 3
  },
  {
    id: 'createdAt',
    label: 'Data de Registro',
    width: 3,
    format: FormatTableFieldType.DATE
  },
]

function tabsProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  }
}

const InviteForm: React.FC = () => {
  const { search: searchLocation } = useLocation();
  const searchParams = useMemo(() => new URLSearchParams(searchLocation), [searchLocation]);
  const profileID = searchParams.get('profileID') as ProfileType
  let title = 'Assessores'

  switch (profileID) {
    case ProfileType.ASSESSOR:
      title = 'Assessores';
      break;
    case ProfileType.GESTOR:
      title = 'Gestores';
      break;
    case ProfileType.OFFICER:
      title = 'Officers';
      break;
    case ProfileType.CLIENTE:
      title = 'Clientes';
      break;
  }

  const [mainError, setMainError] = useState('')

  const { selectedProfile } = useProfile();

  const [currentTab, setCurrentTab] = useState(0)

  const [loading, setLoading] = useState(0)
  const [search, setSearch] = useState('')
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(100)
  const [orderByDirection, setOrderByDirection] = useState(true)
  const [rowsCount, setRowsCount] = useState(0)
  const [inviteList, setInviteList] = useState<IInviteDTO[]>([])
  const [usersList, setUsersList] = useState<GetUsersResponse[]>([])
  const [recordToResend, setRecordToResend] = useState<string | null>('')
  const [recordToCancel, setRecordToCancel] = useState<string | null>('')
  const [columnOrder, setColumnOrder] = useState<('ASC' | 'DESC')[]>([])
  const [organizationss, setOrganizationsCategoriesA] = useState([])
  const [organizationsList, setOrganizationsList] = useState([])

  const [isResendDialogOpen, setIsResendDialogOpen] = useState<boolean>(false)
  const [isCancelDialogOpen, setIsCancelDialogOpen] = useState<boolean>(false)
  const [inviteToDelete, setInviteToDelete] = useState<string>()
  const [paperMaxHeight, setPaperMaxHeight] = useState<string>()

  const handleOpenResendDialog = async(inviteID: string) => {
    await handleResendInvite(inviteID)
    setIsResendDialogOpen(true)
  }

  const handleCloseResendDialog = () => {
    setIsResendDialogOpen(false)
  }

  const handleCloseCancelDialog = () => {
    setIsCancelDialogOpen(false)
  }

  const handleConfirmCancelDialog = async () => {
    await handleCancelInvite(inviteToDelete)
    handleCloseCancelDialog()
  }

  const handleOpenCancelDialog =  (inviteID: string) => {
    setInviteToDelete(inviteID)
    setIsCancelDialogOpen(true)
  }

  const params = useParams<IRouteParams>()
  const firstInputElement = useRef(null)
  const classes = useStyles()
  const history = useHistory()

  const isFintetoUser = selectedProfile === ProfileType.ADMIN || selectedProfile === ProfileType.OFFICER
  const isOfficerTarget = profileID === ProfileType.OFFICER
  const isOrganizationSelectorEnabled = isFintetoUser && !isOfficerTarget

  const nameEmailGridSize = isOrganizationSelectorEnabled
  ? { xs: 12, sm: 12, md: 6, lg: 6, xl: 3 }
  : { xs: 12, sm: 12, md: 4, lg: 5, xl: 5 };

  const validationSchema = yup.object().shape({
    name: yup.string()
      .required('Campo obrigatório'),
    email: yup.string()
      .required('Campo obrigatório')
      .email('Formato de email inválido'),
  })

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    watch,
    formState: { errors },
    control
  } = useForm<IInviteDTO>({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      name: '',
      email: '',
      organizationID: FINTETO_ORGANIZATION_ID,
    }
  })

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setPaperMaxHeight(newValue === 0 ? "calc(100vh - 290px)" : "calc(100vh - 210px)")
    setCurrentTab(newValue)
  }

  const handleCancelInvite = async(inviteID: string) => {
    try {
      await api
        .post(`/invites/${inviteID}/cancel`)
      loadInvites()
    } catch (err) {
      const error = err as AxiosError<ServerErrorResponse>
      setMainError(error.response.data.error.message)
    }
  }

  const handleResendInvite = async(inviteID: string) => {
    try {
      await api
        .post(`/invites/${inviteID}/resend`)
    } catch (err) {
      const error = err as AxiosError<ServerErrorResponse>
      setMainError(error.response.data.error.message)
    }
  }

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number, ) => {
    setPage(newPage);
  }

  const handleChangeRowsPerPage = async (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  // data save
  const onSubmit = useCallback(async (data: IInviteDTO) => {
    const payLoad: IInviteDTO = {
      inviterUserID: data.inviterUserID,
      organizationID: isOrganizationSelectorEnabled ? data.organizationID : null,
      profileID,
      name: data.name,
      email: data.email,
    }

    await api
      .post('/invites', payLoad)
      .then(() => reset())
      .then(() => loadInvites())
      .catch(error => {

        setMainError(error.response.data.error.message)
        return error.response.data.error.message
      })
  }, [profileID, selectedProfile])


  const loadInvites = useCallback(async () => {
    setLoading(1)
    await api
      .post('/invites/list', { search, page, rowsPerPage, columnOrder, profileIDs: [profileID] })
      .then(async listResponse => {
        setInviteList(listResponse.data)
      })
      setLoading(0)
  }, [profileID, search])

  const loadUsers = useCallback(async () => {
    setLoading(1)
    const response = await getUsers({ search, page, rowsPerPage, columnOrder, profileIDs: [profileID] })
    setUsersList(response)
    setLoading(0)
  }, [profileID, search])

  const loadOrganizations = useCallback(async () => {
    setLoading(1)

    await api
      .post('/organizations/list', { search, page, rowsPerPage, columnOrder, onlyActive: true })
      .then(async listResponse => {
        const { data } = listResponse.data
        setOrganizationsList(data)

        setValue('organizationID', data[0].organizationID)

        await api
          .post('/organizations/count', {search})
          .then(countResponse => {
            const { count } = countResponse.data.data
            setRowsCount(count)
          })
          .then(() => setLoading(0))
          .catch(error => {
            return error
          })
      })
      .catch(error => {
        return error
      })
  }, [])

  useEffect(() => {
    if(currentTab === 0) {
      loadInvites()
      loadOrganizations()
    } else {
      loadUsers()
    }

  }, [loadInvites, loadOrganizations, loadUsers, search, currentTab])


  const handleChange = (formField: any) => {
    setMainError('')
  }

  return (
    <Paper elevation={0} className={classes.paper} sx={{ maxHeight: paperMaxHeight }}>
      <Box
        component="form"
        onSubmit={handleSubmit(onSubmit)}
        noValidate
        data-testid="form"
      >
        <FormHeader
          title={title}
          icon={ListIcon}
          backRoute="/invites"
          showSaveButton={false}
        />

        <FormAlert setMainError={setMainError} mainError={mainError} />

        <Box sx={{ borderBottom: 1, borderColor: 'divider', height: '100%' }}>
          <Tabs value={currentTab} onChange={handleTabChange} aria-label="basic tabs example">
            <Tab label="Convites" {...tabsProps(0)} />
            <Tab label="Usuários" {...tabsProps(1)} />
          </Tabs>
        </Box>
      </Box>

      {currentTab === 0 &&
        <>
          <Grid container className={classes.formContainer} paddingTop={2}>
            <Grid {...nameEmailGridSize}>
              <Typography variant="caption" display="block" gutterBottom>
                Nome
              </Typography>
              <TextField
                id="name"
                error={!!errors.name}
                helperText={errors?.name?.message}
                variant="outlined"
                margin="dense"
                size="small"
                fullWidth={true}
                InputLabelProps={{
                  shrink: true
                }}
                inputProps={{
                  maxLength: 60
                }}
                {...register("name",
                  { onChange: (e) => handleChange(e) }
                )}
              />
            </Grid>

            <Grid {...nameEmailGridSize} className={classes.gridBox}>
              <Typography variant="caption" display="block" gutterBottom>
                Email
              </Typography>
              <TextField
                id="email"
                error={!!errors.email}
                helperText={errors?.email?.message}
                variant="outlined"
                margin="dense"
                size="small"
                fullWidth={true}
                InputLabelProps={{
                  shrink: true
                }}
                inputProps={{
                  maxLength: 60
                }}
                {...register("email",
                  { onChange: (e) => handleChange(e) }
                )}
              />
            </Grid>

            { isOrganizationSelectorEnabled &&
              <Grid item xs={12} sm={12} md={8} lg={10} xl={4} className={classes.gridBox}>
                <Typography variant="caption" display="block" gutterBottom>
                  Parceiro
                </Typography>
                <TextField
                  id="organizationID"
                  error={!!errors.organizationID}
                  helperText={errors?.organizationID?.message}
                  variant="outlined"
                  margin="dense"
                  size="small"
                  fullWidth={true}
                  value={watch('organizationID')}
                  select
                  {...register("organizationID", { onChange: (e) => {
                    setValue("organizationID", e.target.value)
                    handleChange(e)
                  }})}
                >
                {organizationsList.map((organization) => (
                  <MenuItem
                    key={organization.id}
                    value={organization.id}
                  >
                    {`${createMask({ mask: cnpjMask }).resolve(organization.cnpj)} - ${organization.legalName}`}
                  </MenuItem>
                ))}
                </TextField>
              </Grid>
            }

            <Grid item xs={12} sm={12} md={4} lg={2} xl={2} className={classes.gridBox}>
              <Button
                type="button"
                variant="contained"
                color="primary"
                style={{marginTop: '20px', marginBottom: '8px'}}
                fullWidth
                onClick={handleSubmit(onSubmit)}
              >
                Enviar Convite
              </Button>
            </Grid>
          </Grid>

          <CustomTable
            headCells={headCells}
            rows={inviteList}
            totalRows={rowsCount}
            handleSearch={setSearch}
            isLoading={loading}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            rowsPerPage={rowsPerPage}
            handleChangePage={handleChangePage}
            page={page}
            columnOrder={columnOrder}
            setColumnOrder={setColumnOrder}
            orderByDirection={orderByDirection}
            setOrderByDirection={setOrderByDirection}
            buttons={[
              {
                name: 'Cancelar Convite',
                icon: HighlightOffIcon,
                onClick: (inviteID) => {
                  handleOpenCancelDialog(inviteID)
                }
              },
              {
                name: 'Reenviar Convite',
                icon: SendIcon,
                onClick: (inviteID) => {
                  handleOpenResendDialog(inviteID)
                }
              },
            ]}
          />
        </>
      }

      {currentTab === 1 &&
        <CustomTable
          headCells={usersHeadCells}
          rows={usersList}
          totalRows={rowsCount}
          handleSearch={setSearch}
          isLoading={loading}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          rowsPerPage={rowsPerPage}
          handleChangePage={handleChangePage}
          page={page}
          columnOrder={columnOrder}
          setColumnOrder={setColumnOrder}
          orderByDirection={orderByDirection}
          setOrderByDirection={setOrderByDirection}
          buttons={[
            {
              name: "Detalhes",
              icon: InfoIcon,
              onClick(userID) {
                history.push(`users/edit/${userID}`)
              },
            }
          ]}
        />
      }

      <DefaultModal
        isOpen={isResendDialogOpen}
        title='Reenvio de convite'
        bodyContent='Um novo convite acabou de ser ser enviado ao Usuário.'
        onCloseModal={handleCloseResendDialog}
        type={DialogType.CONFIRMATION}
      />

      <DefaultModal
        isOpen={isCancelDialogOpen}
        title='Cancelamento de Convite'
        bodyContent='Deseja realmente cancelar o convite? Caso necessário, você poderá convidar este usuário novamente mais tarde.'
        type={DialogType.ACTION}
        onCloseModal={handleCloseCancelDialog}
        onConfirmModal={handleConfirmCancelDialog}
      />

    </Paper>
  )
}

export default InviteForm
