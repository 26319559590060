export enum RealEstateSituation {
  NEW = 'NEW',
  USED = 'USED',
}

export const showRealStateSituation = (realStateSituation: RealEstateSituation): string => {
  switch (realStateSituation) {
    case RealEstateSituation.NEW:
      return 'Novo'
    case RealEstateSituation.USED:
      return 'Usado'
    default:
      return 'Tipo de situação inválido'
  }
}
