import React, { useState, useRef, useCallback, useEffect, useContext, ChangeEvent } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { Box, Paper, Typography, StepContent, Button, CircularProgress, Stack, StepConnector, stepConnectorClasses, StepIconProps, TextField, Grid, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, RadioGroup, FormControlLabel, Radio } from '@mui/material'
import { useStyles } from './style'
import api from 'services/api'
import { ProposalPendencyReason, showProposalPendingReason } from 'hooks/enums/ProposalPendingReason'

type Props = {
  proposalID: string
  isOpen: boolean
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
}

const AddPendingToProposalDialog = ({ proposalID, isOpen, setIsOpen }: Props) => {
  const classes = useStyles()
  const history = useHistory()

  const [isLoading, setIsLoading] = useState(false);
  const [mainError, setMainError] = useState('')
  const [reason, setReason] = useState<ProposalPendencyReason>(ProposalPendencyReason.MISSING_OR_INCORRECT_DOCUMENTATION);

  const handleCloseAddPendingToProposalDialog = () => {
    setIsOpen(false)
  }

  const handleSubmitAddPendingToProposalDialog = () => {
    setIsLoading(true)

    api
      .post(`proposals/actions/add-pendency/${proposalID}`, { reason })
      .then(history.push('/performance'))
      .catch(error => {
        setMainError('Erro ao adicionar pendência na proposta, por favor tente novamente mais tarde')
      }).finally(() => {
        setIsLoading(false)
      })
  }

  if(isLoading) {
    return <CircularProgress color="primary" className={classes.spinner}/>
  }

  return (
    <Dialog
      open={isOpen}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className={classes.roundedDialog}
    >
      <DialogTitle id="alert-dialog-title" style={{ textAlign: 'center', margin: '20px', fontSize: '22px' }}>Adicionar Pendencia</DialogTitle>

      <DialogContent style={{ minWidth: '500px' }}>
        <DialogContentText id="alert-dialog-description">

          <Typography>
            Selecione a pendência:
          </Typography>
          <RadioGroup
            value={reason}
            onChange={(e) => setReason(e.target.value as ProposalPendencyReason)}
            style={{ margin: '15px' }}
          >
            <FormControlLabel value={ProposalPendencyReason.MISSING_OR_INCORRECT_DOCUMENTATION} control={<Radio size='small' />} label={showProposalPendingReason(ProposalPendencyReason.MISSING_OR_INCORRECT_DOCUMENTATION)} />
          </RadioGroup>

        </DialogContentText>
      </DialogContent>

      <DialogActions style={{ justifyContent: 'center', marginBottom: '30px' }} >
        <Button onClick={handleCloseAddPendingToProposalDialog} variant="outlined">Cancelar</Button>
        <Button onClick={handleSubmitAddPendingToProposalDialog} variant="contained">Continuar</Button>
      </DialogActions>
    </Dialog>
  )
}

export default AddPendingToProposalDialog
