import { ProfileType } from "hooks/enums/ProfileType"
import api from "services/api"

interface GetUsersRequest {
  search?: string
  page?: number
  rowsPerPage?: number
  columnOrder?: ('ASC' | 'DESC')[]
  profileIDs?: ProfileType[]
  organizationID?: string
}

export interface GetUsersResponse {
  id: string,
  userGroupId: string,
  userGroupName: string,
  name: string,
  email: string,
  blockReasonId: string,
  description: string,
  organizationID: string,
  organizationName: string,
  createdAt: Date,
  updatedAt: Date
}

export interface UpdateUserRequest {
  id: string,
  name: string,
  cpf: string,
  email: string,
  phoneNumber: string,
  organizationID: string,
  responsibleUserID: string,
}

export interface UpdateProposalRequest {
  proposalID: string,
  payload: {
    officerUserID: string
  },
  shouldNotifyOfficer: boolean
}

export const getUsers = async ({ search = '', page = 0, rowsPerPage = 100, columnOrder = [], profileIDs, organizationID }: GetUsersRequest): Promise<GetUsersResponse[]> => {
  const { data: userList } = await api.post<GetUsersResponse[]>('/users-security/list', { search, page, rowsPerPage, columnOrder, profileIDs, organizationID })
  return userList
}

export const UpdateUsers = async(updateUserRequest: UpdateUserRequest): Promise<void> => {
  await api.put('/users-security', updateUserRequest)
}

export const UpdateProposal = async({ proposalID, payload, shouldNotifyOfficer }: UpdateProposalRequest): Promise<void> => {
  await api.put(`/proposals/${proposalID}?shouldNotifyOfficer=${shouldNotifyOfficer}`, payload);
}
