import { makeStyles } from '@mui/styles'

const useStyles = makeStyles(() => ({
	footer: {
    flexDirection: 'column',
    justifyContent: 'center',
		alignItems: 'center',
  	backgroundColor: '#E7EAED',
		padding: '30px',
		width: '100%',
	},

	copyright: {
    flexDirection: 'column',
    justifyContent: 'center',
		alignItems: 'center',
	},

	policy: {
    flexDirection: 'row',
    justifyContent: 'center',
		alignItems: 'center',
	}
}))

export { useStyles }
