export enum ProposalDataFormStep {
  PERSON_PERSONAL_DATA = 0,
  PERSON_PROFESSIONAL_DATA = 1,
  SPOUSE_PERSONAL_DATA = 2,
  SPOUSE_PROFESSIONAL_DATA = 3,
  REAL_STATE_DATA = 4,
  FINANCING_DATA = 5,
  PUBLIC_BANK_DATA = 6,
  COMPLETED = 7
}
