export enum IncomeType {
  SALARIED_EMPLOYEE = 'SALARIED_EMPLOYEE',
  SELF_EMPLOYED = 'SELF_EMPLOYED',
  ENTREPRENEUR = 'ENTREPRENEUR',
  SELF_EMPLOYED_PROFESSIONAL = 'SELF_EMPLOYED_PROFESSIONAL',
  RURAL_PRODUCER = 'RURAL_PRODUCER',
  RETIRED = 'RETIRED',
  PENSIONER = 'PENSIONER',
  HOME_MAKER = 'HOME_MAKER',
  INTERN = 'INTERN',
  NEVER_WORKED = 'NEVER_WORKED',
  UNEMPLOYED = 'UNEMPLOYED'
}

export const showIncomeType = (incomeType: IncomeType): string => {
  switch (incomeType) {
    case IncomeType.SALARIED_EMPLOYEE:
      return 'Assalariado'
    case IncomeType.SELF_EMPLOYED:
      return 'Autônomo'
    case IncomeType.ENTREPRENEUR:
      return 'Empresário'
    case IncomeType.SELF_EMPLOYED_PROFESSIONAL:
      return 'Profissional Liberal'
    case IncomeType.RURAL_PRODUCER:
      return 'Produtor Rural'
    case IncomeType.RETIRED:
      return 'Aposentado'
    case IncomeType.PENSIONER:
      return 'Pensionista'
    case IncomeType.HOME_MAKER:
      return 'Do lar'
    case IncomeType.INTERN:
      return 'Estagiário'
    case IncomeType.NEVER_WORKED:
      return 'Nunca trabalhou'
    case IncomeType.UNEMPLOYED:
      return 'Não empregado'
    default:
      return 'Tipo de renda inválido'
  }
}
