import { Button, FormControl, Grid } from "@mui/material";
import { ProposalContext } from "contexts/proposal-context"
import { IProposalDTO } from "data/dtos/i-proposal-dto";
import { useContext } from "react"
import { useForm } from "react-hook-form";
import { useStyles } from "../../styles";
import { ProposalStatus } from "hooks/enums/ProposalStatus";
import { ProposalDisbursementFormStep } from "./form-step";

type Props = {
  isDisabled: boolean
  setProposalFormStep: React.Dispatch<React.SetStateAction<ProposalDisbursementFormStep>>
}

export const PaymentStatusForm = ({ isDisabled, setProposalFormStep }: Props) => {
  const { proposal, setProposal } = useContext(ProposalContext);
  const classes = useStyles()

  const handleBack = () => {
    setProposal({...proposal, status: ProposalStatus.AWAITING_DISBURSEMENT})
    setProposalFormStep(ProposalDisbursementFormStep.SCHEDULED_PAYMENT_DATA)
  }

  const saveData = (spousePersonalData: IProposalDTO) => {
    setProposal({...proposal, status: ProposalStatus.AWAITING_COMMISSION_GENERATED})
    setProposalFormStep(ProposalDisbursementFormStep.COMMISSION_DATA)
  }

  const {
    handleSubmit,
    formState: { errors },
  } = useForm<IProposalDTO>({
    defaultValues: {
      realEstateAppraisalValue: proposal.realEstateAppraisalValue || '',
    },
    reValidateMode: 'onChange'
  })

  return (
    <form onSubmit={handleSubmit(saveData)}>
      <FormControl >
        <Grid container spacing={1} className={classes.formContainer}>

          <Grid container spacing={1}>
            <Button
              onClick={handleBack}
              sx={{ mt: 1, mr: 1 }}
              disabled={isDisabled}
            >
               Voltar
            </Button>
            <Button
              variant="contained"
              type="submit"
              sx={{ mt: 1, mb: 1 }}
              disabled={isDisabled}
            >
              Sim
            </Button>
          </Grid>
        </Grid>
      </FormControl>
    </form>
  )
}
