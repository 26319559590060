import React, { useState, useEffect, useRef, useCallback } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { Box, Paper, Grid, TextField, InputLabel, MenuItem, Typography } from '@mui/material'
import TextareaAutosize from '@mui/base/TextareaAutosize';
import { Checkbox } from '@mui/material'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { FormHeader, FormAlert } from 'components'
import ListIcon from '@mui/icons-material/List'
import * as yup from 'yup'
import { useStyles } from './styles'
import api from 'services/api'
import { INotificationDestinationDTO } from 'data/dtos/operation/i-notification-destination-dto'

interface IRouteParams {
  id: string
}

const NotificationDestinationForm: React.FC = () => {
  const [mainError, setMainError] = useState('')
  const [notificationsA, setNotificationsA] = useState([])
  const [personsB, setPersonsB] = useState([])

  const params = useParams<IRouteParams>()
  const firstInputElement = useRef(null)
  const classes = useStyles()
  const history = useHistory()

  const validationSchema = yup.object().shape({
    notificationId: yup.string()
      .required('Campo obrigatório'),
    destinationId: yup.string()
      .required('Campo obrigatório'),
    datetimeView: yup.string()
      .required('Campo obrigatório'),
  })

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    watch,
    formState: { errors },
    control
  } = useForm<INotificationDestinationDTO>({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      notificationId: null,
      destinationId: null,
    }
  })

  // initial load

  useEffect(() => {
    async function loadData() {

      // select Notificação

      await api
        .post('/notifications/select')
        .then(response => {
          const { data } = response.data

          return data
        })
        .then((notificationsResult) => {
          setNotificationsA(notificationsResult)
        })
        .catch(error => {
          return error
        })

      // select Destinatário

      await api
        .post('/persons/select')
        .then(response => {
          const { data } = response.data

          return data
        })
        .then((personsResult) => {
          setPersonsB(personsResult)
        })
        .catch(error => {
          return error
        })
    }

    loadData()
  }, [])


  // main data load

  useEffect(() => {
    async function loadData() {
      const { id } = params

      // form data

      await api
        .get(`/notification-destinations/${id}`)
        .then(response => {
          const { data } = response.data

          const notificationDestinationResult = {
            notificationId: data.notificationId.id,
            destinationId: data.destinationId.id,
            datetimeView: data.datetimeView,
          }

          return notificationDestinationResult
        })
        .then((notificationDestinationResult: INotificationDestinationDTO) => {
          reset(notificationDestinationResult)
        })
        .catch(error => {
          return error
        })
    }

    if (params.id) {
      loadData()
    }
  }, [params, params.id])


  // data save

  const onSubmit = useCallback(async (data: INotificationDestinationDTO) => {
    const payLoad: INotificationDestinationDTO = {
      notificationId: data.notificationId,
      destinationId: data.destinationId,
      datetimeView: data.datetimeView,
    }

    if (params.id) {
      const { id } = params

      payLoad.id = id

      await api
        .put(`/notification-destinations`, payLoad)
        .then(history.push('/notification-destinations'))
        .catch(error => {
          setMainError(error.response.data.data.name)
          return error.response.data.data
        })
    } else {
      await api
        .post('/notification-destinations', payLoad)
        .then(history.push('/notification-destinations/new'))
        .then(() => reset())
        .then(() => setTimeout(() => { firstInputElement.current.focus() }, 0))
        .catch(error => {
          setMainError(error.response.data.data.name)
          return error.response.data.data
        })
    }
  }, [])


  const handleChange = (formField: any) => {
    setMainError('')
  }


  return (
    <Paper elevation={0} className={classes.paper}>
      <Box
        component="form"
        onSubmit={handleSubmit(onSubmit)}
        noValidate
        data-testid="form"
      >
        <FormHeader
          title="Destinatários"
          icon={ListIcon}
          backRoute="/notification-destinations"
          showBackButton={true}
          showSaveButton={true}
        />

        <FormAlert setMainError={setMainError} mainError={mainError} />

        <Paper elevation={1} className={classes.gridPaper}>
          <Grid container spacing={1} className={classes.formContainer}>

            <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Typography variant="caption" display="block" gutterBottom>
                  Notificação
                </Typography>
                <TextField
                  id="notificationId"
                  error={!!errors.notificationId}
                  helperText={errors?.notificationId?.message}
                  variant="outlined"
                  margin="dense"
                  size="small"
                  fullWidth={true}
                  value={`${watch().notificationId}`}
                  select
                  autoFocus
                  inputRef={firstInputElement}
                  {...register("notificationId", { onChange: (e) => {
                    setValue("notificationId", e.target.value)
                    handleChange(e)
                  }})}
                >
                {notificationsA.map((notification) => (
                  <MenuItem
                    key={notification.id}
                    value={notification.id}
                  >
                    {notification.subject}
                  </MenuItem>
                ))}
                </TextField>
              </Grid>
            </Grid>

            <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Typography variant="caption" display="block" gutterBottom>
                  Destinatário
                </Typography>
                <TextField
                  id="destinationId"
                  error={!!errors.destinationId}
                  helperText={errors?.destinationId?.message}
                  variant="outlined"
                  margin="dense"
                  size="small"
                  fullWidth={true}
                  value={`${watch().destinationId}`}
                  select
                  {...register("destinationId", { onChange: (e) => {
                    setValue("destinationId", e.target.value)
                    handleChange(e)
                  }})}
                >
                {personsB.map((person) => (
                  <MenuItem
                    key={person.id}
                    value={person.id}
                  >
                    {person.name}
                  </MenuItem>
                ))}
                </TextField>
              </Grid>
            </Grid>

            <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Typography variant="caption" display="block" gutterBottom>
                  Visualizado em
                </Typography>
                <TextField
                  id="datetimeView"
                  error={!!errors.datetimeView}
                  helperText={errors?.datetimeView?.message}
                  variant="outlined"
                  margin="dense"
                  size="small"
                  fullWidth={true}
                  type="date"
                  InputLabelProps={{
                    shrink: true
                  }}
                  {...register("datetimeView",
                    { onChange: (e) => handleChange(e) }
                  )}
                />
              </Grid>
            </Grid>

          </Grid>
        </Paper>
      </Box>
    </Paper>
  )
}

export default NotificationDestinationForm
