export enum ProposalPendencyReason {
  MISSING_OR_INCORRECT_DOCUMENTATION = 'MISSING_OR_INCORRECT_DOCUMENTATION',
}

export const showProposalPendingReason = (proposalPendingReason: ProposalPendencyReason): string => {
  switch (proposalPendingReason) {
    case ProposalPendencyReason.MISSING_OR_INCORRECT_DOCUMENTATION:
      return 'Falta ou documentação incorreta'
  }
}
