import { Box, Step, StepContent, StepLabel, Stepper, TextField, Typography } from "@mui/material"
import { useState } from "react"
import { SubmitProposal } from "../proposal-data/submit-proposal"
import { ScheduledPaymentDataForm } from "./scheduled-payment-data-form"
import { ProposalDisbursementFormStep } from "./form-step"
import { PaymentStatusForm } from "./payment-status-form"
import { CommissionDataForm } from "./commission-data-form"
import { ProposalStatus } from "hooks/enums/ProposalStatus"

type Props = {
  canEdit: boolean,
  proposalStatus: ProposalStatus
}

const propertyEvaluationFormSteps = [
  { label: 'Qual a data de efetivação do desembolso?' },
  { label: 'Pagamento ok?' },
  { label: 'Gerar Comissão' },
];

const getFormStepByProposalStatus = (proposalStatus: ProposalStatus): ProposalDisbursementFormStep => {
  switch(proposalStatus) {
    case ProposalStatus.AWAITING_DISBURSEMENT:
      return ProposalDisbursementFormStep.SCHEDULED_PAYMENT_DATA;
    case ProposalStatus.AWAITING_PAYMENT:
      return ProposalDisbursementFormStep.PAYMENT_STATUS_DATA;
    case ProposalStatus.AWAITING_COMMISSION_GENERATED:
      return ProposalDisbursementFormStep.COMMISSION_DATA;

    default:
      return ProposalDisbursementFormStep.SCHEDULED_PAYMENT_DATA
  }
}

export const DisbursementDataForm = ({ canEdit, proposalStatus }: Props) => {
  const [proposalFormStep, setProposalFormStep] = useState<ProposalDisbursementFormStep>(getFormStepByProposalStatus(proposalStatus));

  function getFormStep(formStep: ProposalDisbursementFormStep) {
    switch (formStep) {
      case ProposalDisbursementFormStep.SCHEDULED_PAYMENT_DATA:
        return <ScheduledPaymentDataForm isDisabled={!canEdit} setProposalFormStep={setProposalFormStep} />
      case ProposalDisbursementFormStep.PAYMENT_STATUS_DATA:
        return <PaymentStatusForm isDisabled={!canEdit} setProposalFormStep={setProposalFormStep} />
      case ProposalDisbursementFormStep.COMMISSION_DATA:
        return <CommissionDataForm isDisabled={!canEdit} setProposalFormStep={setProposalFormStep} />
    }
  }

  return (
    <>
      <Box sx={{ maxWidth: 1200, mx: 'auto', mt: 5, mb: 5 }}>
        <Stepper activeStep={proposalFormStep} orientation="vertical" >
          {propertyEvaluationFormSteps.map((step, index) => (
            <Step key={step.label}>
              <StepLabel>
                {step.label}
              </StepLabel>
              <StepContent>
                {getFormStep(proposalFormStep)}
              </StepContent>
            </Step>
          ))}
        </Stepper>
        {proposalFormStep === ProposalDisbursementFormStep.COMPLETED && (
          <SubmitProposal />
        )}
      </Box>
    </>
  )
}

