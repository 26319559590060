import {  Button, FormControl, Grid, MenuItem, TextField, Typography } from "@mui/material";
import { ProposalContext } from "contexts/proposal-context"
import { IProposalDTO } from "data/dtos/i-proposal-dto";
import { useContext, useEffect, useState } from "react"
import { useForm } from "react-hook-form";
import { useStyles } from "../../styles";
import { createMask } from "imask";
import { ComplementPlaceHolder, addressPlaceHolder, currencyMask, neighborhoodPlaceHolder, numberPlaceHolder, realEstatePricePlaceHolder, sanatizeStringValue, zipCodeMask, zipCodeMaskPlaceHolder } from "utils/utils";
import { RealEstateSituation, showRealStateSituation } from "hooks/enums/RealEstateSitutation";
import { RealEstateType, showRealStateType } from "hooks/enums/RealEstateType";
import api from "services/api";
import { MaritalStatus } from "hooks/enums/MaritalStatus";
import { invalidCepLength } from "utils/error-messages";
import { ProposalDataFormStep } from "./form-step";

type Props = {
  isDisabled: boolean
  setProposalFormStep: React.Dispatch<React.SetStateAction<ProposalDataFormStep>>
}

export const RealStateForm = ({ isDisabled, setProposalFormStep }: Props) => {
  const { proposal, setProposal } = useContext(ProposalContext);

  const [states, setStates] = useState([])
  const [cities, setCities] = useState([])
  const [selectedStateID, setSelectedStateID] = useState<string>(proposal.realEstateStateID)

  const classes = useStyles()

  const saveData = (personProfessionalData: IProposalDTO) => {
    setProposal({...proposal, ...personProfessionalData})
    setProposalFormStep(ProposalDataFormStep.FINANCING_DATA)
  }

  const handleBack = () => {
    setProposalFormStep(proposal.personMaritalStatus === MaritalStatus.MARRIED ? ProposalDataFormStep.SPOUSE_PROFESSIONAL_DATA : ProposalDataFormStep.PERSON_PROFESSIONAL_DATA)
  }

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm<IProposalDTO>({
    defaultValues: {
      realEstateAddress: proposal.realEstateAddress || '',
      realEstateComplement: proposal.realEstateComplement || '',
      realEstateNumber: proposal.realEstateNumber || '',
      realEstateNeighborhood: proposal.realEstateNeighborhood || '',
      realEstateZipCode: proposal.realEstateZipCode ||'',

      realEstateStateID: proposal.realEstateStateID || '',
      realEstateCityID: proposal.realEstateCityID || '',
      realEstateType: proposal.realEstateType || '',
      realEstateSituation: proposal.realEstateSituation || '',
      realEstatePrice: proposal.realEstatePrice || '',
    },
    reValidateMode: 'onChange'
  })

  useEffect(() => {
    async function loadData() {
      // select UF

      await api
        .post('/states/select')
        .then(response => {
          const { data } = response.data

          return data
        })
        .then((statesResult) => {
          setStates(statesResult)
        })
        .catch(error => {
          console.log(error)
          return error
        })
    }

    loadData()
  }, [])

  useEffect(() => {
    async function loadData() {
      // select Cidade
      await api
        .get(`/states/${selectedStateID}/cities`)
        .then(response => {
          return response.data
        })
        .then((citiesResult) => {
          setCities(citiesResult)
        })
        .catch(error => {
          console.log(error)
          return error
        })
    }

    if(selectedStateID) {
      loadData()
    }

  }, [selectedStateID])

  return (
    <form onSubmit={handleSubmit(saveData)}>
      <FormControl >
        <Grid container spacing={1} className={classes.formContainer}>

          <Grid item xs={12} sm={10} md={4} lg={4} xl={4}>
            <Typography variant="caption" display="block" gutterBottom >
              Endereço
            </Typography>
            <TextField
              id="realEstateAddress"
              name="realEstateAddress"
              error={!!errors.realEstateAddress}
              helperText={errors?.realEstateAddress?.message}
              placeholder={addressPlaceHolder}
              variant="outlined"
              margin="dense"
              size="small"
              fullWidth={true}
              InputLabelProps={{
                shrink: true
              }}
              inputProps={{
                maxLength: 100
              }}
              {...register("realEstateAddress", {
                required: "Campo Obrigatório",
                onChange: (e) => {
                  setProposal({ ...proposal, realEstateAddress: e.target.value })
                }
              })}
              disabled={isDisabled}
            />
          </Grid>

          <Grid item xs={12} sm={2} md={1} lg={1} xl={1}>
            <Typography variant="caption" display="block" gutterBottom >
              Número
            </Typography>
            <TextField
              id="realEstateNumber"
              name="realEstateNumber"
              error={!!errors.realEstateNumber}
              helperText={errors?.realEstateNumber?.message}
              placeholder={numberPlaceHolder}
              variant="outlined"
              margin="dense"
              size="small"
              fullWidth={true}
              InputLabelProps={{
                shrink: true
              }}
              inputProps={{
                maxLength: 5
              }}
              {...register("realEstateNumber", {
                required: "Campo Obrigatório",
                onChange: (e) => {
                  setProposal({ ...proposal, realEstateNumber: e.target.value })
                }
              })}
              disabled={isDisabled}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
            <Typography variant="caption" display="block" gutterBottom >
              Complemento
            </Typography>
            <TextField
              id="realEstateComplement"
              name="realEstateComplement"
              error={!!errors.realEstateComplement}
              helperText={errors?.realEstateComplement?.message}
              placeholder={ComplementPlaceHolder}
              variant="outlined"
              margin="dense"
              size="small"
              fullWidth={true}
              InputLabelProps={{
                shrink: true
              }}
              inputProps={{
                maxLength: 100
              }}
              {...register("realEstateComplement", {
                onChange: (e) => {
                  setProposal({ ...proposal, realEstateComplement: e.target.value })
                }
              })}
              disabled={isDisabled}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={2} lg={2} xl={2}>
            <Typography variant="caption" display="block" gutterBottom >
              Bairro
            </Typography>
            <TextField
              id="realEstateNeighborhood"
              name="realEstateNeighborhood"
              error={!!errors.realEstateNeighborhood}
              helperText={errors?.realEstateNeighborhood?.message}
              placeholder={neighborhoodPlaceHolder}
              variant="outlined"
              margin="dense"
              size="small"
              fullWidth={true}
              InputLabelProps={{
                shrink: true
              }}
              {...register("realEstateNeighborhood", {
                required: "Campo Obrigatório",
                onChange: (e) => {
                  setProposal({ ...proposal, realEstateNeighborhood: e.target.value })
                }
              })}
              disabled={isDisabled}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={2} lg={2} xl={2}>
            <Typography variant="caption" display="block" gutterBottom >
              CEP
            </Typography>
            <TextField
              id="realEstateZipCode"
              name="realEstateZipCode"
              fullWidth={true}
              error={!!errors.realEstateZipCode}
              helperText={errors?.realEstateZipCode?.message}
              placeholder={zipCodeMaskPlaceHolder}
              variant="outlined"
              margin="dense"
              size="small"
              InputLabelProps={{
                shrink: true,
              }}
              {...register("realEstateZipCode", {
                required: "Campo Obrigatório",
                validate: (value: string) => {
                  const cep = sanatizeStringValue(value);
                  return cep.length === 8 || invalidCepLength;
                },
                onChange: (e) => {
                  const realEstateZipCode = createMask({ mask: zipCodeMask }).resolve(e.target.value)
                  setValue("realEstateZipCode", realEstateZipCode)
                  setProposal({ ...proposal, realEstateZipCode })
                }
              })}
              disabled={isDisabled}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={2} lg={2} xl={2}>
            <Typography variant="caption" display="block" gutterBottom >
              Estado
            </Typography>
            <TextField
              id="realEstateStateID"
              name="realEstateStateID"
              error={!!errors.realEstateStateID}
              helperText={errors?.realEstateStateID?.message}
              variant="outlined"
              margin="dense"
              size="small"
              fullWidth={true}
              select
              value={`${watch().realEstateStateID}`}
              {...register("realEstateStateID", {
                required: "Campo Obrigatório",
                onChange: (e) => {
                  setSelectedStateID(e.target.value)
                  setProposal({ ...proposal, realEstateStateID: e.target.value })
                }
              })}
              disabled={isDisabled}
            >
            {states.map((state) => (
              <MenuItem
                key={state.id}
                value={state.id}
              >
                {state.code}
              </MenuItem>
            ))}
            </TextField>
          </Grid>

          <Grid item xs={12} sm={6} md={3} lg={4} xl={4}>
            <Typography variant="caption" display="block" gutterBottom >
              Cidade
            </Typography>
            <TextField
              id="realEstateCityID"
              name="realEstateCityID"
              error={!!errors.realEstateCityID}
              helperText={errors?.realEstateCityID?.message}
              variant="outlined"
              margin="dense"
              size="small"
              fullWidth={true}
              select
              value={`${watch().realEstateCityID}`}
              {...register("realEstateCityID", {
                required: "Campo Obrigatório",
                onChange: (e) => {
                  setValue("realEstateCityID", e.target.value)
                  setProposal({ ...proposal, realEstateCityID: e.target.value })
                }
              })}
              disabled={isDisabled}
            >
            {cities.map((city) => (
              <MenuItem
                key={city.id}
                value={city.id}
              >
                {city.name}
              </MenuItem>
            ))}
            </TextField>
          </Grid>

          <Grid item xs={12} sm={6} md={2} lg={2} xl={2}>
            <Typography variant="caption" display="block" gutterBottom >
              Tipo
            </Typography>
            <TextField
              id="realEstateType"
              name="realEstateType"
              error={!!errors.realEstateType}
              helperText={errors?.realEstateType?.message}
              variant="outlined"
              margin="dense"
              size="small"
              fullWidth={true}
              value={watch('realEstateType')}
              select
              {...register("realEstateType", {
                required: "Campo Obrigatório",
                onChange: (e) => {
                  setProposal({ ...proposal, realEstateType: e.target.value })
                }
              })}
              disabled={isDisabled}
            >
            {Object.entries(RealEstateType).map(([key, value]) => (
              <MenuItem
                key={key}
                value={value}
              >
                {showRealStateType(value)}
              </MenuItem>
            ))}
            </TextField>
          </Grid>

          <Grid item xs={12} sm={6} md={2} lg={2} xl={2}>
            <Typography variant="caption" display="block" gutterBottom >
              Situação
            </Typography>
            <TextField
              id="realEstateSituation"
              name="realEstateSituation"
              error={!!errors.realEstateSituation}
              helperText={errors?.realEstateSituation?.message}
              variant="outlined"
              margin="dense"
              size="small"
              fullWidth={true}
              value={watch('realEstateSituation')}
              select
              {...register("realEstateSituation", {
                required: "Campo Obrigatório",
                onChange: (e) => {
                  setProposal({ ...proposal, realEstateSituation: e.target.value })
                }
              })}
              disabled={isDisabled}
            >
            {Object.entries(RealEstateSituation).map(([key, value]) => (
              <MenuItem
                key={key}
                value={value}
              >
                {showRealStateSituation(value)}
              </MenuItem>
            ))}
            </TextField>
          </Grid>

          <Grid item xs={12} sm={6} md={3} lg={2} xl={2}>
            <Typography variant="caption" display="block" gutterBottom >
              Valor do Imóvel
            </Typography>
            <TextField
              id="realEstatePrice"
              name="realEstatePrice"
              error={!!errors.realEstatePrice}
              helperText={errors?.realEstatePrice?.message}
              placeholder={realEstatePricePlaceHolder}
              variant="outlined"
              margin="dense"
              size="small"
              fullWidth={true}
              InputLabelProps={{
                shrink: true
              }}
              inputProps={{
                maxLength: 60
              }}
              {...register("realEstatePrice", {
                required: "Campo Obrigatório",
                onChange: (e) => {
                  const realEstatePrice = currencyMask(e.target.value)
                  setValue("realEstatePrice", realEstatePrice);
                  setProposal({ ...proposal, realEstatePrice })
                },
              })}
              disabled={isDisabled}
            />
          </Grid>

          <Grid container spacing={1}>
            <Button variant="contained" type="submit" sx={{ mt: 1, mb: 1 }}>
              Continuar
            </Button>
            <Button onClick={handleBack} sx={{ mt: 1, mr: 1, ml: 1 }}>
              Voltar
            </Button>
          </Grid>
        </Grid>
      </FormControl>
    </form>
  )
}
