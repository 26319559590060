import { IProposalDTO } from "data/dtos/i-proposal-dto";
import { ProposalPhase } from "hooks/enums/ProposalStatus";
import { ReactNode, createContext, useState } from "react";

type ProposalContextData = {
  proposal: IProposalDTO
  setProposal: (proposal: IProposalDTO) => void
  resetProposal: () => void
  proposalPhase: ProposalPhase
  setProposalPhase: (proposalPhase: ProposalPhase) => void
  currentProposalPhase: ProposalPhase
  setCurrentProposalPhase: (proposalPhase: ProposalPhase) => void
}

interface Props {
  children?: ReactNode
}

const ProposalContext = createContext<ProposalContextData>({} as ProposalContextData);

function ProposalProvider({ children }: Props) {
  const [proposal, setProposal] = useState<IProposalDTO>({} as IProposalDTO);
  const [proposalPhase, setProposalPhase] = useState<ProposalPhase>(ProposalPhase.PROPOSAL_DATA)
  const [currentProposalPhase, setCurrentProposalPhase] = useState<ProposalPhase>(ProposalPhase.PROPOSAL_DATA)

  const resetProposal = () => {
    setProposal({
      personCompleteName: '',
      personEmail: '',
      personBirthDate: '',

      personCpf: '',
      personDocumentType: '',
      personDocumentNumber: '',
      personDocumentIssuingAgency: '',
      personPhoneNumber: '',

      personNationality: '',
      personMaritalStatus: '',
      personCurrentHouseType: '',
      personBankAccounts: '',


      personProfession: '',
      personJobPosition: '',
      personCompanyName: '',

      personHireDate: '',
      personGrossIncome: '',
      personNetIncome: '',
      personIncomeType: '',


      spouseCompleteName: '',
      spouseEmail: '',
      spouseBirthDate: '',
      spouseCpf: '',

      spouseDocumentType: '',
      spouseDocumentNumber: '',
      spouseDocumentIssuingAgency: '',
      spousePhoneNumber: '',
      spouseNationality: '',


      spouseProfession: '',
      spouseJobPosition: '',
      spouseCompanyName: '',

      spouseHireDate: '',
      spouseGrossIncome: '',
      spouseNetIncome: '',
      spouseIncomeType: '',


      realEstateAddress: '',
      realEstateComplement: '',
      realEstateNumber: '',
      realEstateNeighborhood: '',
      realEstateZipCode: '',

      realEstateStateID: '',
      realEstateCityID: '',
      realEstateType: '',
      realEstateSituation: '',
      realEstatePrice: '',

      realEstateAppraisalValue: '',


      financingValue: '',
      financingPayType: '',
      financingSpouse: false,


      // Seller data
      sellerTradeName: '',
      sellerCNPJ: '',
      sellerCompleteName: '',
      sellerAccountAgency: '',
      sellerAccountNumber: '',
      sellerAccountBankID: '',
      sellerEmail: '',
      sellerPhoneNumber: '',


      // Person Files URL
      personFileCNHURL: '',
      personFileRGFrontURL: '',
      personFileRGBackURL: '',
      personFileCPFURL: '',
      personFileCertificateCivilStatusURL: '',
      personFileAddressVerificationURL: '',
      personFileLatestPayStubURL: '',
      personFilePenultimatePayStubURL: '',
      personFileIncomeTaxURL: '',
      personFileIncomeTaxReceiptURL: '',
      personFileExtractFGTSURL: '',
      personFileAuthorizationFGTSURL: '',


      // Seller Files URL
      sellerFileRGFrontURL: '',
      sellerFileRGBackURL: '',
      sellerFileCertificateCivilStatusURL: '',
      sellerFileAddressVerificationURL: '',
      sellerFileSocialContractURL: '',
      sellerFileSimplifiedCertificateURL: '',
      sellerFileDocumentsOfLegalRepresentativesURL: '',
      sellerFilePowerOfAttorneyURL: '',
      sellerFileAttorneysDocumentURL: '',


      // Real Estates Files URL
      realEstateFileUpdateRegistrationURL: '',
      realEstateFileIPTUURL: '',
      realEstateFileOccupancyPermitURL: '',
      realEstateFileSupervisorCREACAUURL: '',
      realEstateFileARTRRTURL: '',
      realEstateFileAppraisalReportURL: '',

      scheduledPaymentDate: '',
    })
  }

  return (
    <ProposalContext.Provider value={{ proposal, setProposal, resetProposal, proposalPhase, setProposalPhase, currentProposalPhase, setCurrentProposalPhase }}>
      {children}
    </ProposalContext.Provider>
  )
}

export { ProposalContext, ProposalProvider  }
