export enum ProposalStatus {
  CREATED = 'CREATED',
  FIRST_BANK_ANALYSIS = 'FIRST_BANK_ANALYSIS',

  AWAITING_CLIENT_DOCUMENTATION = 'AWAITING_CLIENT_DOCUMENTATION',
  AWAITING_ASSESSOR_DOCUMENTATION_ANALYSIS = 'AWAITING_ASSESSOR_DOCUMENTATION_ANALYSIS',
  SECOND_BANKS_ANALYSIS = 'SECOND_BANKS_ANALYSIS',

  AWAITING_EVALUATION_SCHEDULING = 'AWAITING_EVALUATION_SCHEDULING',
  AWAITING_PROPERTY_EVALUATION = 'AWAITING_PROPERTY_EVALUATION',

  HIRING_AWAITING_CLIENT_SIGNED = 'HIRING_AWAITING_CLIENT_SIGNED',
  HIRING_AWAITING_SELLER_SIGNED = 'HIRING_AWAITING_SELLER_SIGNED',
  HIRING_AWAITING_BANK_SIGNED = 'HIRING_AWAITING_BANK_SIGNED',
  HIRING_AWAITING_ITBI_GENERATED = 'HIRING_AWAITING_ITBI_GENERATED',
  HIRING_AWAITING_ITBI_PAID = 'HIRING_AWAITING_ITBI_PAID',
  HIRING_AWAITING_NOTARY_EXPENSES_PAID = 'HIRING_AWAITING_NOTARY_EXPENSES_PAID',
  HIRING_AWAITING_DOCUMENTATION_DELIVERED = 'HIRING_AWAITING_DOCUMENTATION_DELIVERED',

  AWAITING_DISBURSEMENT = 'AWAITING_DISBURSEMENT',
  AWAITING_PAYMENT = 'AWAITING_PAYMENT',
  AWAITING_COMMISSION_GENERATED = 'DISBURSEMENT_PAYMENT_DONE',
  COMPLETED = 'COMPLETED',

  DECLINED_CREDIT_REFUSAL = 'DECLINED_CREDIT_REFUSAL',
  DECLINED_LACK_OF_INFORMATION = 'DECLINED_LACK_OF_INFORMATION',
  DECLINED_COMPETITOR_CONDITIONS = 'DECLINED_COMPETITOR_CONDITIONS',
  DECLINED_ABANDONMENT = 'DECLINED_ABANDONMENT',
  DECLINED_BY_CLIENT = 'DECLINED_BY_CLIENT',
}

export enum ProposalPerformanceStatus {
  MAPPED = 'MAPPED',
  IN_ANALYSIS = 'IN_ANALYSIS',
  HIRING = 'HIRING',
  COMPLETED = 'COMPLETED',
  DECLINED_CREDIT_REFUSAL = 'DECLINED_CREDIT_REFUSAL',
  DECLINED_LACK_OF_INFORMATION = 'DECLINED_LACK_OF_INFORMATION',
  DECLINED_COMPETITOR_CONDITIONS = 'DECLINED_COMPETITOR_CONDITIONS',
  DECLINED_ABANDONMENT = 'DECLINED_ABANDONMENT',
  DECLINED_BY_CLIENT = 'DECLINED_BY_CLIENT',
}

export enum ProposalPhase {
  PROPOSAL_DATA = 0,
  DOCUMENTATION = 1,
  PROPERTY_EVALUATION = 2,
  CONTRACTING = 3,
  DISBURSEMENT = 4,
  REFUSED = 5,
}

export const showProposalStatus = (proposalStatus: ProposalStatus): string => {
  switch (proposalStatus) {
    case ProposalStatus.DECLINED_CREDIT_REFUSAL:
      return 'Recusada de crédito'
    case ProposalStatus.DECLINED_LACK_OF_INFORMATION:
      return 'Recusada (falta de informação)'
    case ProposalStatus.DECLINED_COMPETITOR_CONDITIONS:
      return 'Condições dos concorrentes'
    case ProposalStatus.DECLINED_ABANDONMENT:
      return 'Abandono'
    case ProposalStatus.DECLINED_BY_CLIENT:
      return 'Cancelada pelo cliente'
  }
}

export const showProposalPerformanceStatus = (proposalPerformanceStatus: ProposalPerformanceStatus): string => {
  switch (proposalPerformanceStatus) {
    case ProposalPerformanceStatus.MAPPED:
      return 'Mapeada'
    case ProposalPerformanceStatus.IN_ANALYSIS:
      return 'Análise de Documentos'
    case ProposalPerformanceStatus.HIRING:
      return 'Em Contratação'
    case ProposalPerformanceStatus.COMPLETED:
      return 'Concluído'
    case ProposalPerformanceStatus.DECLINED_CREDIT_REFUSAL:
    case ProposalPerformanceStatus.DECLINED_LACK_OF_INFORMATION:
    case ProposalPerformanceStatus.DECLINED_COMPETITOR_CONDITIONS:
    case ProposalPerformanceStatus.DECLINED_ABANDONMENT:
    case ProposalPerformanceStatus.DECLINED_BY_CLIENT:
      return 'Recusada'
  }
}

export const showProposalPhase = (proposalStatus: ProposalPhase): string => {
  switch (proposalStatus) {
    case ProposalPhase.PROPOSAL_DATA:
      return 'Dados da Proposta'
    case ProposalPhase.DOCUMENTATION:
      return 'Documentação'
    case ProposalPhase.PROPERTY_EVALUATION:
      return 'Avaliação do Imóvel'
    case ProposalPhase.CONTRACTING:
      return 'Contratação'
    case ProposalPhase.DISBURSEMENT:
      return 'Desembolso'
    case ProposalPhase.REFUSED:
      return 'Recusada'
  }
}
