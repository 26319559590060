export enum MaritalStatus {
  SINGLE = 'SINGLE',
  MARRIED = 'MARRIED',
  DIVORCED = 'DIVORCED',
  WIDOWED = 'WIDOWED',
  DESQUITADO = 'DESQUITADO',
  LEGALLY_SEPARATED = 'LEGALLY_SEPARATED'
}

export const showMaritalStatus = (maritalStatus: MaritalStatus): string => {
  switch (maritalStatus) {
    case MaritalStatus.SINGLE:
      return 'Solteiro'
    case MaritalStatus.MARRIED:
      return 'Casado'
    case MaritalStatus.DIVORCED:
      return 'Divorciado'
    case MaritalStatus.WIDOWED:
      return 'Viúvo'
    case MaritalStatus.DESQUITADO:
      return 'Desquitado'
    case MaritalStatus.LEGALLY_SEPARATED:
      return 'Separado Judicialmente'
    default:
      return 'Tipo de status de convite inválido'
  }
}
