import { makeStyles } from '@mui/styles'
import { Theme } from '@mui/material'

const appBarHeight = 70

const useStyles = makeStyles((theme: Theme) => ({
  appBar: {
    height: `${appBarHeight}px`,
    justifyContent: 'center',
    color: '#FFF !important',
    backgroundColor: '#FFF !important',
    paddingLeft: '30px'
  },

  logo: {
    width: '150px',
    marginLeft: '0px',
    marginRight: '65px',
  },

}))

export { useStyles }
