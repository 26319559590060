import { makeStyles } from '@mui/styles'
import { Theme } from '@mui/material'

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    textAlign: 'center',
    maxHeight: 'calc(100vh - 320px)',
    margin: 'auto',
    justifyContent: 'center',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },

  emptyBoxLogo: {
    display: 'block',
    margin: 'auto',
    height: '250px'
  },

  elementSpacing: {
    margin: '100px'
  },

  registerButton: {
    fontSize: '1.2rem !important',
    height: "3rem !important",
    fontWeight: 'bold !important'
  }
}))

export { useStyles }
