export enum InviteStatus {
  ACTIVE = 'ACTIVE',
  CANCELLED = 'CANCELLED',
  CONSUMED = 'CONSUMED',
}

export const showInviteStatus = (inviteStatus: InviteStatus): string => {
  switch (inviteStatus) {
    case InviteStatus.ACTIVE:
      return 'Pendente'
    case InviteStatus.CANCELLED:
      return 'Cancelado'
    case InviteStatus.CONSUMED:
      return 'Aceito'
    default:
      return 'Tipo de status de convite inválido'
  }
}
