import React from 'react';
import { Box, Card, CardContent, Divider, Grid, IconButton, Paper, Typography } from '@mui/material';
import { useStyles } from "./styles";
import { BankRates, homeData } from './data';
import AppRegistrationIcon from '@mui/icons-material/AppRegistration';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import PeopleIcon from '@mui/icons-material/People';
import logo from 'assets/logo.svg';
import { useHistory } from 'react-router-dom';
import { useProfile } from 'hooks/use-profile';
import { ProfileType } from 'hooks/enums/ProfileType';

const Home: React.FC = () => {
  const classes = useStyles();
  const history = useHistory();
  const { selectedProfile } = useProfile();
  const isClientUser = selectedProfile === ProfileType.CLIENTE;

  const handleNavigation = (path: string) => {
    history.push(path);
  };

  const renderFintetoLogo = () => (
    <Grid container className={classes.logoContainer}>
      <img src={logo} alt="Finteto Logo" className={classes.fintetoLogo} />
    </Grid>
  );

  const renderRedirectCard = () => (
    <Grid container spacing={0} alignItems="stretch" justifyContent="center">
      <Grid item xs={12} sm={12} md={3} lg={3} xl={2} sx={{ marginBottom: '10px !important'}}>
        <Card className={classes.card} onClick={() => handleNavigation('proposals/new')}>
          <CardContent className={classes.cardContent}>
            <IconButton>
              <AppRegistrationIcon className={classes.cardIcon} />
            </IconButton>
            <Typography variant="body2">
              Cadastre uma nova proposta de financiamento.
            </Typography>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12} sm={12} md={3} lg={3} xl={2} sx={{ marginBottom: '10px !important', marginLeft: { md: '20px !important', lg: '20px !important', xl: '20px !important' }, marginRight: { md: '20px !important', lg: '20px !important', xl: '20px !important' } }}>
        <Card className={classes.card} onClick={() => handleNavigation(isClientUser ? 'follow-up' : '/performance')}>
          <CardContent className={classes.cardContent}>
            <IconButton>
              <QueryStatsIcon className={classes.cardIcon} />
            </IconButton>
            <Typography variant="body2">
              Acompanhe o andamento de suas propostas.
            </Typography>
          </CardContent>
        </Card>
      </Grid>
      {!isClientUser && (
        <Grid item xs={12} sm={12} md={3} lg={3} xl={2} sx={{ marginBottom: '10px !important'}}>
          <Card className={classes.card} onClick={() => handleNavigation(`invites?profileID=${ProfileType.CLIENTE}`)}>
            <CardContent className={classes.cardContent}>
              <IconButton>
                <PeopleIcon className={classes.cardIcon} />
              </IconButton>
              <Typography variant="body2">
                Convide usuários para a plataforma de Finteto.
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      )}
    </Grid>
  );

  const renderTableHeader = () => (
    <Grid container spacing={0} paddingTop="10px">
      <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
        <Box className={classes.gridHeader}>
          <Typography align="left" fontSize={14} fontWeight="bold" >
            Intituição Financiadora
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
        <Box className={classes.gridHeader}>
          <Typography align="center" fontSize={14} fontWeight="bold">
            Tradicional
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
        <Box className={classes.gridHeader}>
          <Typography align="center" fontSize={14} fontWeight="bold">
            Poupança
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
        <Box className={classes.gridHeader}>
          <Typography align="center" fontSize={14} fontWeight="bold">
            CGI
          </Typography>
        </Box>
      </Grid>
    </Grid>
  );

  const renderResultTable = (results: BankRates[]) => (
    <Grid container spacing={0}>
      {results.map((item, index) => (
        <Grid container spacing={0} key={index}>
          <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
            <Box className={classes.gridBody} display="flex" alignItems="center">
              <img src={item.logo} alt={`Logo ${item.bankName}`} className={classes.bankLogo} />
              <Typography align="center" fontSize={14} marginLeft={2}>
                {item.bankName}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
            <Box className={classes.gridBody} display="flex" alignItems="center" justifyContent="center">
              <Typography align="center" fontSize={14}>
                {item.traditional}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
            <Box className={classes.gridBody} display="flex" alignItems="center" justifyContent="center">
              <Typography align="center" fontSize={14}>
                {item.savings}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
            <Box className={classes.gridBody} display="flex" alignItems="center" justifyContent="center">
              <Typography align="center" fontSize={14}>
                {item.cgi}
              </Typography>
            </Box>
          </Grid>
        </Grid>
      ))}
    </Grid>
  );

  return (
    <Paper className={classes.paper}>
      <Box maxWidth={1400}>
        <Card>
          <CardContent>
            {renderFintetoLogo()}
            {renderRedirectCard()}

            <Typography align="left" fontSize={20} fontWeight="bold" marginTop={5}>
              Condições Atuais de Referência nas Intituições Parceiras Finteto
            </Typography>

            <Divider variant="fullWidth" sx={{ borderBottomWidth: 1, my: 1 }} />

            {renderTableHeader()}
            {renderResultTable(homeData)}

            <Typography variant="body2" fontWeight="bold" style={{ marginTop: '20px' }}>
              Taxas sujeitas a análise de crédito e demais condições do produto. As taxas utilizadas nas simulações são indicativas
              e não há compromisso das instituições financeiras para emissão dos contratos com tais taxas.
            </Typography>
          </CardContent>
        </Card>
      </Box>
    </Paper>
  );
};

export default Home;
