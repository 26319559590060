import { makeStyles } from '@mui/styles'
import { Theme } from '@mui/material'

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    background: "#FFF",
    padding: '20px',
    height: '5000px',
    maxHeight: 'calc(100vh - 420px)',
    width: '100%'
  },

  formTitle: {
    display: 'flex',
    width: '100%',
    fontSize: 24,
    fontWeight: 'bold',
    alignItems: 'center',
    justifyContent: 'space-between',
    color: theme.palette.primary.main
  },

  tableContainer: {
    display: 'flex',
    flexDirection: 'column',
  },

}))

export { useStyles }
