import React, { useState, useRef, useCallback, useEffect, useContext, ChangeEvent } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { Box, Paper, Typography, StepContent, Button, CircularProgress, Stack, StepConnector, stepConnectorClasses, StepIconProps, TextField, Grid, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, RadioGroup, FormControlLabel, Radio, MenuItem } from '@mui/material'
import { ProfileType } from 'hooks/enums/ProfileType'
import { useProfile } from 'hooks/use-profile'
import { ProposalStatus, showProposalPerformanceStatus, showProposalStatus } from 'hooks/enums/ProposalStatus'
import { useStyles } from './style'
import api from 'services/api'
import { useForm } from 'react-hook-form'
import { currencyMask, realEstatePricePlaceHolder, sanatizeMoneyValue } from 'utils/utils'
import { PARTNER_BANK_ITAU_ID } from 'hooks/enums/PartnerBankID'
import { useAlreadyMounted } from 'utils/use-already-mounted'

type Props = {
  proposalID: string
  isOpen: boolean
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
}

type ApprovalProposalRequest = {
  partnerBankID: string
  approvedValue: string
}

const ApproveProposalDialog = ({ proposalID, isOpen, setIsOpen }: Props) => {
  const { selectedProfile } = useProfile();
  const classes = useStyles()
  const history = useHistory()

  const [isLoading, setIsLoading] = useState(false);
  const [mainError, setMainError] = useState('')
  const [partnerBanks, setPartnerBanks] = useState([])
  const [declinedReason, setDeclinedReason] = useState<ProposalStatus>(ProposalStatus.DECLINED_ABANDONMENT);
  const alreadyMounted = useAlreadyMounted()

  const {
    register,
    handleSubmit,
    reset,
    watch,
    setValue,

    formState: { errors },
  } = useForm<ApprovalProposalRequest>({
    defaultValues: {
      partnerBankID: PARTNER_BANK_ITAU_ID,
      approvedValue: '',
    },
    reValidateMode: 'onChange'
  })

  const handleCloseDeclineProposalDialog = () => {
    setIsOpen(false)
  }

  useEffect(() => {
    async function loadData() {
      await api
      .get('/partner-banks/')
      .then(response => {
        return response.data
      })
      .then((partnerBanks) => {
        setPartnerBanks(partnerBanks)
      })
    }

    if(!alreadyMounted) {
      loadData()
    }

  }, [alreadyMounted])

  const onSubmit = useCallback(async (data: ApprovalProposalRequest) => {
    await api
      .post(`proposals/actions/approve/${proposalID}`, {
        approvedValue: sanatizeMoneyValue(data.approvedValue),
        partnerBankID: data.partnerBankID
      })
      .then(history.push('/performance'))
      .then(() => reset())
      .catch(error => {
        setMainError('Erro ao aprovar sua proposta, por favor tente novamente mais tarde')
      })
  }, [])

  if(isLoading) {
    return <CircularProgress color="primary" className={classes.spinner}/>
  }

  return (
    <Dialog
      open={isOpen}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className={classes.roundedDialog}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle id="alert-dialog-title" style={{ textAlign: 'center', margin: '20px', fontSize: '22px' }}>Aprovar Proposta</DialogTitle>

        <DialogContent style={{ minWidth: '500px' }}>

          <Grid container spacing={1} className={classes.formContainer}>

            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Typography variant="caption" display="block" gutterBottom>
                  Valor aprovado
                </Typography>
                <TextField
                  id="approvedValue"
                  name="approvedValue"
                  error={!!errors.approvedValue}
                  helperText={errors?.approvedValue?.message}
                  placeholder={realEstatePricePlaceHolder}
                  variant="outlined"
                  margin="dense"
                  size="small"
                  fullWidth={true}
                  InputLabelProps={{
                    shrink: true
                  }}
                  inputProps={{
                    maxLength: 60
                  }}
                  {...register("approvedValue", {
                    required: "Campo Obrigatório",
                    onChange: (e) => {
                      const approvedValue = currencyMask(e.target.value)
                      setValue("approvedValue", approvedValue);
                    }
                  })}
                />
              </Grid>
            </Grid>

            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Typography variant="caption" display="block" gutterBottom>
                  Banco
                </Typography>
                <TextField
                  id="partnerBankID"
                  error={!!errors.partnerBankID}
                  helperText={errors?.partnerBankID?.message}
                  variant="outlined"
                  margin="dense"
                  size="small"
                  fullWidth={true}
                  value={`${watch().partnerBankID}`}
                  select
                  {...register("partnerBankID", { onChange: (e) => {
                    setValue("partnerBankID", e.target.value)
                  }})}
                >
                {partnerBanks.map((partnerBank) => (
                  <MenuItem
                    key={partnerBank.id}
                    value={partnerBank.id}
                  >
                    {partnerBank.name}
                  </MenuItem>
                ))}
                </TextField>
              </Grid>
            </Grid>

          </Grid>

        </DialogContent>

        <DialogActions style={{ justifyContent: 'center', marginBottom: '30px' }} >
          <Button onClick={handleCloseDeclineProposalDialog} variant="outlined">Cancelar</Button>
          <Button type='submit'variant="contained">Aprovar</Button>
        </DialogActions>
      </form>
    </Dialog>
  )
}

export default ApproveProposalDialog
