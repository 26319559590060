import {
  Alert,
  Box,
  Grid,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { ImageConfig } from './FileConfig';
import ArrowCircleUpIcon from '@mui/icons-material/ArrowCircleUp';
import { Controller, useController, useFormContext } from 'react-hook-form';
import { useStyles } from './style';
import InfoIcon from '@mui/icons-material/Info';
import { format } from 'bytes';
import axios from 'axios';
import { useAlreadyMounted } from 'utils/use-already-mounted';
import DefaultModal, { DialogType } from 'components/modal';
import { ProposalContext } from 'contexts/proposal-context';

// 👇 FileUpload Props Here
interface IFileUploadProps {
  label: string;
  helperText: string;
  shouldShowHeader?: boolean
  name: string;
  isDisabled: boolean
  imageUrl?: string
  onFileUpload: (fieldName: string, file: File) => void,
  isRequired?: boolean
  defaultValue?: File
}

// 👇 Custom Styles for the Box Component
const CustomBox = styled(Box)({
  '&.MuiBox-root': {
    backgroundColor: '#fff',
    borderRadius: '2rem',
  },
  '&.MuiBox-root:hover, &.MuiBox-root.dragover': {
    opacity: 0.7,
  },
});

const DEFAULT_UUID_SIZE = 37

// 👇 FileUpload Component
const FileUpload: React.FC<IFileUploadProps> = ({ label, helperText, shouldShowHeader = false, name, isDisabled, imageUrl, onFileUpload, isRequired, defaultValue }) => {
  const classes = useStyles();
  const { control, setError, formState: { isSubmitting, errors }, register } = useFormContext();
  const [file, setFile] = useState<File | null>(defaultValue);
  const [fileName, setFileName] = useState<string>('');
  const [fileSize, setFileSize] = useState<number | null>(null);
  const [fileType, setFileType] = useState<string>('');
  const wrapperRef = useRef<HTMLDivElement>(null);
  const [mainError, setMainError] = useState<string>('');
  const alreadyMounted = useAlreadyMounted();
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState<boolean>(false);
  const { proposal, setProposal } = useContext(ProposalContext);

  const formatImageType = (type: string): string => {
    switch (type) {
      case "image/jpg":
      case "jpg":
        return "JPG";
      case "image/jpeg":
      case "jpeg":
        return "JPEG";
      case "image/png":
      case "png":
        return "PNG";
      case "application/pdf":
      case "pdf":
        return "PDF";
      default:
        return type;
    }
  };

  const onDragEnter = () => wrapperRef.current?.classList.add('dragover');
  const onDragLeave = () => wrapperRef.current?.classList.remove('dragover');

  const onFileDrop = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setMainError('');
      if (!e.target.files) return;

      const allowedTypes = ['image/jpg', 'image/jpeg', 'image/png', 'application/pdf'];
      const maxSize = 10 * 1024 * 1024; // 10 megabytes

      const newFile = e.target.files[0];

      if (!allowedTypes.includes(newFile.type)) {
        setMainError('Somente são permitidos arquivos JPG, JPEG, PNG e PDF.');
        return;
      }

      if (newFile.size > maxSize) {
        setMainError('O tamanho do arquivo deve ser de no máximo 10 megabytes.');
        return;
      }

      setFile(newFile);
      setFileName(newFile.name);
      setFileSize(newFile.size);
      setFileType(newFile.type);
      onFileUpload(name, newFile);
    },
    [name, onFileUpload]
  );

  const fileRemove = () => {
    setFile(null);
    setFileName('');
    setFileType('');
    setFileSize(null);
    const input = wrapperRef.current?.querySelector('input[type=file]') as HTMLInputElement | null;
    if (input) {
      input.value = '';
    }
  };

  useEffect(() => {
    if (isSubmitting) {
      setFile(null);
    }
  }, [isSubmitting]);

  useEffect(() => {

    const getImageInfo = async () => {
      if (file) {
        setFileName(file.name);
        setFileSize(file.size);
        setFileType(file.type);
      } else if (imageUrl) {
        const filenameFromImageURL = decodeURIComponent(imageUrl.split('?')[0].substring(imageUrl.lastIndexOf('/') + 1 + DEFAULT_UUID_SIZE));
        const fileTypeFromImageUrl = filenameFromImageURL.split('.').pop();

        setFileName(filenameFromImageURL);
        setFileType(formatImageType(fileTypeFromImageUrl));

        try {
          const imageDataResponse = await axios.get(imageUrl);
          const imageSize = parseInt(imageDataResponse.headers['content-length']);
          setFileSize(imageSize);
        } catch (error: any) {
          console.error('Error fetching image data:', error);
        }
      }
    };

    getImageInfo();
  }, [file, alreadyMounted, imageUrl]);

  return (
    <>
      {shouldShowHeader && (
        <Box display='flex' sx={{ position: 'relative', width: '100%', height: '40px' }} justifyContent="flex-start" alignItems='center'>
          <Grid item xs={4}><Stack alignItems='left' marginLeft={2}><Typography variant='caption'><strong>Documento</strong></Typography></Stack></Grid>
          <Grid item xs={4}><Stack alignItems='left' marginLeft={2}><Typography variant='caption'><strong>Nome do Arquivo</strong></Typography></Stack></Grid>
          <Grid item xs={2}><Stack alignItems='left' marginLeft={2}><Typography variant='caption'><strong>Tamanho</strong></Typography></Stack></Grid>
          <Grid item xs={1}><Stack alignItems='left' marginLeft={2}><Typography variant='caption'><strong>Tipo</strong></Typography></Stack></Grid>
          <Grid item xs={1} container justifyContent="flex-end" alignItems="center">
            <Stack direction='row' alignItems='center' marginRight={3}><Typography variant='caption'><strong>Ações</strong></Typography></Stack>
          </Grid>
        </Box>
      )}
      <CustomBox>
        <Box display='flex' sx={{ position: 'relative', width: '100%', height: '40px', border: '1px solid #2614FE', borderRadius: '10px', boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.2)' }} justifyContent="flex-start" alignItems='center' ref={wrapperRef} onDragEnter={onDragEnter} onDragLeave={onDragLeave} onDrop={onDragLeave}>
          <Controller
            name={name}
            control={control}
            rules={{ required: 'Arquivo obrigatório' }}
            render={({ field: { name, onBlur, ref } }) => (
              <input
                id={name}
                name={name}
                type='file'
                onBlur={onBlur}
                ref={ref}
                onChange={onFileDrop}
                multiple={false}
                accept='image/jpg, image/png, image/jpeg, application/pdf'
                style={{ opacity: 0, position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', cursor: 'pointer' }}
                disabled={isDisabled}
                required={isRequired}
              />
            )}
          />
          <Grid item xs={4}><Stack alignItems='left' marginLeft={2}><Typography variant='caption'>{label}</Typography></Stack></Grid>
          <Grid item xs={4}><Stack alignItems='left' marginLeft={2}><Typography variant='caption'>{fileName}</Typography></Stack></Grid>
          <Grid item xs={2}><Stack alignItems='left' marginLeft={2}><Typography variant='caption'>{fileSize && format(fileSize)}</Typography></Stack></Grid>
          <Grid item xs={1}><Stack alignItems='left' marginLeft={2}><Typography variant='caption'>{formatImageType(fileType)}</Typography></Stack></Grid>
          <Grid item xs={1} container justifyContent="flex-end" alignItems="center">
            <Stack direction='row' alignItems='center' marginRight={2}>
              {imageUrl && !file && (
                <>
                  <IconButton onClick={() => { window.open(imageUrl, '_blank'); }}>
                    <VisibilityIcon />
                  </IconButton>
                </>
              )}
              {file && (
                <IconButton sx={{ marginLeft: '-7px' }} disabled={isDisabled} onClick={() => { setIsDeleteDialogOpen(true); }}>
                  <DeleteIcon />
                </IconButton>
              )}
              {helperText && <IconButton sx={{ marginLeft: '-7px' }}><Tooltip title={helperText} placement="top" arrow={true}><InfoIcon /></Tooltip></IconButton>}
              <ArrowCircleUpIcon sx={{ color: isDisabled ? 'inherit' : '' }} />
            </Stack>
          </Grid>
        </Box>
      </CustomBox>
      {mainError !== '' && (<Alert severity="error" className={classes.alert}>{mainError}</Alert>)}
      <DefaultModal isOpen={isDeleteDialogOpen} title='Deletar Arquivo' bodyContent='Deseja realmente deletar o arquivo?' type={DialogType.ACTION} onCloseModal={() => setIsDeleteDialogOpen(false)} onConfirmModal={() => fileRemove()} />
    </>
  );
};

export default FileUpload;
