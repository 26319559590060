import { makeStyles } from '@mui/styles'
import { Theme } from '@mui/material'

const useStyles = makeStyles((theme: Theme) => ({
  formTitleHelpIcon: {
    marginLeft: 7,
    marginTop: 2,
    opacity: 0.4
  },

  alert: {
    marginTop: '10px',
		padding: '1px 16px 1px 16px',
	},

}))

export { useStyles }
