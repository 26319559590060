import { makeStyles } from '@mui/styles'
import { Theme } from '@mui/material'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
  },

  proposalContainer: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    maxWidth: '1200px',
    margin: 'auto',
    marginTop: '25px',
    paddingLeft: '30px',
    paddingRight: '30px'
  },

  footer: {
    marginTop: 'auto',
    width: '100%'
  },
}))

export { useStyles }
