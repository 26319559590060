import React, { useState, useEffect, useRef, useCallback } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { Box, Paper, Grid, TextField, InputLabel, MenuItem, Typography } from '@mui/material'
import { FormGroup, FormControlLabel, Checkbox } from '@mui/material'
import { useForm, Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { FormHeader, FormAlert } from 'components'
import ListIcon from '@mui/icons-material/List'
import * as yup from 'yup'
import { useStyles } from './styles'
import api from 'services/api'
import { IUserDTO } from 'data/dtos/security/i-user-dto'
import { useProfile } from 'hooks/use-profile'
import { ProfileType } from 'hooks/enums/ProfileType'
import { createMask } from 'imask'
import { cnpjMask, completeNamePlaceHolder, cpfMask, cpfMaskPlaceholder, emailPlaceHolder, phoneMask, phoneMaskPlaceholder } from 'utils/utils'
import { useAuth } from 'hooks/auth'
import DefaultModal, { DialogType } from 'components/modal'
import { UpdateUserRequest, UpdateUsers, getUsers } from 'services/users'

interface IRouteParams {
  id: string
}

const UserForm: React.FC = () => {
  const [mainError, setMainError] = useState('')
  const { selectedProfile } = useProfile();
  const { user } = useAuth()

  const params = useParams<IRouteParams>()
  const { id: editUserID } = params
  const classes = useStyles()
  const history = useHistory()
  const isSelf = editUserID === user.id
  const isOrganizationSelectorEnabled = selectedProfile === ProfileType.ADMIN || selectedProfile === ProfileType.OFFICER
  const isResponsibleUserSelectorEnabled = selectedProfile === ProfileType.ADMIN || selectedProfile === ProfileType.OFFICER || selectedProfile === ProfileType.GESTOR
  const [organizationsList, setOrganizationsList] = useState([])
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false)
  const [selectedOrganizationID, setSelectedOrganizationID] = useState<string>()
  const [responsibleUsers, setResponsibleUsers] = useState([])


  const validationSchema = isSelf ? yup.object().shape({
    name: yup.string()
      .required('Campo obrigatório'),
    cpf: yup.string()
      .required('Campo obrigatório'),
    email: yup.string()
      .email('Formato de email inválido')
      .required('Campo obrigatório'),
    phoneNumber: yup.string()
      .required('Campo obrigatório'),
  }) : yup.object().shape({})

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    watch,
    formState: { errors },
  } = useForm<UpdateUserRequest>({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      name: '',
      cpf: '',
      email: '',
      phoneNumber: '',
      organizationID: null,
      responsibleUserID: null,
    }
  })

  useEffect(() => {
    async function loadResponsibleUsers() {
      const response = await getUsers({ profileIDs: [ProfileType.GESTOR, ProfileType.ASSESSOR, ProfileType.OFFICER], organizationID: selectedOrganizationID })
      setResponsibleUsers(response)
    }
    loadResponsibleUsers()
  }, [selectedOrganizationID])

  const loadUser = useCallback(async () => {
    const { id } = params

    await api
      .get(`/users-security/${id}`)
      .then(response => {
        const data = response.data

        const userResult = {
          name: data.name,
          cpf: data.cpf,
          email: data.email,
          phoneNumber: data.phoneNumber,
          organizationID: data.organizationID,
          responsibleUserID: data.responsibleUserID
        }

        return userResult
      })
      .then((userResult: IUserDTO) => {
        const { cpf, phoneNumber } = userResult

        userResult.cpf = cpf ? createMask({ mask: cpfMask }).resolve(cpf) : ''
        userResult.phoneNumber = phoneNumber ? createMask({ mask: phoneMask }).resolve(phoneNumber) : ''

        setSelectedOrganizationID(userResult.organizationID)

        reset(userResult)
      })
      .catch(error => {
        console.log(error)
        return error
      })
  }, [])

  const loadOrganizations = useCallback(async () => {
    await api
      .post('/organizations/list', { search: '', page: 0, rowsPerPage: 100, onlyActive: true })
      .then(async listResponse => {
        const { data } = listResponse.data
        setOrganizationsList(data)
        // setValue('organizationID', data[0].organizationID)
      })
      .catch(error => {
        console.log(error)
      })
  }, [])

  // initial load

  useEffect(() => {
    loadOrganizations()
    loadUser()
  }, [loadOrganizations])

  // data save
  const onSubmit = useCallback(async (data: UpdateUserRequest) => {
    const { id } = params

    const payLoad: UpdateUserRequest = {
      id,
      name: data.name,
      cpf: data.cpf,
      email: data.email,
      phoneNumber: data.phoneNumber,
      organizationID: data.organizationID,
      responsibleUserID: data.responsibleUserID
    }

    try {
      UpdateUsers(payLoad)
      setIsConfirmationModalOpen(true)
    } catch {
      setMainError("Erro ao atualizar o usuário.")
    }
  }, [])


  const handleChange = (formField: any) => {
    setMainError('')
  }

  const handleConfirmModal = () => {
    history.push('/home')
  }


  return (
    <Paper elevation={0} className={classes.paper}>
      <Box
        component="form"
        onSubmit={handleSubmit(onSubmit)}
        noValidate
        data-testid="form"
      >
        <FormHeader
          title="Dados de Cadastro do Usuário"
          icon={ListIcon}
          backRoute="/users"
          showBackButton={false}
          showSaveButton={true}
        />

        <FormAlert setMainError={setMainError} mainError={mainError} />

        <Paper elevation={1} className={classes.gridPaper}>
          <Grid container spacing={1} className={classes.formContainer}>

            <Grid item xs={12} sm={12} md={8} lg={4} xl={4}>
              <Typography variant="caption" display="block" gutterBottom>
                Nome
              </Typography>
              <TextField
                id="name"
                name="name"
                error={!!errors.name}
                helperText={errors?.name?.message}
                placeholder={completeNamePlaceHolder}
                variant="outlined"
                margin="dense"
                size="small"
                fullWidth={true}
                disabled={!isSelf}
                InputLabelProps={{
                  shrink: true
                }}
                inputProps={{
                  maxLength: 60
                }}
                {...register("name",
                  { onChange: (e) => handleChange(e) }
                )}
              />
            </Grid>

            <Grid item xs={12} sm={12} md={4} lg={2} xl={2}>
              <Typography variant="caption" display="block" gutterBottom>
                CPF
              </Typography>
              <TextField
                id="cpf"
                name="cpf"
                error={!!errors.cpf}
                helperText={errors?.cpf?.message}
                placeholder={cpfMaskPlaceholder}
                variant="outlined"
                margin="dense"
                size="small"
                fullWidth={true}
                disabled={!isSelf}
                InputLabelProps={{
                  shrink: true
                }}
                inputProps={{
                  maxLength: 60
                }}
                {...register("cpf",
                  { onChange: (e) => handleChange(e) }
                )}
                onChange={(event) => {
                  const { value } = event.target
                  event.target.value = createMask({ mask: cpfMask }).resolve(value)
                }}
              />
            </Grid>

            <Grid item xs={12} sm={12} md={8} lg={4} xl={4}>
              <Typography variant="caption" display="block" gutterBottom>
                Email
              </Typography>
              <TextField
                id="email"
                name="email"
                error={!!errors.email}
                helperText={errors?.email?.message}
                placeholder={emailPlaceHolder}
                variant="outlined"
                margin="dense"
                size="small"
                fullWidth={true}
                disabled={!isSelf}
                InputLabelProps={{
                  shrink: true
                }}
                inputProps={{
                  maxLength: 60
                }}
                {...register("email",
                  { onChange: (e) => handleChange(e) }
                )}
              />
            </Grid>

            <Grid item xs={12} sm={12} md={4} lg={2} xl={2}>
              <Typography variant="caption" display="block" gutterBottom>
                Telefone
              </Typography>
              <TextField
                id="phoneNumber"
                name="phoneNumber"
                error={!!errors.phoneNumber}
                helperText={errors?.phoneNumber?.message}
                placeholder={phoneMaskPlaceholder}
                variant="outlined"
                margin="dense"
                size="small"
                fullWidth={true}
                disabled={!isSelf}
                InputLabelProps={{
                  shrink: true
                }}
                inputProps={{
                  maxLength: 60
                }}
                {...register("phoneNumber",
                  { onChange: (e) => handleChange(e) }
                )}
                onChange={(event) => {
                  const { value } = event.target
                  event.target.value = createMask({ mask: phoneMask }).resolve(value)
                }}
              />
            </Grid>

            <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
              <Typography variant="caption" display="block" gutterBottom>
                Parceiro
              </Typography>
              <TextField
                id="organizationID"
                error={!!errors.organizationID}
                helperText={errors?.organizationID?.message}
                variant="outlined"
                margin="dense"
                size="small"
                fullWidth={true}
                value={`${watch().organizationID}`}
                select
                {...register("organizationID", { onChange: (e) => {
                  setValue("organizationID", e.target.value)
                  setSelectedOrganizationID(e.target.value)
                  handleChange(e)
                }})}
                disabled={!isOrganizationSelectorEnabled}
              >
              {organizationsList.map((organization) => (
                <MenuItem
                  key={organization.id}
                  value={organization.id}
                >
                  {`${createMask({ mask: cnpjMask }).resolve(organization.cnpj)} - ${organization.legalName}`}
                </MenuItem>
              ))}
              </TextField>
            </Grid>

            <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
              <Typography variant="caption" display="block" gutterBottom>
                Usuário Responsável
              </Typography>
              <TextField
                id="responsibleUserID"
                error={!!errors.responsibleUserID}
                helperText={errors?.responsibleUserID?.message}
                variant="outlined"
                margin="dense"
                size="small"
                fullWidth={true}
                value={`${watch().responsibleUserID}`}
                select
                {...register("responsibleUserID", { onChange: (e) => {
                  setValue("responsibleUserID", e.target.value)
                  handleChange(e)
                }})}
                disabled={!isResponsibleUserSelectorEnabled}
              >
              {responsibleUsers.map((user) => (
                <MenuItem
                  key={user.id}
                  value={user.id}
                >
                  {user.name}
                </MenuItem>
              ))}
              </TextField>
            </Grid>
          </Grid>
        </Paper>
      </Box>

      <DefaultModal
        isOpen={isConfirmationModalOpen}
        title='Perfil Atualizado'
        bodyContent={"Perfil atualizado com sucesso."}
        onConfirmModal={() => handleConfirmModal()}
        onCloseModal={() => setIsConfirmationModalOpen(false)}
        type={DialogType.ACTION}
        confirmModalValue='Ir para Home'
        cancelModalValue='Continuar editando'
      />

    </Paper>
  )
}

export default UserForm
