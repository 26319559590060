import { UpdateOfficerProposal } from "pages/operation/performance/types"

interface ITableHeadCellDTO {
  id: string
  label: string
  numeric?: boolean
  width?: number
  format?: FormatTableFieldType
  cancelIconEnabled?: boolean
  resendIconEnabled?: boolean
  allowSort?: boolean,
  possibleValues?: comboBoxValues[],
  onChange?: (proposalToUpdate: UpdateOfficerProposal) => void
  comboBoxDefaultValue?: string
}

export enum FormatTableFieldType {
  CNPJ = 'CNPJ',
  DATE = 'DATE',
  INVITE_STATUS = 'INVITE_STATUS',
  MONEY = 'MONEY',
  PROPOSAL_PERFORMANCE_STATUS = 'PROPOSAL_PERFORMANCE_STATUS',
  NO_FORMAT = 'NO_FORMAT',
  BOOLEAN = 'BOOLEAN',
  COMBO_BOX = 'COMBO_BOX'
}

export interface comboBoxValues {
  id: string,
  value: string
}

export type { ITableHeadCellDTO }
